import { Input } from 'antd'
import React from 'react'

const NumericInput = (props) => {
  const onChange = (e) => {
    const { value } = e.target

    const reg = props.currency ? /^\d*(\.\d{0,2})?$/ : /^\d*$/

    // if ((!isNaN(value) && reg.test(value)) || value === "" || value === "-") {
    //   props.onChange(value);
    // }
    if ((!isNaN(value) && reg.test(value)) || value === '') {
      props.onChange(value)
    }
  }

  const onBlur = () => {
    const {
      value, onBlur, onChange, currency,
    } = props
    if (value) {
      let valueTemp = value
      if (value.charAt(value.length - 1) === '.' || value === '-') {
        valueTemp = value.slice(0, -1)
      }

      const replaceRegex = currency ? /0*(\d+)/ : /(\d+)/
      onChange(valueTemp.replace(replaceRegex, '$1'))
    }
    if (onBlur) {
      onBlur()
    }
  }

  return <Input {...props} onChange={onChange} onBlur={onBlur} />
}

export default NumericInput

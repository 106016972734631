import { SHA3 } from 'sha3'

export const validateAddressETH = function (address) {
  if (!/^(0x)?[0-9a-f]{40}$/i.test(address)) {
      return false
  } else if (/^(0x)?[0-9a-f]{40}$/.test(address) || /^(0x)?[0-9A-F]{40}$/.test(address)) {
      return true
  } else {
      return isChecksumAddress(address)
  }
}

const isChecksumAddress = (address) => {
  const testAddress = address.replace('0x','')
  var addressHash = new SHA3(256)
  addressHash.update(testAddress.toLowerCase())
  for (var i = 0; i < 40; i++ ) {
      if ((parseInt(addressHash[i], 16) > 7 && testAddress[i].toUpperCase() !== testAddress[i]) || (parseInt(addressHash[i], 16) <= 7 && testAddress[i].toLowerCase() !== testAddress[i])) {
          return false
      }
  }
  return true
}

import _ from 'lodash'
import filterFiatCurrency from './filterFiatCurrency'
import sortCryptoCurrencies from './sortCryptoCurrencies'
import filterCryptoCurrency from './filterCryptoCurrency'

export const providerCurrencies = (urlParams, buySellFlow, currencyData, currencyCode, type, initialSourceCurrencyCode) => {
  let selectedFiat
  let selectedCrypto
  const initialValues = ''
  const popularTokens = ['BTC', 'ETH']

  if(type === 'fiat') {
    let destinationCurrencyCode = ''
    const initialSourceCurrencyCode = currencyCode
    if (buySellFlow === 'SELL') {
      destinationCurrencyCode = initialSourceCurrencyCode
    }
    selectedFiat = filterFiatCurrency(buySellFlow, initialValues, initialSourceCurrencyCode, destinationCurrencyCode, currencyData, urlParams?.sourceCurrencyCodeLocked)
  }

  if(type === 'crypto') {
    const cryptoCurrencies = _.uniqBy(sortCryptoCurrencies(currencyData, popularTokens, ''), 'currencyCode')
    selectedCrypto = filterCryptoCurrency(buySellFlow, initialSourceCurrencyCode, currencyCode, cryptoCurrencies, urlParams?.destinationCurrencyCodeLocked)
  }

  return {
    selectedFiat: selectedFiat,
    selectedCrypto: selectedCrypto
  }
}

export default providerCurrencies

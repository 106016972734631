import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  cryptoQuoteDetails: {},
  cryptoQuoteResult: {},
  paymentDetails: {
    // creditCardNumber: "4111111111111111",
    // creditCardExpiration: null,
    // creditCardCVC: "123",
  },
  walletAddress: '', // "tb1q45h8zexwztmz3nyd8gmkxhpavdsva4znwwhzvs",
  billingDetails: {},
  customerDetails: {},
  limitDetails: {},
  transactionId: undefined,
  multiFactorAuthorizationStatus: [],
  walletOrderId: undefined,
}

export const paymentProcessSlice = createSlice({
  name: 'payment-process',
  initialState,
  reducers: {
    setCryptoQuoteDetails: (state, action) => ({
      ...state,
      cryptoQuoteDetails: action.payload,
    }),
    setCryptoQuoteResult: (state, action) => ({
      ...state,
      cryptoQuoteResult: action.payload,
    }),
    setPaymentDetails: (state, action) => ({
      ...state,
      paymentDetails: action.payload,
    }),
    setBillingDetails: (state, action) => ({
      ...state,
      billingDetails: action.payload,
    }),
    setCustomerDetails: (state, action) => ({
      ...state,
      customerDetails: action.payload,
    }),
    setLimitDetails: (state, action) => ({
      ...state,
      limitDetails: action.payload,
    }),
    setMultiFactorAuthorizationStatus: (state, action) => ({
      ...state,
      multiFactorAuthorizationStatus: action.payload,
    }),
    setTransactionId: (state, action) => {
      state.transactionId = action.payload
    },
    setWalletAddress: (state, action) => {
      state.walletAddress = action.payload
    },
    setWalletOrderId: (state, action) => {
      state.walletOrderId = action.payload
    },
    resetPaymentDetails: () => initialState,
  },
})

export const {
  setCryptoQuoteDetails,
  setCryptoQuoteResult,
  setPaymentDetails,
  setBillingDetails,
  setCustomerDetails,
  setLimitDetails,
  setTransactionId,
  setMultiFactorAuthorizationStatus,
  resetPaymentDetails,
  setWalletAddress,
  setWalletOrderId,
} = paymentProcessSlice.actions

export default paymentProcessSlice.reducer

export const toTitleCaseWithoutUnderScores = (str) => {
  return str && str
    .toLowerCase()
    .replace(/_/g, ' ')
    .replace(/\w\S*/g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    })
}

export const formatServiceProviderName = (provider) => {
  switch (provider) {
    case 'BTCDIRECT':
      return 'BTC Direct'
    default:
      return toTitleCaseWithoutUnderScores(provider)
  }
}

export const sortCurrencies = (type, currency) => {
  return type === 'fiat' ?
    [...currency].sort((a, b) => a.currencyCode.localeCompare(b.currencyCode))
    :
    [...currency].sort((a, b) => a.currencyCode.localeCompare(b.currencyCode))
}

export const formatPaymentMethodType = (type) => {
  switch (type) {
    case 'SEPA':
    case 'ACH':
      return type
    default:
      return toTitleCaseWithoutUnderScores(type)
  }
}

import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { setDisplayPage } from 'store/slices/app'
import { CHECK_CUSTOMER_STATUS, CRYPTO_QUOTE } from '../pages'

import styled from 'styled-components'
import Button from 'components/ui/button'
import Currency from 'components/ui/currency'
import LogoPageWrapper from 'components/ui/logo-page-wrapper'
import { ButtonContainer, ResultItem } from 'components/ui/styled'

const ResultsTables = styled.div`
  margin: 20px 0;
`

const VerificationCaption = styled.div`
  text-align: center;
  font-size: 1rem;
`

const LimitDetails = () => {
  const dispatch = useDispatch()
  const account = useSelector((state) => state.account.current)
  const limitData = useSelector((state) => state.wyre.limitData)

  const { customerDetails, limitDetails } = useSelector((state) => state.paymentProcess)

  const needsKYC = customerDetails.kycStatus?.WYRE?.status === 'INFORMATION_NEEDED'

  const cannotProceed = limitDetails.dailyLimitRemaining === 0 && !needsKYC

  const onEditOrder = () => dispatch(setDisplayPage(CRYPTO_QUOTE))

  const onGoToSumsub = () => {
    const url = customerDetails.kycStatus.WYRE.verificationUrl // TODO: This will only work with WYRE
    window.open(url, '_blank')
    dispatch(setDisplayPage(CHECK_CUSTOMER_STATUS))
  }

  const onComplete = () => {
    window.open(account.options.returnUrl, '_self')
  }

  return (
    <LogoPageWrapper title="Limit Exceeded">
      <ResultsTables>
        <ResultItem>
          <div>Daily Limit Remaining</div>
          <div>
            <Currency currencyCode={limitDetails?.sourceCurrency}>{limitData?.dailyLimitRemaining}</Currency>
          </div>
        </ResultItem>
        <ResultItem>
          <div>Daily Limit</div>
          <div>
            <Currency currencyCode={limitDetails?.sourceCurrency}>{limitData?.dailyLimit}</Currency>
          </div>
        </ResultItem>
        <ResultItem>
          <div>Weekly Limit Remaining</div>
          <div>
            <Currency currencyCode={limitDetails?.sourceCurrency}>{limitData?.weeklyLimitRemaining}</Currency>
          </div>
        </ResultItem>
        <ResultItem>
          <div>Weekly Limit</div>
          <div>
            <Currency currencyCode={limitDetails?.sourceCurrency}>{limitData?.weeklyLimit}</Currency>
          </div>
        </ResultItem>
        <ResultItem>
          <div>Yearly Limit Remaining</div>
          <div>
            <Currency currencyCode={limitDetails?.sourceCurrency}>{limitData?.yearlyLimitRemaining}</Currency>
          </div>
        </ResultItem>
        <ResultItem>
          <div>Yearly Limit</div>
          <div>
            <Currency currencyCode={limitDetails?.sourceCurrency}>{limitData?.yearlyLimit}</Currency>
          </div>
        </ResultItem>
        {needsKYC && <VerificationCaption>Get verified to be able to purchase more!</VerificationCaption>}

        <ButtonContainer>
          {!cannotProceed && (
            <>
              {needsKYC && (
                <Button onClick={onGoToSumsub} size="large" type="primary" style={{ width: '100%' }}>
                  Get Verified
                </Button>
              )}
              <Button onClick={onEditOrder} size="large" type="default" style={{ width: '100%' }}>
                Edit Order Details
              </Button>
            </>
          )}
          {cannotProceed && (
            <Button size="large" type="primary" style={{ width: '100%' }} onClick={onComplete}>
              Back to {account.label}
            </Button>
          )}
        </ButtonContainer>
      </ResultsTables>
    </LogoPageWrapper>
  )
}

export default LimitDetails

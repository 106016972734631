import filterTokenCrypto from './filterTokenCrypto'
import filterNonTokenCrypto from './filterNonTokenCrypto'

export const filterDestinationCurrency = (cryptoCurrencies, popularTokens, cryptoSearch) => {
  let filterToken = filterTokenCrypto({
    cryptoCurrencies: cryptoCurrencies,
    popularTokens: popularTokens,
    cryptoSearch: cryptoSearch
  })
  let filterNonToken = filterNonTokenCrypto({
    cryptoCurrencies: cryptoCurrencies,
    popularTokens: popularTokens,
    cryptoSearch: cryptoSearch
  })

  let filteredCurrencies = [].concat(filterToken).concat(filterNonToken)
  const filterPopular = filteredCurrencies?.filter((crypto) => popularTokens.includes(crypto.currencyCode))
  if (filterPopular.length === 0) {
    filteredCurrencies = filteredCurrencies?.filter((crypto) => crypto.currencyCode !== '---Popular---')
  }

  const filterNonPopular = filteredCurrencies?.filter((crypto) => (!popularTokens.includes(crypto.currencyCode) && crypto.currencyCode !== '---Popular---') )
  if (filterNonPopular.length === 1) {
    filteredCurrencies = filteredCurrencies?.filter((crypto) => crypto.currencyCode !== '---All---')
  }

  if (!filteredCurrencies.length) {
    filteredCurrencies = filteredCurrencies.concat({ currencyCode: 'No results found' })
  }

  return filteredCurrencies
}

export default filterDestinationCurrency

import mixpanel from 'mixpanel-browser'
import { mixPanelEventKeys } from 'utils/constants'
import { mixpanelConstant } from './constants'

export const mixPanel = async ({eventName, buySellFlow, payload, serviceProvider, cryptoCurrencyCode}) => {
  const mixPanelEvent = mixpanelConstant(eventName, buySellFlow)
  if (serviceProvider) {
    mixPanelEvent.name = mixPanelEvent.name.replace('${serviceProvider}', serviceProvider)
    mixPanelEvent.description = mixPanelEvent.description.replace('${serviceProvider}', serviceProvider)
  }
  if (cryptoCurrencyCode) {
    mixPanelEvent.description = mixPanelEvent.description.replace('${cryptoCurrencyCode}', cryptoCurrencyCode)
  }
  if (process.env.REACT_APP_ENVIRONMENT?.toUpperCase() === 'PRODUCTION' && mixPanelEvent && process.env.REACT_APP_MIXPANEL_KEY_PRODUCTION) {
    await mixpanel.track(mixPanelEvent.name, {
      ...mixPanelEventKeys,
      buySellFlow: buySellFlow,
      eventName: mixPanelEvent.name,
      description: mixPanelEvent.description,
      ...payload
    })
  }
}

export default mixPanel

// base
import { useQuery, useMutation } from 'react-query'
import { generatePath } from 'react-router'
import doRequest from './doRequest'
import {
  GET_SESSION_DATA,
  GET_WIDGET_TOKEN,
  GET_ACCOUNT_PREFERENCES,
  POST_TRANSACTION,
  GET_DCA_INFO,
  GET_QUOTES,
  GET_SERVICE_PROVIDER_PROPERTIES,
  GET_SERVICE_PROVIDER_DETAILS,
  GET_SERVICE_PROVIDER_COUNTRY,
  GET_SERVICE_PROVIDER_CURRENCY_CODE,
  GET_SERVICE_PROVIDER_FIAT_CURRENCY,
  GET_PAYMENT_METHODS,
  GET_PAYMENT_METHODS_BY_SERVICE_PROVIDER,
  CONNECT_CUSTOMER,
  GET_CRYPTO_LIMITS,
  UPDATE_CUSTOMER,
  VALIDATE_MERCHANT,
  GET_CUSTOMER_STATUS,
  GET_TRANSACTION_STATUS,
  POST_SESSION_DATA,
  GET_SERVICE_PROVIDER_CRYPTO_LIMIT,
  POST_SESSION_EVENT,
  LOG,
} from 'services/URLs'

const QUERIES = {
  // Public-key-privileged queries
  GET_WIDGET_TOKEN: 'GET_WIDGET_TOKEN',
  GET_ACCOUNT_PREFERENCES: 'GET_ACCOUNT_PREFERENCES',
  GET_SERVICE_PROVIDER_PROPERTIES: 'GET_SERVICE_PROVIDER_PROPERTIES',
  GET_SERVICE_PROVIDER_DETAILS: 'GET_SERVICE_PROVIDER_DETAILS',
  GET_SERVICE_PROVIDER_COUNTRY_BUY: 'GET_SERVICE_PROVIDER_COUNTRY_BUY',
  GET_SERVICE_PROVIDER_COUNTRY_SELL: 'GET_SERVICE_PROVIDER_COUNTRY_SELL',
  GET_SERVICE_PROVIDER_CURRENCY_CODE: 'GET_SERVICE_PROVIDER_CURRENCY_CODE',
  GET_PAYMENT_METHODS: 'GET_PAYMENT_METHODS',
  GET_PAYMENT_METHODS_BY_SERVICE_PROVIDER: 'GET_PAYMENT_METHODS_BY_SERVICE_PROVIDER',
  // Session-privileged queries
  GET_SESSION_DATA: 'GET_SESSION_DATA',
  POST_SESSION_DATA: 'POST_SESSION_DATA',
  GET_QUOTES: 'GET_QUOTES',
  POST_TRANSACTION: 'POST_TRANSACTION',
  GET_DCA_INFO: 'GET_DCA_INFO',
  GET_CUSTOMER_STATUS: 'GET_CUSTOMER_STATUS',
  GET_TRANSACTION_STATUS: 'GET_TRANSACTION_STATUS',
  GET_SERVICE_PROVIDER_CRYPTO_LIMIT: 'GET_SERVICE_PROVIDER_CRYPTO_LIMIT',
  GET_PAYMENT_METHODS_BY_COUNTRY: 'GET_PAYMENT_METHODS_BY_COUNTRY',
  POST_SESSION_EVENT: 'POST_SESSION_EVENT',
  GET_FIAT_CURRENCY_BY_COUNTRY: 'GET_FIAT_CURRENCY_BY_COUNTRY',
  GET_CRYPTO_CURRENCY_BY_COUNTRY: 'GET_CRYPTO_CURRENCY_BY_COUNTRY',
}

// All mutations are privileged
const MUTATIONS = {
  GET_PAYMENT_METHODS_BY_COUNTRY: 'GET_PAYMENT_METHODS_BY_COUNTRY',
  GET_FIAT_CURRENCY_BY_COUNTRY: 'GET_FIAT_CURRENCY_BY_COUNTRY',
  GET_CRYPTO_CURRENCY_BY_COUNTRY: 'GET_CRYPTO_CURRENCY_BY_COUNTRY',
  POST_SESSION_DATA: 'POST_SESSION_DATA',
  POST_TRANSACTION: 'POST_TRANSACTION',
  CONNECT_CUSTOMER: 'CONNECT_CUSTOMER',
  UPDATE_CUSTOMER: 'UPDATE_CUSTOMER',
  GET_CRYPTO_LIMITS: 'GET_CRYPTO_LIMITS',
  VALIDATE_MERCHANT: 'VALIDATE_MERCHANT',
  GET_ACCOUNT_PREFERENCES: 'GET_ACCOUNT_PREFERENCES',
  GET_SERVICE_PROVIDER_COUNTRY_BUY: 'GET_SERVICE_PROVIDER_COUNTRY_BUY',
  GET_SERVICE_PROVIDER_COUNTRY_SELL: 'GET_SERVICE_PROVIDER_COUNTRY_SELL',
  GET_SERVICE_PROVIDER_CRYPTO_LIMIT: 'GET_SERVICE_PROVIDER_CRYPTO_LIMIT',
  GET_SERVICE_PROVIDER_DETAILS: 'GET_SERVICE_PROVIDER_DETAILS',
  POST_SESSION_EVENT: 'POST_SESSION_EVENT',
  POST_LOG: 'POST_LOG',
}

// Queries that use publicKey auth, so we use omitToken to skip the X-Crypto-Session-Token header

export const useGetWidgetToken = (publicKey) => {
  return useQuery([QUERIES.GET_WIDGET_TOKEN], async () => {
    return await doRequest({
      method: 'post',
      url: GET_WIDGET_TOKEN,
      config: {
        omitToken: true,
        headers: {
          Authorization: `BASIC ${publicKey}`
        }
      }
    })
  })
}

export const useGetAccountPreferencesDetails = () => {
  return useMutation([MUTATIONS.GET_ACCOUNT_PREFERENCES], async (params) => {
    const accountId = params?.accountId
    const publicKey = params?.publicKey
    if (params?.fetchData) {
      return params?.preferences
    }
    if (params?.accountPublicPreference?.accountId) {
      return params?.accountPublicPreference
    }
    return await fetchAccountPreference(accountId, publicKey)
  })
}

export const useGetAccountPreferences = (publicKey, accountId) => {
  return useQuery([QUERIES.GET_ACCOUNT_PREFERENCES], async () => {
    return await fetchAccountPreference(accountId, publicKey)
  }, {
    enabled: !!accountId
  })
}

const fetchAccountPreference = async (accountId, publicKey) => {
  return await doRequest({
    method: 'get',
    url: generatePath(GET_ACCOUNT_PREFERENCES, { accountId }),
    config: {
      omitToken: true,
      headers: {
        Authorization: `BASIC ${publicKey}`
      }
    }
  })
}

export const useGetServiceProviderProperties = (params, enabled = false) => {
  return useQuery([QUERIES.GET_SERVICE_PROVIDER_PROPERTIES, JSON.stringify(params?.serviceProviders)], async () => {
    return await doRequest({
      method: 'get',
      url: GET_SERVICE_PROVIDER_PROPERTIES,
      config: {
        omitToken: true,
        useDefaultPublicKey: true,
        params,
        headers: {
          'Meld-Version': '2023-11-22'
        }
      }
    })
  }, {
    enabled: !!(params?.serviceProviders || enabled),
  })
}

export const useGetServiceProviderInfo = () => {
  return useMutation([MUTATIONS.GET_SERVICE_PROVIDER_DETAILS], async (params) => {
    if (params?.fetchData) {
      return params?.serviceProvider
    }
    if (params?.accountServiceProvider.length > 0) {
      return params?.accountServiceProvider
    }
    const urlParams = params
    delete urlParams['fetchData']
    delete urlParams['accountServiceProvider']
    delete urlParams['serviceProvider']
    return await fetchServiceProviderDetails(urlParams)
  })
}

export const useGetServiceProviderDetails = (params, enabled = false) => {
  return useQuery([QUERIES.GET_SERVICE_PROVIDER_DETAILS], async () => {
    return await fetchServiceProviderDetails(params)
  }, {
    enabled: !!(params?.serviceProviders || enabled),
  })
}

const fetchServiceProviderDetails = async (params) => {
  return await doRequest({
    method: 'get',
    url: GET_SERVICE_PROVIDER_DETAILS,
    config: {
      omitToken: true,
      useDefaultPublicKey: true,
      params,
      headers: {
        'Meld-Version': '2023-11-22'
      }
    }
  })
}

export const useGetPaymentMethods = (params, enabled = true) => {
  return useQuery([QUERIES.GET_PAYMENT_METHODS, JSON.stringify(params)], async () => {
    return await doRequest({
      method: 'get',
      url: GET_PAYMENT_METHODS,
      config: {
        omitToken: true,
        useDefaultPublicKey: true,
        params,
        headers: {
          'Meld-Version': '2023-11-22'
        }
      }
    })
  }, {
    enabled: !!enabled,
  })
}

export const useGetAccountCryptoLimitDetails = () => {
  return useMutation([MUTATIONS.GET_SERVICE_PROVIDER_CRYPTO_LIMIT], async (params) => {
    const accountId = params?.accountId
    const publicKey = params?.publicKey
    if (params?.fetchData) {
      return params?.cryptoLimit
    }
    if (params?.accountCryptoLimit.length > 0) {
      return params?.accountCryptoLimit
    }
    return await fetchCrytpLimit(publicKey, accountId, { accountFilter: true })
  })
}

export const useGetAccountCryptoLimit = (publicKey, accountId) => {
  return useQuery([QUERIES.GET_SERVICE_PROVIDER_CRYPTO_LIMIT], async () => {
    return await fetchCrytpLimit(publicKey, accountId)
  }, {
    enabled: !!accountId
  })
}

const fetchCrytpLimit = async (publicKey, accountId, params) => {
  return await doRequest({
    method: 'get',
    url: generatePath(GET_SERVICE_PROVIDER_CRYPTO_LIMIT, { accountId }),
    config: {
      omitToken: true,
      params,
      headers: {
        Authorization: `BASIC ${publicKey}`
      }
    }
  })
}

export const useGetPaymentMethodsByServiceProvider = (params) => {
  return useQuery([QUERIES.GET_PAYMENT_METHODS_BY_SERVICE_PROVIDER], async () => {
    return await doRequest({
      method: 'get',
      url: GET_PAYMENT_METHODS_BY_SERVICE_PROVIDER,
      config: {
        omitToken: true,
        useDefaultPublicKey: true,
        params,
        headers: {
          'Meld-Version': '2023-11-22'
        }
      }
    })
  })
}

export const useGetServiceProviderBuyCountry = () => {
  return useMutation([MUTATIONS.GET_SERVICE_PROVIDER_COUNTRY_BUY], async (params) => {
    if (params?.fetchData) {
      return params?.countryList
    }
    if (params?.accountCountry.length > 0 && !params?.isForceFetch) {
      return params?.accountCountry
    }
    const urlParams = params
    delete urlParams['fetchData']
    delete urlParams['countryList']
    delete urlParams['accountCountry']
    delete urlParams['isForceFetch']
    return await fetchServiceProviderCountry(urlParams)
  })
}

export const useGetServiceProviderSellCountry = () => {
  return useMutation([MUTATIONS.GET_SERVICE_PROVIDER_COUNTRY_SELL], async (params) => {
    if (params?.fetchData) {
      return params?.countryList
    }
    const urlParams = params
    delete urlParams['fetchData']
    delete urlParams['countryList']
    return await fetchServiceProviderCountry(urlParams)
  })
}

const fetchServiceProviderCountry = async (params) => {
  return await doRequest({
    method: 'get',
    url: GET_SERVICE_PROVIDER_COUNTRY,
    config: {
      omitToken: true,
      useDefaultPublicKey: true,
      params,
      headers: {
        'Meld-Version': '2023-11-22'
      }
    }
  })
}

export const useGetServiceProviderCountry = (params) => {
  return useQuery([params.categories === 'CRYPTO_ONRAMP' ? QUERIES.GET_SERVICE_PROVIDER_COUNTRY_BUY : QUERIES.GET_SERVICE_PROVIDER_COUNTRY_SELL], async () => {
    return await doRequest({
      method: 'get',
      url: GET_SERVICE_PROVIDER_COUNTRY,
      config: {
        omitToken: true,
        useDefaultPublicKey: true,
        params,
        headers: {
          'Meld-Version': '2023-11-22'
        }
      }
    })
  }, {
    enabled: !!params?.categories,
  })
}

export const useGetServiceProviderCurrencyCode = (params) => {
  return useQuery([QUERIES.GET_SERVICE_PROVIDER_CURRENCY_CODE], async () => {
    if (params.serviceProvider === 'MESH') {
      return {}
    }
    return await doRequest({
      method: 'get',
      url: GET_SERVICE_PROVIDER_CURRENCY_CODE,
      config: {
        omitToken: true,
        useDefaultPublicKey: true,
        params,
        headers: {
          'Meld-Version': '2023-11-22'
        }
      }
    })
  }, {
    enabled: !!params?.serviceProvider,
  })
}

// Privileged queries, using X-Crypto-Session-Token authentication (baked into doRequest)

export const useGetSessionData = (enabled, config) => {
  return useQuery([QUERIES.GET_SESSION_DATA], async () => {
    return await doRequest({
      method: 'get',
      url: GET_SESSION_DATA,
      config
    })
  }, {
    enabled
  })
}

export const useGetQuotes = (params, enabled = true, refetchEveryMinuteUpToFiveMinutes) => {
  return useQuery([QUERIES.GET_QUOTES, JSON.stringify(params)], async () => {
    return await doRequest({
      method: 'post',
      url: GET_QUOTES,
      payload: params,
    })
  }, {
    enabled,
    refetchInterval: refetchEveryMinuteUpToFiveMinutes
  })
}

export const useStartTransaction = (payload, config, enabled = true) => {
  return useQuery([QUERIES.POST_TRANSACTION], async () => {
    return await doRequest({
      method: 'post',
      url: POST_TRANSACTION,
      config,
      payload
    })
  }, {
    enabled
  })
}

export const useGetDCAInfo = (payload) => {
  return useQuery([QUERIES.GET_DCA_INFO], async () => {
    return await doRequest({
      method: 'post',
      url: GET_DCA_INFO,
      payload
    })
  })
}

export const usePollCustomerStatus = (customerId = '', enabled) => {
  return useQuery([QUERIES.GET_CUSTOMER_STATUS], async () => {
    return await doRequest({
      method: 'get',
      url: generatePath(GET_CUSTOMER_STATUS, { customerId })
    })
  }, {
    refetchInterval: 1000,
    enabled: enabled && !!customerId
  })
}

export const usePollTransactionStatus = (transactionId = '', enabled) => {
  return useQuery([QUERIES.GET_TRANSACTION_STATUS], async () => {
    return await doRequest({
      method: 'get',
      url: generatePath(GET_TRANSACTION_STATUS, { transactionId })
    })
  }, {
    refetchInterval: 1000,
    enabled: enabled && !!transactionId
  })
}

// Mutations

export const usePostSessionData = (publicKey) => {
  return useMutation([MUTATIONS.POST_SESSION_DATA], async (payload) => {
    return await doRequest({
      method: 'post',
      url: POST_SESSION_DATA,
      payload,
      config: {
        headers: {
          Authorization: `BASIC ${publicKey}`,
          'Meld-Version': '2023-12-19'
        }
      }
    })
  })
}

export const useUpdateTransaction = (config, enabled = true) => {
  return useMutation([MUTATIONS.POST_TRANSACTION], async (payload) => {
    return await doRequest({
      method: 'post',
      url: POST_TRANSACTION,
      config,
      payload
    })
  }, {
    enabled
  })
}

export const useConnectCustomer = () => {
  return useMutation([MUTATIONS.CONNECT_CUSTOMER], async () => {
    return await doRequest({
      method: 'get',
      url: CONNECT_CUSTOMER
    })
  })
}

export const useUpdateCustomer = () => {
  return useMutation([MUTATIONS.UPDATE_CUSTOMER], async (payload) => {
    return await doRequest({
      method: 'put',
      url: UPDATE_CUSTOMER,
      payload
    })
  })
}

export const useGetCryptoLimits = () => {
  return useMutation([MUTATIONS.GET_CRYPTO_LIMITS], async (payload) => {
    return await doRequest({
      method: 'post',
      url: GET_CRYPTO_LIMITS,
      payload
    })
  })
}

export const useValidateMerchant = () => {
  return useMutation([MUTATIONS.VALIDATE_MERCHANT], async () => {
    return await doRequest({
      method: 'get',
      url: VALIDATE_MERCHANT
    })
  })
}

export const usePostSessionEvent = () => {
  return useMutation([MUTATIONS.POST_SESSION_EVENT], async (payload) => {
    return await doRequest({
      method: 'post',
      url: POST_SESSION_EVENT,
      payload,
      completeResponse: true
    })
  })
}

export const useGetPaymentMethodsByCountryCode = () => {
  return useMutation([MUTATIONS.GET_PAYMENT_METHODS_BY_COUNTRY], async (params) => {
    if (params?.accountPaymentMethod.length > 0 && !params?.isForceFetch) {
      return params?.accountPaymentMethod
    }
    const urlParams = params
    delete urlParams['accountPaymentMethod']
    delete urlParams['isForceFetch']

    return await doRequest({
      method: 'get',
      url: GET_PAYMENT_METHODS_BY_SERVICE_PROVIDER,
      config: {
        omitToken: true,
        useDefaultPublicKey: true,
        params,
        headers: {
          'Meld-Version': '2023-11-22'
        }
      }
    })
  })
}

export const useFiatCurrencyByCountryCode = () => {
  return useMutation([MUTATIONS.GET_FIAT_CURRENCY_BY_COUNTRY], async (params) => {
    if (params?.accountFiatCurrency !== '' && !params?.isForceFetch) {
      return JSON.parse(params?.accountFiatCurrency)
    }
    const urlParams = params
    delete urlParams['accountFiatCurrency']
    delete urlParams['isForceFetch']

    return await doRequest({
      method: 'get',
      url: GET_SERVICE_PROVIDER_FIAT_CURRENCY,
      config: {
        omitToken: true,
        useDefaultPublicKey: true,
        params,
        headers: {
          'Meld-Version': '2023-11-22'
        }
      }
    })
  })
}

export const useCryptoCurrencyByCountryCode = () => {
  return useMutation([MUTATIONS.GET_CRYPTO_CURRENCY_BY_COUNTRY], async (params) => {
    if (params?.accountCryptoCurrency !== '' && !params?.isForceFetch) {
      return JSON.parse(params?.accountCryptoCurrency)
    }
    const urlParams = params
    delete urlParams['accountCryptoCurrency']
    delete urlParams['isForceFetch']

    return await doRequest({
      method: 'get',
      url: GET_SERVICE_PROVIDER_CURRENCY_CODE,
      config: {
        omitToken: true,
        useDefaultPublicKey: true,
        params,
        headers: {
          'Meld-Version': '2023-11-22'
        }
      }
    })
  })
}

export const useGetPaymentMethodsByCountry = (params, enabled = true) => {
  return useQuery([QUERIES.GET_PAYMENT_METHODS_BY_SERVICE_PROVIDER], async () => {
    return await doRequest({
      method: 'get',
      url: GET_PAYMENT_METHODS_BY_SERVICE_PROVIDER,
      config: {
        omitToken: true,
        useDefaultPublicKey: true,
        params,
        headers: {
          'Meld-Version': '2023-11-22'
        }
      }
    })
  }, {
    enabled: !!(params?.categories || enabled),
  })
}

export const useGetServiceProviderPaymentMethods = (params) => {
  return useQuery([QUERIES.GET_PAYMENT_METHODS_BY_SERVICE_PROVIDER], async () => {
    return await doRequest({
      method: 'get',
      url: GET_PAYMENT_METHODS_BY_SERVICE_PROVIDER,
      config: {
        omitToken: true,
        useDefaultPublicKey: true,
        params,
        headers: {
          'Meld-Version': '2023-11-22'
        }
      }
    })
  })
}

export const useGetCountryByServiceProvider = (params, enabled = false) => {
  return useQuery([QUERIES.GET_SERVICE_PROVIDER_COUNTRY], async () => {
    return await doRequest({
      method: 'get',
      url: GET_SERVICE_PROVIDER_COUNTRY,
      config: {
        omitToken: true,
        useDefaultPublicKey: true,
        params,
        headers: {
          'Meld-Version': '2023-11-22'
        }
      }
    })
  }, {
    enabled: !!(params?.serviceProviders || enabled),
  })
}

export const useGetFiatCurrencyByProvider = (params, enabled = false) => {
  return useQuery([MUTATIONS.GET_FIAT_CURRENCY_BY_COUNTRY], async () => {
    return await doRequest({
      method: 'get',
      url: GET_SERVICE_PROVIDER_FIAT_CURRENCY,
      config: {
        omitToken: true,
        useDefaultPublicKey: true,
        params,
        headers: {
          'Meld-Version': '2023-11-22'
        }
      }
    })
  }, {
    enabled: !!(params?.serviceProviders || enabled),
  })
}

export const useGetCryptoCurrencyByProvider = (params, enabled = false) => {
  return useQuery([MUTATIONS.GET_CRYPTO_CURRENCY_BY_COUNTRY], async () => {
    return await doRequest({
      method: 'get',
      url: GET_SERVICE_PROVIDER_CURRENCY_CODE,
      config: {
        omitToken: true,
        useDefaultPublicKey: true,
        params,
        headers: {
          'Meld-Version': '2023-11-22'
        }
      }
    })
  }, {
    enabled: !!(params?.serviceProviders || enabled),
  })
}

export const useGetPaymentMethodsByProvider = (params, enabled = false) => {
  return useQuery([MUTATIONS.GET_PAYMENT_METHODS_BY_COUNTRY], async () => {
    return await doRequest({
      method: 'get',
      url: GET_PAYMENT_METHODS_BY_SERVICE_PROVIDER,
      config: {
        omitToken: true,
        useDefaultPublicKey: true,
        params,
        headers: {
          'Meld-Version': '2023-11-22'
        }
      }
    })
  }, {
    enabled: !!(params?.serviceProviders || enabled),
  })
}

export const useLog = () => {
  return useMutation([MUTATIONS.POST_LOG], async (payload) => {
    return await doRequest({
      method: 'post',
      url: LOG,
      payload,
      config: {
        headers: {
          'Meld-Version': '2023-11-22'
        }
      }
    })
  })
}

/* eslint-disable complexity */
import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { CloseOutlined, SearchOutlined } from '@ant-design/icons'
import { Avatar, Col, Input, List, Row, Select } from 'antd'
import filterDestinationCurrency from '../services/filterDestinationCurrency'
import getMobileScreenWidth from '../services/getMobileScreenWidth'
import { useDispatch, useSelector } from 'react-redux'
import { selectTheme } from 'store/slices/theme'
import filterSourceCurrency from '../services/filterSourceCurrency'
// import { getCurrencySymbol } from './utils'
import { FlexRow } from './styled'
import { CheckOutlined } from '@ant-design/icons'
import { setIsCountrySelected } from 'store/slices/wizard'
import { ReactComponent as ChevronSvg } from './icons/chevron-down-icon.svg'
import Icon from '@ant-design/icons'
import _ from 'lodash'

const SelectedInput = styled.div`
  margin-right: 10px;
  width: 100%;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: small;
`

const Blur = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  z-index: 9;
  backdrop-filter: blur(10px);
  border-radius: 6px 6px 0 0;
  background: rgb(49 48 48 / 30%);
`

const Drawer = styled.div`
  color: #000000;
  position: absolute;
  top: 50px;
  left: 0;
  right: 0;
  height: 94%;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  background: ${({ theme }) => theme.colors.drawerBackgoundPrimary};
  z-index: 11;
  padding: 15px;
  display: ${({ height }) => height};
  transform: translateY(100%);
  -webkit-transform: translateY(100%);
`

const Header = styled.div`
  color: ${({ theme }) => theme.colors.drawerHeaderColor};
  text-align: left;
  font-size: 18px;
  position: relative;
  font-weight: bold;
  margin-top: 10px;
`

const SearchWrapper = styled.div`
  margin: 15px 0px;
  border: 1px solid ${({ theme }) => theme.colors.cardBorder};
  border-radius: 8px;
  border-top-right-radius: ${({borderRadius, isMobileScreen}) => borderRadius && !isMobileScreen && 0 };
  border-bottom-right-radius: ${({borderRadius, isMobileScreen}) => borderRadius && !isMobileScreen && 0 };
`

const SearchField = styled(Input)`
  height: 51px;
  line-height: 51px;
  padding: 10px 11px;

  .ant-input{
    color: ${({ theme }) => theme.colors.drawerSearchInput};
  }
  .ant-input-clear-icon{
    font-size: 16px;
    color: ${({ theme }) => theme.colors.drawerClearIcon};
  }
`

const SearchContent = styled.div`
  font-size: 14px;
  height: ${({ismobilescreen}) => ismobilescreen};
  overflow-y: ${({ display }) => display};
  box-sizing: content-box;
  ::-webkit-scrollbar {
        width: 0px;
        background: transparent;
    }

    -ms-overflow-style: none;
    scrollbar-width: none;
`

const Title = styled.div`
  color: ${({ theme }) => theme.colors.drawerBodyTitle};
  margin: 0px 10px 5px 0px;
`

const ListNew = styled(List)`
  .ant-list-item {
    color: ${({ theme }) => theme.colors.drawerListTextColor} !important;
    padding: 10px;
    margin-bottom: 10px;
  }
  .ant-list-item-meta-title
  {
   color: ${({ theme }) => theme.colors.foregroundPrimary};
   font-weight: bold;
  }
  .ant-list-item-meta-content,
  .ant-list-item-meta-description {
    color: ${({ theme }) => theme.colors.foregroundQuaternary};
    font-size: 12px;
  }
  .ant-list-item:hover,
  .ant-list-item-meta:hover,
  .ant-list-item-meta-title:hover,
  .ant-list-item-meta-content:hover,
  .ant-list-item-meta-description:hover {
    /* color: #000000d9 !important; */
  }
`

const ListItem = styled(List.Item)`
  text-align: ${({ align }) => align};
  cursor: pointer;
  color: #ffffff !important;
  display: flex;
  align-items: center;
  border-bottom: 0px !important;

  &:hover {
    background: ${({ theme }) => theme.colors.cardBackground};
    color: #ffffff !important;
    border-radius: 8px
  }
`

const ListCryptoItem = styled(List.Item)`
  text-align: ${({ align }) => align};
  cursor: pointer;
  color: #ffffff !important;
  display: flex;
  align-items: center;
  border: 2px solid ${({ activeborder }) => activeborder};
  border-bottom: 2px solid ${({ activeborder }) => activeborder} !important;
  border-radius: 8px;

  &:hover {
    background: ${({ theme }) => theme.colors.cardBackground};
    color: #ffffff !important;
    border-radius: 8px
  }
`

const ListItemMeta = styled(List.Item.Meta)`
  align-items: center;
  cursor: pointer;
`

// const CryptoRow = styled(Row)`
//   align-items: center;
//   display: flex;
//   justify-content: center;
// `

const CryptoChainCol = styled(Col)`
  display: flex;
  justify-content: right;
  align-items: center;
`

const CryptoChainSection = styled.div`
  background: ${({ theme }) => theme.colors.cryptoChainColColor};
  color: ${({ theme }) => theme.colors.labelColorSecondary};
  border-radius: .50rem;
  text-align: center;
  border: 1px solid ${({ theme }) => theme.colors.cardBorder};
  font-size: 12px;
  width: fit-content;
  padding: 2px 5px;
  margin-right: 5px;
`

const EmptyData = styled.div`
  color: ${({ theme }) => theme.colors.drawerBodyTitle};
  text-align: center;
`

const ErrorMsg = styled.div`
  color: #db3e33;
`

const CryptoSelect = styled(Select)`
  .ant-select-selector {
    height: 53px !important;
    display: flex;
    align-items: center;
    position: relative;
    background-color: ${({ theme }) => theme.colors.drawerBackgoundPrimary} !important;
    border: 1px solid ${({ theme }) => theme.colors.cardBorder} !important;
    border-radius: .50rem !important;
    text-align: center !important;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
    color: ${({ theme }) => theme.colors.foregroundPrimary} !important;
    border-top-left-radius: ${({ isMobileScreen }) => isMobileScreen ? '.50rem' : '0px'} !important;
    border-bottom-left-radius: ${({ isMobileScreen }) => isMobileScreen ? '.50rem' : '0px'} !important;
    box-shadow: none !important;
  }
  .ant-select-selection-search-input {
    height: 53px !important;
  }
  .ant-select-selection-item, .ant-select-selection-placeholder {
    line-height: 53px !important;
    flex: none !important;
    padding-right: 0px !important;
    width: ${({ width }) => width && '90%'} !important;
    text-align: left !important;
  }
  .ant-select-arrow {
    color: ${({ theme }) => theme.colors.foregroundPrimary} !important;
    margin-top: -4px !important;
  }
  .ant-select-dropdown .ant-select-item {
    background-color: ${({ theme }) => theme.colors.cardBorder} !important;
    color: ${({ theme }) => theme.colors.cardBorder} !important;
  }
  .ant-select-dropdown .ant-select-item-option-selected {
    background-color: ${({ theme }) => theme.colors.cardBorder} !important;
    color: ${({ theme }) => theme.colors.cardBorder} !important;
  }
  .ant-select-clear {
    width: ${({ isMobileScreen }) => isMobileScreen ? '18px' : '16px'} !important;
    height: ${({ isMobileScreen }) => isMobileScreen ? '18px' : '16px'} !important;
    font-size: ${({ isMobileScreen }) => isMobileScreen ? '18px' : '16px'} !important;
    border-radius: 15px !important;
  }
`

const DropDownRow = styled(FlexRow)`
  cursor: ${({ cursor }) => cursor};
`

const DropDownCol = styled(FlexRow)`
  display: flex;
  align-items: center;
`

const DropDownFiatRow = styled(FlexRow)`
  cursor: ${({ cursor }) => cursor};
`

const DropDownFiatCol = styled(Col)`
  display: flex;
  align-items: center;
`

const DownDownIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.downIcon};
  font-size: 12px
`

const DownDownCurrencyIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.currencyCardColor};
  font-size: 12px
`

const SelectedCurrency = styled.span`
  color: ${({ theme }) => theme.colors.labelColorSecondary};
  font-size: 16px;
  font-weight: bold;
`

const DrawerDropdown = ({ selectedCurrency, selectedCountry, header, inputPlaceholder, inputError, inputErrorMsg, searchPlaceholder, searchListName, content, disabled, selectedValue, category, styling, onSelect, cryptoChain, onClose, onOpen, onNetworkDropDown, onNetworkSelection }) => {
  const dispatch = useDispatch()
  const theme = useSelector(selectTheme)
  const [ selectedUserCountry, setSelecteduserCountry ] = useState(selectedCountry)
  const [ selectedUserCurrency, setSelectedUserCurrency ] = useState(selectedCurrency)
  // eslint-disable-next-line no-unused-vars
  const [ selectedOption, setSelecteOption ] = useState(selectedValue)
  const [ searchKeyword, setSearchkeyword ] = useState('')
  const [ selectChainOption, setSelectChainOption ] = useState('')
  const [ filterContent, setFilterContent ] = useState(content)
  const [ filterLastContent, setFilterLastContent ] = useState(content)
  const [ isOpen, setIsOpen ] = useState(false)
  const [ isDrawerOpen, setIsDrawerOpen ] = useState(false)
  const [ openDrawer, setOpenDrawer ] = useState(false)
  const [ isChainDropdownOpen, setIsChainDropdownOpen ] = useState(false)
  const isMobileScreen = getMobileScreenWidth()
  const regex = /[\u0300-\u036f]/g
  const emptyMsg = 'No results found'
  const inputRef = useRef(true)

  useEffect(() => {
    setSelecteduserCountry(selectedCountry)
  }, [selectedCountry])

  const filterDropdwon = (keyword) => {
    setSearchkeyword(keyword)
    if (keyword === '') {
      if (selectChainOption === '') {
        setFilterContent(content)
      }
    } else {
      if (category === 'country') {
        // content?.sort((a,b) => a?.name?.localeCompare(b?.name))
        let filterCountries = _.orderBy(content, ['name'],['asc'])
          .filter((c) => (
            c?.name?.normalize('NFD')?.replace(regex, '')?.toLowerCase()?.includes(keyword.toLowerCase()) ||
            c?.countryCode?.toLowerCase() === keyword?.toLowerCase() ||
            c?.name?.toLowerCase().includes(keyword.toLowerCase())
          ))

        if (!filterCountries.length) {
          filterCountries = filterCountries.concat({ countryCode: emptyMsg })
        }
        setFilterContent(filterCountries)
      }
      if (category === 'fiat') {
        const filteredCurrencies = filterSourceCurrency(content, regex, keyword, emptyMsg)
        setFilterContent(filteredCurrencies)
      }
      if (category === 'crypto') {
        const popularTokens = ['BTC', 'ETH']
        const filteredCurrencies = filterDestinationCurrency(selectChainOption === '' ? content : filterLastContent, popularTokens, keyword)
        setFilterContent(filteredCurrencies)
      }
    }
  }

  const onCountrySelect = (item) => {
    dispatch(setIsCountrySelected(true))
    onSelect(item)
    setSelecteOption(item.countryCode)
    setSelecteduserCountry(item)
    resetDrawer()
  }

  const onFiatSelect = (item) => {
    onSelect(item)
    setSelecteOption(`${item.currencyCode}`)
    resetDrawer()
    setSelectedUserCurrency(item)
  }

  const onCryptoSelect = (item) => {
    onSelect(item)
    setSelecteOption(`${item.currencyCode}`)
    resetDrawer()
    setSelectedUserCurrency(item)
  }

  const onCryptoChainSelect = (item) => {
    setSelectChainOption(item)
    if (item === '' || item === undefined) {
      setFilterLastContent(content)
      setFilterContent(content)
    } else {
      const filterOption = content.filter((crypto) => crypto.chainName === item)
      setFilterLastContent(filterOption)
      setFilterContent(filterOption)
    }
    onNetworkSelection(item)
  }

  const resetDrawer = (isBackButtonClicked) => {
    setTimeout(() => {
      setIsDrawerOpen(false)
    }, 500)
    setOpenDrawer(false)
    filterDropdwon('')
    setSelectChainOption('')
    setFilterLastContent(content)
    setFilterContent(content)
    if (isBackButtonClicked) {
      onClose()
    }
  }

  const renderCountryList = (item) => {
    return (
      item.countryCode !== emptyMsg ?
      <ListItem align={item.countryCode === emptyMsg ? 'center' : 'left'} onClick={() => (item.countryCode !== emptyMsg && onCountrySelect(item))}>
        <ListItemMeta
          avatar={item?.flagImageUrl && <Avatar src={item?.flagImageUrl} />}
          title={item.countryCode !== emptyMsg ? `${item.name}` : item.countryCode}
          description={item.countryCode === emptyMsg ? item.name : item.countryCode}
        />
        {selectedCountry === item && <CheckOutlined style={{ color: '#F969D3',fontSize:'20px' }} />}
      </ListItem>
      : <EmptyData>{item.countryCode}</EmptyData>
    )
  }

  const renderFiatList = (item) => {
    return (
      item.currencyCode !== emptyMsg ?
     <ListItem align={item.currencyCode === emptyMsg ? 'center' : 'left'} onClick={() => (item.currencyCode !== emptyMsg && onFiatSelect(item))}>
        <ListItemMeta
          avatar={item?.symbolImageUrl && <Avatar src={item?.symbolImageUrl} />}
          title={item.currencyCode !== emptyMsg && item.currencyCode}
          description={item.currencyCode === emptyMsg ? item.currencyCode : item.name}
        />
        {selectedCurrency === item && <CheckOutlined style={{ color: '#F969D3',fontSize:'20px' }} />} {/* Render the tick icon if the current item is the selected item */}
      </ListItem>
      : <EmptyData>{item.currencyCode}</EmptyData>
    )
  }

  const renderCryptoList = (item) => {
    return (
      item.cryptoCurrencyCode !== emptyMsg ?
      <ListCryptoItem activeborder={item.currencyCode === selectedUserCurrency?.currencyCode ? theme.colors.filled : theme.colors.cardBorder} align={item.cryptoCurrencyCode === emptyMsg ? 'center' : 'left'} onClick={() => (item.currencyCode !== emptyMsg && onCryptoSelect(item))}>
        <ListItemMeta
          avatar={item?.symbolImageUrl && <Avatar src={item?.symbolImageUrl} />}
          title={ item.cryptoCurrencyCode !== emptyMsg ? item.currencyCode : item.cryptoCurrencyCode }
          description={item.currencyCode === emptyMsg ? item.currencyCode : item.name}
        />
        <CryptoChainCol span={isMobileScreen ? 8 : 9}>
          <CryptoChainSection>{item.chainName}</CryptoChainSection>
        </CryptoChainCol>
      </ListCryptoItem>
      : <EmptyData>{item.cryptoCurrencyCode}</EmptyData>
    )
  }

  // const characterCount = (selectedUserCurrency) => {
  //   return category !== 'crypto' ? getCurrencySymbol(selectedUserCurrency.currencyCode).length : selectedUserCurrency.currencyCode.length
  // }

  const currencyName = (cryptoCurrency) => {
    if (cryptoCurrency.indexOf('_') > -1) {
      return cryptoCurrency.split('_')[0]
    }
    return cryptoCurrency
  }

  // const drawerBox = () => {
  //   return characterCount(selectedUserCurrency) === 1 ? '15px' : (characterCount(selectedUserCurrency) === 2 ? '8px' :
  //     (category === 'crypto' ? (characterCount(selectedUserCurrency) > 6 ? '-2px' : '0px') : '1px'))
  // }

  const CountryDrawer = () => {
    return (
      <DropDownRow cursor={!disabled ? 'pointer' : 'not-allowed'} onClick={() => {
        if(!disabled) {
          setOpenDrawer(true)
          setIsDrawerOpen(true)
          onOpen()
          setIsOpen(true)
          setTimeout(() => {
            inputRef.current.focus()
          }, 500)
        }
      }}>
        <DropDownCol><Avatar style={{ width: '20px', height: '20px' ,marginRight: '10px'}}  src={selectedUserCountry.flagImageUrl} /></DropDownCol>
       { /* <DropDownCol style={{ marginLeft: '10px', fontSize: '16px' }}><SelectedInput>{selectedOption || inputPlaceholder}</SelectedInput></DropDownCol> */}
        <DropDownCol>
          <DownDownIcon component={ChevronSvg} />
        </DropDownCol>
      </DropDownRow>
    )
  }

  const FiatDrawer = () => {
    return (
      <DropDownFiatRow cursor={!disabled ? 'pointer' : 'not-allowed'} onClick={() => {
        if(!disabled) {
          setOpenDrawer(true)
          setIsDrawerOpen(true)
          onOpen()
          setIsOpen(true)
          setTimeout(() => {
            inputRef.current.focus()
          }, 500)
        }
      }}>
        <DropDownFiatCol
          col={18}
          style={{ margin: `0px`, fontSize: '20px', width: '100%', textAlign: 'center' }}
        >
        <DropDownRow>
        <DropDownCol><Avatar style={{ width: '20px', height: '20px' ,marginRight: '10px'}}  src={selectedUserCurrency?.symbolImageUrl} /></DropDownCol>
        <DropDownCol>
          <SelectedInput>
            <SelectedCurrency>{selectedUserCurrency.currencyCode}</SelectedCurrency>
          </SelectedInput>
          </DropDownCol>
        </DropDownRow>

        </DropDownFiatCol>
        <DropDownFiatCol col={6}><DownDownCurrencyIcon component={ChevronSvg} /></DropDownFiatCol>
      </DropDownFiatRow>
    )
  }

  const CryptoDrawer = () => {
    return (
      <DropDownFiatRow cursor={!disabled ? 'pointer' : 'not-allowed'} onClick={() => {
        if(!disabled) {
          setOpenDrawer(true)
          setIsDrawerOpen(true)
          onOpen()
          setIsOpen(true)
          setTimeout(() => {
            inputRef.current.focus()
          }, 500)
        }
      }}>
        <DropDownCol col={2}>
          {category === 'crypto' && <Avatar style={{ width: '20px', height: '20px' ,marginRight: '10px'}} src={selectedUserCurrency?.symbolImageUrl} /> }
        </DropDownCol>
        <DropDownCol
          col={18}
          style={{ margin: `0px`, fontSize: '16px', width: '100%', textAlign: 'center' }}
        >
          <SelectedInput>
            <SelectedCurrency>{category === 'crypto' ? currencyName(selectedUserCurrency.currencyCode) : selectedUserCurrency.currencyCode}</SelectedCurrency>
          </SelectedInput>
        </DropDownCol>
        <DropDownCol col={4}><DownDownCurrencyIcon component={ChevronSvg} /></DropDownCol>
      </DropDownFiatRow>
    )
  }

  return (
    <>
      { category === 'country' && <CountryDrawer /> }
      { category === 'fiat' && <FiatDrawer /> }
      { category === 'crypto' && <CryptoDrawer /> }
      {inputError && <ErrorMsg>{inputErrorMsg}</ErrorMsg>}
      {openDrawer && <Blur/>}
      {
        isDrawerOpen &&
        <Drawer height={!isOpen && 'none'} className={openDrawer ? 'selected' : 'dismiss'}>
        <Header>
          <CloseOutlined style={{ position: 'absolute', right: 0, top: 5 }} onClick={() => resetDrawer(true)}/>
          {header}
        </Header>
        <Row>
          <Col span={category === 'crypto' ? isMobileScreen ? 24 : 15 : 24}>
            <SearchWrapper borderRadius={category === 'crypto'} isMobileScreen={isMobileScreen}>
              <SearchField
                size="large"
                bordered={false}
                isMobileScreen={isMobileScreen}
                allowClear
                placeholder={searchPlaceholder}
                prefix={<SearchOutlined style={{ color: '#A8AAAC', fontSize: '18px' }} />}
                onChange={(e) => {
                  e.target.value !== '' && filterDropdwon(e.target.value)
                  e.target.value === '' && filterDropdwon('')

                }}
                value={searchKeyword}
                ref={inputRef}
              />
            </SearchWrapper>
          </Col>
          { /* { (category === 'crypto' && !isMobileScreen) && (<Col span={1}></Col>)} */}
          {category === 'crypto' &&
            <Col span={isMobileScreen ? 24 : 9}
              style={{ paddingLeft: `${isMobileScreen ? '0px' : '0px'}` }}
            >
              <CryptoSelect
                virtual={false}
                allowClear
                showSearch
                defaultValue=""
                size="large"
                isMobileScreen={isMobileScreen}
                style={{
                  width: '100%',
                  margin: `${isMobileScreen ? '0px 0px 27px 0px' : '15px 0px 0px -1px'}`,
                  height: '50px'
                }}
                placeholder="All Chains"
                onChange={(e) => onCryptoChainSelect(e)}
                onDropdownVisibleChange={(e) => {
                  setIsChainDropdownOpen(e)
                  onNetworkDropDown(e)
                }}
                dropdownStyle={{
                   /* background: `${theme.name === 'lightMode' ? '#898a8c' : '#161B26  '}`, */
                  color: theme.name === 'lightMode' ? `#db0707!important`: `#b10b0b !important`
                }}
                options={cryptoChain}
                notFoundContent={
                  <div style={{color: `${theme.name === 'lightMode' ? '#ffffff' : '#000000'}`}}>
                    {emptyMsg}
                  </div>
                }
                width={selectChainOption}
              />
            </Col>
          }
        </Row>
        <Title>{searchKeyword === '' && `All ${searchListName}`} {searchKeyword !== '' && 'Search Results'}</Title>
        <SearchContent ismobilescreen={isMobileScreen ? '70%' : '75%' } display={isChainDropdownOpen ? 'hidden' : 'auto'}>
          <ListNew
            size="small"
            bordered={false}
            dataSource={filterContent}
            renderItem={(item) =>
              (
                category === 'country' ? renderCountryList(item) :
                category === 'fiat' ? renderFiatList(item) :
                category === 'crypto' && renderCryptoList(item)
              )
            }
          >
          </ListNew>
        </SearchContent>
      </Drawer>
      }

    </>
  )
}

export default DrawerDropdown

/* eslint-disable complexity */
import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Row, Col, Skeleton } from 'antd'

import { useDispatch, useSelector } from 'react-redux'
import countryCodeByTimezone from 'utils/countryCodeByTimezone'
import { setCountryCode, setDestinationCurrencyCode, setPaymentMethod, setSourceAmount, setSourceCurrencyCode, setWizardDrawerRef } from 'store/slices/wizard'

import ThemeToggle from './components/themeToggle'
import BuySellToggle from './components/buySellToggle'
import { copyrightText, meldLogo } from 'utils/constants'
import DrawerDropdown from './components/drawerDropdown'
import mixPanel from './services/mixpanel'
import { getURLParams } from 'utils/data-model'

const MobilePage = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0px;
  height: 100%;
`

const DesktopPage = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 0px;
  height: 100%;
`

const ContentWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.defaultBackgroundColor};
  // -webkit-transition: background-color 3000ms ease;
  // -moz-transition: background-color 3000ms ease;
  // -o-transition: background-color 3000ms ease;
  // -ms-transition: background-color 3000ms ease;
  // transition: background-color 3000ms ease;
  color: ${({ theme }) => theme.colors.borderTextColor};
  height: 100%;
  // padding: 8px 8px 0px 8px;
  max-height: ${({ small }) => small ? '2000px' : '790px'};
  min-height: 745px;
  border-radius: ${({ small }) => small ? '0px' : '0px'};
  overflow: hidden;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, .1);

  -webkit-filter: blur(${({isModalOpen}) => isModalOpen ? '2px' : '0px'});
  -moz-filter: blur(${({isModalOpen}) => isModalOpen ? '2px' : '0px'});
  -o-filter: blur(${({isModalOpen}) => isModalOpen ? '2px' : '0px'});
  -ms-filter: blur(${({isModalOpen}) => isModalOpen ? '2px' : '0px'});
  filter: blur(${({isModalOpen}) => isModalOpen ? '2px' : '0px'});
`

const ContentFlat = styled.div`
 background-color: ${({ theme }) => theme.colors.backgroundPrimary};
  -moz-transition: background-color 3000ms ease;
  -o-transition: background-color 3000ms ease;
  -ms-transition: background-color 3000ms ease;
  transition: background-color 3000ms ease;
  /* 38px makes room for the SubContext below */
  height: calc(100% - 38px);
  /*/* rder-radius: 15px; * */ position: relative;
`

const ContentContainer = styled.div`
  /* background: ${({ theme }) => theme.colors.backgroundGradient}; */
  color: ${({ theme }) => theme.colors.toggleOuterBorder};
  height: 100%;
  border-radius: 15px;
  padding: ${({padding}) => padding};
`

export const Header = styled(Row)`
  width: 100%;
  height: 32px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 0px 10px;
`

const SubContextContainer = styled.div`
  height: 38px;
  line-height: 38px;
  font-size: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ isMobileScreen }) => isMobileScreen ? '0px' : '0px 15px'};
  color: ${({ theme }) => theme.colors.borderTextColor};
  position: relative;
  z-index: 10;
`

const SubContext = styled.div`
  flex: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  &:first-of-type {
    flex: 1;
    justify-content: flex-start;
  }
  &:last-of-type {
    flex: 1;
    justify-content: flex-end;
  }
`

const SubCopyContext = styled.div`
  flex: 2;
  display: flex;
  color: ${({ theme }) => theme.colors.copyrightText};
  align-items: center;
  justify-content: center;
  &:first-of-type {
    flex: 1;
    justify-content: flex-start;
  }
  &:last-of-type {
    flex: 1;
    justify-content: flex-end;
  }
`

const InputWrapper = styled.div`
  margin-top: 0px;
`

const SourceSkeleton = styled(Skeleton)`
  .ant-skeleton-content {
    display: flex;
    align-items: center;
  }
  .ant-skeleton-title {
    width: 100% !important;
  }
`

const Headers = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`
const CustomContainer = styled.div`
  background: ${({ theme }) => theme.colors.currencyCardBackground};
  border-radius: 8px;
  padding: 10px;
  border: 1px solid ${({ theme }) => theme.colors.currencyCardBorder};
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
`

const WizardPageWrapper = ({ isFetching, accountPreferences, children, showBuySellToggle, wizardDrawerRef, countries, buySellFlow, showCountrySelection, onCountryChange, userSelectedCountry, onFlowChange, isModalOpen, sellServicePrvider }) => {
  // mixPanel({
  //   eventName: buySellFlow === 'BUY' ? 'buy_country_page_view' : 'sell_country_page_view',
  //   buySellFlow: buySellFlow
  // })
  const isInputError = false
  const { countryCode, lockCountryCode, buySellFlowLocked } = useSelector((state) => state.wizard)
  const account = useSelector((state) => state.account.current)
  const themeLocked = useSelector((state) => state.theme.locked)
  const wizardDrawer = useSelector((state) => state.wizard.wizardDrawerRef)
  const imageUrl = useSelector((state) => state.account.accountLogo)
  const mobileWidth = 450 > Math.max(document?.documentElement?.clientWidth || 0, window?.innerWidth || 0)
  const mobileHeight = 730 > Math.max(document?.documentElement?.clientHeight || 0, window?.innerHeight || 0)
  const urlParams = new URLSearchParams(window.location.search)
  const qparams = getURLParams()
  const isMobileScreen = urlParams.get('width') === 'full' || window.screen.width <= 450
  const dispatch = useDispatch()

  const selectedCountry = countries?.find((country) => country.countryCode === (userSelectedCountry || countryCode || countryCodeByTimezone)) || {}

  useEffect(() => {
    dispatch(setWizardDrawerRef(wizardDrawerRef.current))
  }, [wizardDrawer])

  const Page = mobileWidth || mobileHeight ? MobilePage : DesktopPage

  return (
    <Page id="meld-wizard">
      <ContentWrapper small={mobileWidth || mobileHeight} ref={wizardDrawerRef} isModalOpen={isModalOpen}>
        <ContentFlat>
          <ContentContainer padding={isMobileScreen ? '15px 5px' : '15px'}>
            <Headers>
                {
                  !qparams.publicKey ?
                    <img src={meldLogo} alt="Meld Logo" height="28" width="28" />
                  :
                  imageUrl !== '' && <img src={imageUrl} alt="Meld Logo" height="42" width="100" style={{ objectFit: 'contain' }} />
                }
                <div style={{ marginLeft: '2em' }}>{!buySellFlowLocked && showBuySellToggle && sellServicePrvider.length > 0 ? <BuySellToggle onFlowChange={onFlowChange} /> : <Col span={10} />}</div>
                {
                  showCountrySelection &&
                  <div>
                    <SourceSkeleton active loading={!selectedCountry?.flagImageUrl} paragraph={{rows: 0}} style={{ width: '64px', marginTop: '24px' }}>
                      <CustomContainer>
                        <InputWrapper>
                          <DrawerDropdown
                            selectedCountry={selectedCountry}
                            header={'Select Country of Residence'}
                            inputPlaceholder={'Select Country'}
                            inputError={isInputError}
                            inputErrorMsg={'Please select a country'}
                            searchPlaceholder={'Search for a country'}
                            searchListName={'Countries'}
                            content={countries}
                            selectedValue={selectedCountry.countryCode}
                            disabled={lockCountryCode}
                            category={'country'}
                            styling={{ height: '51px', lineHeight: '51px', padding: '0px 11px', fontSize: '16px', border: isInputError ? '1px solid #ff0000' : 'none' }}
                            onSelect={(c) => {
                              onCountryChange(c.countryCode)
                              dispatch(setSourceAmount(0))
                              dispatch(setPaymentMethod({}))
                              dispatch(setSourceCurrencyCode(''))
                              dispatch(setDestinationCurrencyCode(''))
                              dispatch(setCountryCode(c.countryCode))
                              mixPanel({
                                eventName: buySellFlow === 'BUY' ? 'buy_country_selection': 'sell_country_selection',
                                buySellFlow: buySellFlow,
                                payload: {
                                  countryCode: c.countryCode
                                }
                              })
                            }}
                            onOpen={() => {
                              mixPanel({
                                eventName: buySellFlow === 'BUY' ? 'buy_country_drawer_open' : 'sell_country_drawer_open',
                                buySellFlow: buySellFlow
                              })
                            }}
                            onClose={() => {
                              mixPanel({
                                eventName: buySellFlow === 'BUY' ? 'buy_country_drawer_back_button' : 'sell_country_drawer_back_button',
                                buySellFlow: buySellFlow
                              })
                            }}
                            onNetworkDropDown={(e) => {}}
                            onNetworkSelection={(e) => {}}
                          />
                        </InputWrapper>
                      </CustomContainer>
                    </SourceSkeleton>
                  </div>
                }
            </Headers>
            {children}
          </ContentContainer>
        </ContentFlat>
        <SubContextContainer isMobileScreen={isMobileScreen}>
          <SubContext>
            {(themeLocked ? <div style={{ minWidth: 100 }} /> : <ThemeToggle /> )}
          </SubContext>
          <SubCopyContext>
            <span>{copyrightText}&nbsp;<b>Meld.io{account.styling.global.footerPostscript}</b></span>
          </SubCopyContext>
          <SubContext>
            <img src={meldLogo} alt="Meld Logo" height="24"/>
          </SubContext>
        </SubContextContainer>
      </ContentWrapper>
    </Page>
  )
}

export default WizardPageWrapper

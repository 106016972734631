import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  serviceProviderProperties: [],
  serviceProviderDetails: [],
  topServiceProviders: []
}

export const appSlice = createSlice({
  name: 'serviceProvider',
  initialState,
  reducers: {
    setServiceProviderProperties: (state, action) => {
      state.serviceProviderProperties = action.payload
    },
    setServiceProviderDetails: (state, action) => {
      state.serviceProviderDetails = action.payload
    },
    setTopServiceProviders: (state, action) => {
      state.topServiceProviders = action.payload
    },
  },
})

export const { setServiceProviderProperties, setServiceProviderDetails, setTopServiceProviders } = appSlice.actions

export default appSlice.reducer

// Crypto Widget Session endpoints
export const GET_SESSION_DATA = '/crypto/session/sessiondata'
export const GET_WIDGET_TOKEN = '/crypto/session/token'
export const GET_QUOTES = '/crypto/session/quote'
export const POST_TRANSACTION = '/crypto/session/transactions'
export const GET_DCA_INFO = '/crypto/session/dcainfo'
export const CONNECT_CUSTOMER = '/crypto/session/kyc/customer/connect'
export const GET_CRYPTO_LIMITS = '/crypto/session/limits'
export const UPDATE_CUSTOMER = '/crypto/session/kyc/customer'
export const VALIDATE_MERCHANT = '/crypto/session/applepay/paymentsession'
export const GET_CUSTOMER_STATUS = '/crypto/session/kyc/customer/:customerId'
export const GET_TRANSACTION_STATUS = '/crypto/session/transactions/:transactionId'
export const POST_SESSION_DATA = '/crypto/session/widget'

// Service provider properties endpoints
export const GET_SERVICE_PROVIDER_PROPERTIES = '/service-providers/properties'
export const GET_SERVICE_PROVIDER_DETAILS = '/service-providers'
export const GET_SERVICE_PROVIDER_CURRENCY_CODE = '/service-providers/properties/crypto-currencies'
export const GET_PAYMENT_METHODS = '/service-providers/properties/country-payment-methods'
export const GET_PAYMENT_METHODS_BY_SERVICE_PROVIDER = '/service-providers/properties/payment-methods'
export const GET_SERVICE_PROVIDER_FIAT_CURRENCY = '/service-providers/properties/fiat-currencies'
export const GET_SERVICE_PROVIDER_CRYPTO_LIMIT = '/payments/crypto/limits'
export const GET_SERVICE_PROVIDER_COUNTRY = '/service-providers/properties/countries'

// Account preferences endpoint (used for Wizard settings)
export const GET_ACCOUNT_PREFERENCES = '/accounts/:accountId/preferences/public'

export const POST_SESSION_EVENT = '/crypto/session/event'

export const LOG = '/log'

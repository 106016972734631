import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'

import { setTransactionData } from 'store/slices/wyre'
import { setDisplayPage, showGlobalAlert } from 'store/slices/app'
import { setCurrencies, setCoins, setCountries, setStates } from 'store/slices/common'
import { setBillingDetails, setCryptoQuoteDetails, setWalletAddress } from 'store/slices/payment-process-slice'
import { usePollCustomerStatus, usePollTransactionStatus } from 'hooks/api'

import LogoPageWrapper from 'components/ui/logo-page-wrapper'
import { LoadingOutlined as LoadingOutlinedANTD } from '@ant-design/icons'
import CaptureBillingDetails from './components/captureBillingDetails'
import CapturePaymentDetails from './components/capturePaymentDetails'
import CryptoPurchaseForm from './components/quoteForm'
import CryptoQuoteResult from './components/quoteResult'
import LimitDetails from './components/limitsDetails'
import PaymentComplete from './components/paymentComplete'
import PaymentFailure from './components/paymentFailure'

import { allCountries, getStates } from 'services/countries'
import currenciesJSON from 'assets/currencies.json'

import {
  CAPTURE_BILLING_DETAILS,
  CAPTURE_PAYMENT_DETAILS,
  CHECK_CUSTOMER_STATUS,
  CHECK_TRANSACTION_STATUS,
  CRYPTO_QUOTE,
  CRYPTO_QUOTE_RESULT,
  LIMITS_DETAILS,
  PAYMENT_COMPLETE,
  PAYMENT_FAILURE,
} from './pages'

const LoadingContents = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const LoadingOutlined = styled(LoadingOutlinedANTD)`
  text-align: center;
  font-size: 100px;
  color: ${({ theme }) => theme.colors.highlightPrimary} !important;
  margin: 20px auto;
`

const WyreProvider = () => {
  const dispatch = useDispatch()
  const { sessionData, displayPage } = useSelector((state) => state.app)
  const { sourceAmount, sourceCurrency, sourceCurrencyCode, destinationCurrencyCode, walletAddress, country } = sessionData.sessionData
  const { customerDetails } = useSelector((state) => state.paymentProcess)
  const transactionData = useSelector((state) => state.wyre.transactionData)
  const { data: transaction } = usePollTransactionStatus(transactionData?.transaction?.id, displayPage === CHECK_TRANSACTION_STATUS)
  const { data: customerStatus } = usePollCustomerStatus(customerDetails?.customerId, displayPage === CHECK_CUSTOMER_STATUS)

  // TODO: This really should not be 7 dispatches.
  useEffect(() => {
    dispatch(setCurrencies(currenciesJSON.filter((currency) => currency.wyre_supported === 'Yes')))
    dispatch(setCoins([{ value: 'eth', desc: 'Ethereum (ETH)' }, { value: 'ethc', desc: 'Ethereum Classic (ETHC)' }, { value: 'btc', desc: 'Bitcoin (BTC)' }]))
    dispatch(setCountries(allCountries))
    dispatch(setWalletAddress(walletAddress))
    dispatch(setCryptoQuoteDetails({
      amount: sourceAmount,
      currencyCode: sourceCurrency || 'USD',
      cryptoCurrency: destinationCurrencyCode
    }))
    dispatch(setBillingDetails({
      countryCode: country,
      phoneExt: allCountries.find((country) => country.iso2 === country)?.dial_code
    }))

    dispatch(setDisplayPage(walletAddress && sourceAmount && sourceCurrencyCode && destinationCurrencyCode ? CRYPTO_QUOTE_RESULT : CRYPTO_QUOTE))
  }, [])

  // Whenever country changes, make sure we have updated states
  useEffect(async () => {
    dispatch(setStates(await getStates(country || 'US')))
  }, [country])

  // Whenever the transaction updates, handle the changes
  useEffect(() => {
    if (transaction?.response?.status >= 400 || transaction?.response?.data?.error || transaction?.response?.data?.message) {
      return dispatch(showGlobalAlert({
        type: 'error',
        content: transaction?.response?.data?.error || transaction?.response?.data?.message || 'An error occurred.',
      }))
    }

    dispatch(setTransactionData(transaction))

    if (transaction?.transaction.status === 'TRANSFERRING_TO_WALLET' || transaction?.transaction.status?.startsWith('SETTLED') || transaction?.transaction.status?.startsWith('SETTLING')) {
      dispatch(setDisplayPage(PAYMENT_COMPLETE))
    } else if (transaction?.transaction.status === 'FAILED') {
      dispatch(setDisplayPage(PAYMENT_FAILURE))
    }
  }, [transaction])

  // Whenever the customer status updates, handle the changes
  useEffect(() => {
    if (customerStatus?.kycStatus?.WYRE?.status === 'APPROVED') {
      dispatch(setDisplayPage(CAPTURE_PAYMENT_DETAILS))
    } else if (customerStatus?.response?.status >= 400 || customerStatus?.response?.data?.error || customerStatus?.response?.data?.message) {
      dispatch(showGlobalAlert({
        type: 'error',
        content: customerStatus?.response?.data?.error || customerStatus?.response?.data?.message || 'An error occurred.',
      }))
      dispatch(setDisplayPage(LIMITS_DETAILS))
    }
  }, [customerStatus])

  return (
    <>
      {(displayPage === CHECK_TRANSACTION_STATUS || displayPage === CHECK_CUSTOMER_STATUS) && (
        <LogoPageWrapper>
          <LoadingContents>
            <LoadingOutlined />
            <div>Processing Transaction</div>
          </LoadingContents>
        </LogoPageWrapper>
      )}
      {displayPage === CRYPTO_QUOTE && <CryptoPurchaseForm />}
      {displayPage === CRYPTO_QUOTE_RESULT && <CryptoQuoteResult />}
      {displayPage === CAPTURE_PAYMENT_DETAILS && <CapturePaymentDetails />}
      {displayPage === CAPTURE_BILLING_DETAILS && <CaptureBillingDetails />}
      {displayPage === PAYMENT_COMPLETE && <PaymentComplete />}
      {displayPage === PAYMENT_FAILURE && <PaymentFailure />}
      {displayPage === LIMITS_DETAILS && <LimitDetails />}
    </>
  )
}

export default WyreProvider

import { validate as validateAddressBTC } from 'bitcoin-address-validation'
import { validateAddressETH } from './validateETHaddresses'
import { validate } from 'crypto-address-validator-ts'

export const isWalletAddressValid = (walletAddress, token) => {
  switch (token) {
    case 'ETH':
      return validateAddressETH(walletAddress)
    case 'BTC':
      return validateAddressBTC(walletAddress)
    case 'USDT':
    case 'BNB':
    case 'USDC':
    case 'XRP':
    case 'ADA':
    case 'DOGE':
    case 'SOL':
    case 'MATIC':
      return validate(walletAddress, token, null)
    default:
      return true
  }
}

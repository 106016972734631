export const CHECK_TRANSACTION_STATUS = 'CHECK_TRANSACTION_STATUS'

export const CRYPTO_QUOTE = 'CRYPTO_QUOTE'
export const CRYPTO_QUOTE_RESULT = 'CRYPTO_QUOTE_RESULT'
export const CAPTURE_PAYMENT_DETAILS = 'CAPTURE_PAYMENT_DETAILS'
export const CAPTURE_BILLING_DETAILS = 'CAPTURE_BILLING_DETAILS'
export const CHECK_CUSTOMER_STATUS = 'CHECK_CONSUMER_STATUS'
export const PAYMENT_COMPLETE = 'PAYMENT_COMPLETE'
export const PAYMENT_FAILURE = 'PAYMENT_FAILURE'
export const LIMITS_DETAILS = 'LIMITS_DETAILS'

import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  quotesData: [],
  customerForm: {
    phoneExt: '+1'
  },
  customerData: {},
  limitData: {},
  paymentForm: {},
  transactionData: {}
}

export const common = createSlice({
  name: 'wyre',
  initialState,
  reducers: {
    setQuotesData: (state, action) => {
      state.quotesData = action.payload
    },
    setCustomerData: (state, action) => {
      state.customerData = action.payload
    },
    setCustomerForm: (state, action) => {
      state.customerForm = action.payload
    },
    setPaymentForm: (state, action) => {
      state.paymentForm = action.payload
    },
    setLimitData: (state, action) => {
      state.limitData = action.payload
    },
    setTransactionData: (state, action) => {
      state.transactionData = action.payload
    },
  },
})

export const {
  setQuotesData,
  setCustomerData,
  setCustomerForm,
  setPaymentForm,
  setLimitData,
  setTransactionData,
} = common.actions

export default common.reducer

export const filterFiatCurrency = ( buySellFlow, initialValues, initialSourceCurrencyCode, destinationCurrencyCode, fiatCurrencies, fiatLocked ) => {
  const currency = (buySellFlow === 'BUY' ? initialSourceCurrencyCode : (initialValues.destinationCurrencyCode || destinationCurrencyCode))
  const validateCurrency = currency ? fiatCurrencies.find((fiat) => fiat.currencyCode === currency) : fiatCurrencies?.[0]
  if (!validateCurrency && !fiatLocked) {
    return fiatCurrencies.find((fiat) => fiat.currencyCode === 'USD') || fiatCurrencies?.[0]
  }
  return validateCurrency
}

export default filterFiatCurrency

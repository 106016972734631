import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  coins: [],
  currencies: [],
  countries: [],
  states: [],
}

export const common = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setCoins: (state, action) => {
      state.coins = action.payload
    },
    setCurrencies: (state, action) => {
      state.currencies = action.payload
    },
    setCountries: (state, action) => {
      state.countries = action.payload
    },
    setStates: (state, action) => {
      state.states = action.payload
    },
  },
})

export const {
  setCoins, setCurrencies, setCountries, setStates,
} = common.actions

export default common.reducer

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useValidateMerchant, useUpdateTransaction } from 'hooks/api'
import { setDisplayPage, showGlobalAlert } from 'store/slices/app'
import { setPaymentForm, setTransactionData } from 'store/slices/wyre'

import { Col, DatePicker, Form, Input } from 'antd'
import Button from 'components/ui/button'
import Divider from 'components/ui/divider'
import NumericInput from 'components/ui/numeric-input'
import LogoPageWrapper from 'components/ui/logo-page-wrapper'
import CreditCardNumberInput from 'components/ui/credit-card-input'
import { CRYPTO_QUOTE_RESULT, CAPTURE_BILLING_DETAILS, CHECK_TRANSACTION_STATUS } from '../pages'
import { WalletAddressContainer, WalletAddressText, WidgetHeader, WidgetHeaderTitle, BackButton } from 'components/ui/styled'

import { loadScript } from 'utils/dom'
import { GAevent } from 'utils/analytics'
import { isSafari, walletTagCurrencies } from 'utils/constants'
import { cleanCreditCardInput } from 'utils/data-model'
import { constructPaymentData } from 'components/providers/wyre/data-helpers'
import moment from 'moment'

const CapturePaymentDetails = () => {
  const dispatch = useDispatch()
  const quoteData = useSelector((state) => state?.wyre?.quotesData?.[0] || {})
  const sessionData = useSelector((state) => state.app.sessionData)
  const customerForm = useSelector((state) => state.wyre.customerForm)
  const { paymentForm: initialValues } = useSelector((state) => state.wyre)
  const { wizardSessionToken } = useSelector((state) => state.app)
  const config = {
    sessionToken: wizardSessionToken
  }

  const [applePayEnabled, setApplePayEnabled] = useState(false)
  const [isBusyProcessing, setIsBusyProcessing] = useState(false)

  const { mutateAsync: updateTransaction } = useUpdateTransaction(config)
  const { mutateAsync: validateMerchant } = useValidateMerchant()

  const onBackButtonClick = () => {
    if (isSafari && ['MOBILE_WALLET', 'APPLE_PAY'].includes(sessionData.sessionData.paymentMethodType)) {
      dispatch(setDisplayPage(CRYPTO_QUOTE_RESULT))
    } else {
      dispatch(setDisplayPage(CAPTURE_BILLING_DETAILS))
    }
  }

  const handleSubmit = async (_formValues) => {
    setIsBusyProcessing(true)
    let formValues = {
      ..._formValues,
      creditCardExpiration: moment(_formValues.creditCardExpiration).format('MM/YY')
    }
    dispatch(setPaymentForm(formValues))

    const transaction = await updateTransaction(constructPaymentData(quoteData, customerForm, sessionData, formValues))

    if (transaction?.response?.status >= 400 || transaction?.response?.data?.error || transaction?.response?.data?.message) {
      dispatch(showGlobalAlert({
        type: 'error',
        content: transaction?.response?.data?.error || transaction?.response?.data?.message || 'An error occurred.',
      }))
    } else {
      dispatch(setTransactionData(transaction))

      if (transaction?.transaction?.serviceProviderDetails?.authenticationUrl) {
        const top = window.outerHeight / 2 + window.screenY - (335)
        const left = window.outerWidth / 2 + window.screenX - (300)
        const popupWindow = window.open(transaction?.transaction?.serviceProviderDetails?.authenticationUrl,
          'targetWindow',
          `toolbar=no,location=no,status=no,menubar=no,width=${600},height=${670},top=${top},left=${left}`)
        popupWindow?.focus()

        window.addEventListener('message', (event) => {
          console.log('Wyre postmessage:', event)
          try {
            if (event?.origin?.includes('sendwyre') || event?.origin?.includes('testwyre')) {
              if (event?.data?.type === 'authentication' && event?.data?.status === 'completed') {
                popupWindow.close()
              }
            }
          } catch (e) {
            // no-op
          }
        }, false)
      }

      dispatch(setDisplayPage(CHECK_TRANSACTION_STATUS))
    }


    setIsBusyProcessing(false)
  }

  // todo: rework this abomination into something more sensible
  const onApplePayButtonClicked = () => {
    console.warn('onApplePayButtonClicked')
    if (!applePayEnabled) {
      console.warn('returning because apple pay is not enabled')
      return
    }
    if (!window.ApplePaySession) {
      console.error('Apple Pay not supported')
      return
    }
    let session
    setApplePayEnabled(false)

    try {
      session = new window.ApplePaySession(3, {
        countryCode: quoteData.countryCode,
        currencyCode: quoteData.sourceCurrencyCode,
        merchantCapabilities: ['supports3DS', 'supportsDebit', 'supportsCredit'],
        supportedNetworks: ['visa', 'masterCard', 'amex', 'discover'],
        requiredBillingContactFields: ['email', 'name', 'phone', 'postalAddress'],
        requiredShippingContactFields: ['email', 'name', 'phone', 'postalAddress'],
        total: {
          label: `My ${quoteData.sourceCurrencyCode}->${quoteData.destinationCurrencyCode} purchase`,
          type: 'final',
          amount: `${quoteData.sourceAmount}`,
        },
      })
      session.onvalidatemerchant = async () => {
        // Call your own server to request a new merchant session.
        const merchantSession = await validateMerchant()
        try {
          session.completeMerchantValidation(merchantSession.paymentSession)
        } catch (e) {
          console.error('Apple merchant validation error', e.message, e)
          dispatch(setDisplayPage('error'))
        }
      }
      session.onpaymentauthorized = async (event) => {
        let submittedTransaction
        try {
          submittedTransaction = await updateTransaction({
            sourceAmount: quoteData.sourceAmount,
            sourceCurrencyCode: quoteData.sourceCurrencyCode,
            paymentMethod: {
              type: 'APPLE_PAY',
              details: { ...event.payment }
            },
            customer: {
              email: customerForm.email,
              phone: `${customerForm.phoneExt}${customerForm.phoneNumber.replace(/[^0-9]/g, '')}`,
              firstName: customerForm.firstName,
              lastName: customerForm.lastName,
              billingAddress: {
                lineOne: customerForm.lineOne,
                lineTwo: customerForm.lineTwo,
                city: customerForm.city,
                state: customerForm.state,
                zipCode: customerForm.zipCode,
                countryCode: customerForm.countryCode,
              },
            },
            serviceProviderDetails: {
              destinationCurrencyCode: quoteData.destinationCurrencyCode,
              walletAddress: sessionData.sessionData.walletAddress,
            },
            serviceProvider: 'WYRE',
          })
        } catch (e) {
          console.warn('wyre error', e)
          dispatch(showGlobalAlert({
            type: 'error',
            content: `Wyre error when making cryptocurrency purchase`,
          }))
          session.abort()
          return
        }
        const transaction = submittedTransaction.transaction
        session.completePayment({
          status: session.STATUS_SUCCESS,
        })
        dispatch(setTransactionData(transaction))
        dispatch(setDisplayPage(CHECK_TRANSACTION_STATUS))
      }
      session.oncancel = (e) => {
        console.warn('apple session oncancel', e)
        setApplePayEnabled(true)
        dispatch(showGlobalAlert({
          type: 'error',
          content: `Transaction canceled`,
        }))
      }
      session.begin()
    } catch (e) {
      console.warn('error processing applepay', e)
      dispatch(showGlobalAlert({
        type: 'error',
        content: `Error processing Apple Pay transaction: ${e.message}`,
      }))
    }
  }

  useEffect(() => {
    GAevent('payment_details', { payment_provider: 'WYRE' })

    let init = async () => {
      if (sessionData.sessionData.paymentMethodType === 'APPLE_PAY' && isSafari) {
        await loadScript('applepay', 'https://applepay.cdn-apple.com/jsapi/v1/apple-pay-sdk.js')
        setTimeout(() => {console.warn('enabling applepay')
          setApplePayEnabled(true)
        }, 200)
      }
    }
    init()
  }, [])

  const message = { required: '${label} is required' }
  const rules = {
    CREDIT_CARD_NUMBER: [
      {
        required: true,
      },
      () => ({
        validator: (_, value) => {
          if (value) {
            const rawCardNumber = cleanCreditCardInput(value).replaceAll('_', '')
            return rawCardNumber.length === 16 ? Promise.resolve() : Promise.reject('Invalid Card Number')
          }
          return Promise.reject()
        },
      }),
    ],
    EXPIRATION: [
      {
        required: true,
      },
    ],
    CVC: [
      {
        required: true,
      },
      {
        len: 3,
        message: 'CVC must be 3 digits',
      },
    ],
  }

  if (!sessionData.sessionData.paymentMethodType || !['MOBILE_WALLET', 'APPLE_PAY'].includes(sessionData.sessionData.paymentMethodType)) {
    return (
      <LogoPageWrapper>
        <WidgetHeader>
          <BackButton order={0} onClick={onBackButtonClick} />
          <Col sm={16}>
            <WidgetHeaderTitle>Payment Information</WidgetHeaderTitle>
          </Col>
          <Col span={3}/>
        </WidgetHeader>
        <Divider style={{ display: 'flex' }} orientation="left"></Divider>
        <Form name="capture-payment-details" validateMessages={message} initialValues={initialValues} onFinish={handleSubmit} layout="vertical">
          <Form.Item rules={rules.CREDIT_CARD_NUMBER} name="creditCardNumber" label={<b>Card Number</b>} required={false}>
            <CreditCardNumberInput placeholder="Card Number" disabled={isBusyProcessing} />
          </Form.Item>
          <Input.Group size="large" compact>
            <Form.Item rules={rules.EXPIRATION} name="creditCardExpiration" label={<b>Expiration</b>} required={false} style={{ width: '50%' }}>
              <DatePicker disabledDate={(current) => current && current < moment().endOf('day')} size="large" format="MM/YY" picker="month" placeholder="MM/YY" disabled={isBusyProcessing} />
            </Form.Item>
            <Form.Item rules={rules.CVC} name="creditCardCVC" label={<b>CVC</b>} required={false} style={{ width: '50%' }}>
              <NumericInput size="large" placeholder="CVC" maxLength={3} pattern="[0-9]*" disabled={isBusyProcessing} />
            </Form.Item>
          </Input.Group>
          <WalletAddressContainer>
            <label>Wallet Address</label>
            <WalletAddressText>{sessionData.sessionData.walletAddress}</WalletAddressText>
          </WalletAddressContainer>
          {(walletTagCurrencies?.includes(sessionData.sessionData.destinationCurrencyCode) && sessionData.sessionData.walletTag) && (
            <WalletAddressContainer>
              <label>Wallet Tag</label>
              <WalletAddressText>{sessionData.sessionData.walletTag}</WalletAddressText>
            </WalletAddressContainer>
          )}
          <Form.Item>
            {['MOBILE_WALLET', 'APPLE_PAY'].includes(sessionData.sessionData.paymentMethodType) && applePayEnabled ? (
              <button disabled={!applePayEnabled} onClick={onApplePayButtonClicked} className="apple-pay-button" buttonstyle="black" type="plain" locale="en" />
            ) : (
              <Button size="large" type="primary" style={{ width: '100%' }} htmlType="submit" disabled={isBusyProcessing} loading={isBusyProcessing}>
                {isBusyProcessing ? 'SUBMITTING ORDER' : 'SUBMIT ORDER'}
              </Button>
            )}
          </Form.Item>
        </Form>
      </LogoPageWrapper>
    )
  } else if (['MOBILE_WALLET', 'APPLE_PAY'].includes(sessionData.sessionData.paymentMethodType)) {
    return (
      <LogoPageWrapper>
        <WidgetHeader>
          <BackButton order={0} onClick={onBackButtonClick} />
          <Col sm={16}>
            <WidgetHeaderTitle>Payment Information</WidgetHeaderTitle>
          </Col>
          <Col sm={3}/>
        </WidgetHeader>
        <Divider style={{ display: 'flex' }} orientation="left"></Divider>
        <WalletAddressContainer>
          <label>Wallet Address</label>
          <WalletAddressText>{sessionData.sessionData.walletAddress}</WalletAddressText>
        </WalletAddressContainer>
        {(walletTagCurrencies?.includes(sessionData.sessionData.destinationCurrencyCode) && sessionData.sessionData.walletTag) && (
          <WalletAddressContainer>
            <label>Wallet Tag</label>
            <WalletAddressText>{sessionData.sessionData.walletTag}</WalletAddressText>
          </WalletAddressContainer>
        )}
        <button onClick={onApplePayButtonClicked} className="apple-pay-button" buttonstyle="black" type="plain" locale="en" />
      </LogoPageWrapper>
    )
  } else {
    return <div />
  }
}

export default CapturePaymentDetails

function filterCountryList (countryList, param) {
  return countryList.find((country) => country.countryCode === param)?.countryCode
}
export const filterCountries = ({ urlParams, countryList, countryCode, countryCodeByTimezone }) => {
  // Execute this condition if url is having countryCodeLocked param
  let country = filterCountryList(countryList, urlParams?.countryCodeLocked)
  if (country) {
    return country
  }
  country = filterCountryList(countryList, urlParams?.countryCode)
  if (country) {
    return country
  }

  country = filterCountryList(countryList, countryCode)
  if (country) {
    return country
  }

  country = filterCountryList(countryList, countryCodeByTimezone)
  if (country) {
    return country
  }

  country = filterCountryList(countryList, 'US')
  if (country) {
    return country
  }

  return countryList[0]?.countryCode
}

export default filterCountries

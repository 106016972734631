/* eslint-disable complexity */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled, { useTheme } from 'styled-components'
import Button from 'components/ui/button'
import { Skeleton } from 'antd'
import { useGetServiceProviderDetails, useGetWidgetToken, usePostSessionData } from 'hooks/api'
import { setToken, setWizardSessionDirectUrl, setWizardSessionServiceProvider, setWizardSessionToken } from 'store/slices/app'
import jwt from 'jwt-decode'
import moment from 'moment'
import Quotes from './components/quotes'
import { redirectServiceProvider, walletTagCurrencies } from 'utils/constants'
import { setCurrentStep } from 'store/slices/wizard'
import mixPanel from './services/mixpanel'

const Wrapper = styled.div`
  position: ${({ position }) => position ? '' : 'absolute'};
  margin-top: ${({ position }) => position ? '-15px' : '0px'};
  width: 100%;
  left: 0;
  bottom: 0;
`

const Container = styled.div`
  border-radius: 8px;
  padding: 14px;
  /* border: 1px solid ${({ border }) => border}; */
  background-color: ${({ theme }) => theme.colors.quoteCardBackground};
`

const ContinueButton = styled(Button)`
  width: 100%;
  transition: transform .4s;
  &:hover {
    transform: scale(${({ transform }) => transform});
    transform-origin: 50% 50%;
  }

  &.ant-btn-primary {
    :disabled {
      opacity: 0.5;
      background: ${({ theme }) => theme.name === 'darkMode' ? theme.darkModeButtonColor : theme.buttonColor } ${({ theme }) => (theme.darkModeButtonColor || theme.buttonColor) && `!important`};
      background: linear-gradient(112.11deg, ${({ theme }) => theme.colors.filled} -18.69%, ${({ theme }) => theme.colors.highlightPrimary} 102.07%) ${({ theme }) => (!theme.darkModeButtonColor || !theme.buttonColor) && `!important`};
    }
  }
`

const SourceSkeleton = styled(Skeleton)`
  .ant-skeleton-content {
    display: flex;
    align-items: center;
  }
  .ant-skeleton-title {
    width: 100% !important;
  }
`

const WizardQuoteWrapper = () => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const imageUrl = useSelector((state) => state.account.accountLogo)
  const wizardBackgroundColor = useSelector((state) => state.theme.themeBackgroundColor)
  const { token } = useSelector((state) => state.app)
  const { publicKey, urlParams, buySellFlow, countryCode, sourceAmount, sourceCurrencyCode, destinationCurrencyCode, walletTag, paymentMethod, walletAddress, isSourceAmountError, isWalletAddressError } = useSelector((state) => state.wizard)
  const customerId = urlParams?.customerId
  const externalCustomerId = urlParams?.externalCustomerId
  const externalSessionId = urlParams?.externalSessionId
  const { data: widgetTokenResult } = useGetWidgetToken(publicKey)

  useEffect(() => {
    if (widgetTokenResult?.token) {
      dispatch(setToken(widgetTokenResult?.token))
    }
  }, [widgetTokenResult])

  let tokenExpiration = 0
  try {
    // JWT expresses expiration in Epoch seconds; moment uses Epoch milliseconds
    tokenExpiration = jwt(token)?.exp * 1000
  } catch (e) {
    // do nothing with the error; we don't care
  }
  const enableSPDetails = moment().isBefore(tokenExpiration) && !isSourceAmountError && !isWalletAddressError && sourceAmount > 0

  const { data: serviceProviderDetails } = useGetServiceProviderDetails({categories: 'CRYPTO_ONRAMP,CRYPTO_OFFRAMP'}, enableSPDetails)
  const { mutateAsync: postSessionData } = usePostSessionData(publicKey, widgetTokenResult)
  const [ isButtonDisabled, setIsButtonDisabled ] = useState(false)
  const [selectedProvider, setSelectedProvider] = useState(null)
  const [ isQuoteActive, setIsQuoteActive ] = useState(false)
  const [ drawerOpen, setDrawerOpen ] = useState(false)

  useEffect(() => {
    if (buySellFlow === 'BUY' && countryCode && sourceAmount > 0 && sourceCurrencyCode && destinationCurrencyCode && paymentMethod?.paymentMethod && !isSourceAmountError && !isWalletAddressError) {
      if (walletTagCurrencies?.includes(sourceCurrencyCode) || walletTagCurrencies?.includes(destinationCurrencyCode) && walletTag) {
        setIsQuoteActive(false)
      }
      if (!(walletTagCurrencies?.includes(sourceCurrencyCode) || walletTagCurrencies?.includes(destinationCurrencyCode))) {
        setIsQuoteActive(false)
      }
    } else if (buySellFlow === 'SELL' && countryCode && sourceAmount > 0 && sourceCurrencyCode && destinationCurrencyCode && !isSourceAmountError) {
      if (walletTagCurrencies?.includes(sourceCurrencyCode) || walletTagCurrencies?.includes(destinationCurrencyCode) && walletTag) {
        setIsQuoteActive(false)
      }
      if (!(walletTagCurrencies?.includes(sourceCurrencyCode) || walletTagCurrencies?.includes(destinationCurrencyCode))) {
        setIsQuoteActive(false)
      }
    } else {
      setIsQuoteActive(true)
      setIsButtonDisabled(true)
    }
  }, [countryCode, sourceAmount, sourceCurrencyCode, destinationCurrencyCode, paymentMethod, walletAddress, isSourceAmountError, isWalletAddressError, walletTag])

  const handleSubmit = async () => {
    mixPanel({
      eventName: buySellFlow === 'BUY' ? 'buy_wizard_continue' : 'sell_wizard_continue',
      buySellFlow: buySellFlow,
      cryptoCurrencyCode: buySellFlow === 'BUY' ? destinationCurrencyCode : sourceCurrencyCode,
    })
    setIsButtonDisabled(true)
    dispatch(setWizardSessionDirectUrl(''))
    const lockFields = []
    if (urlParams?.walletAddressLocked) {
      lockFields.push('walletAddress')
    }
    if (buySellFlow === 'BUY' && destinationCurrencyCode === urlParams?.destinationCurrencyCodeLocked) {
      lockFields.push('destinationCurrencyCode')
    }
    if (buySellFlow === 'SELL' && sourceCurrencyCode === urlParams?.sourceCurrencyCodeLocked) {
      lockFields.push('sourceCurrencyCode')
    }

    const data = {
      sessionData: {
        countryCode,
        sourceCurrencyCode,
        destinationCurrencyCode,
        sourceAmount,
        walletAddress: walletAddress,
        paymentMethodType: paymentMethod.paymentMethod,
        serviceProvider: selectedProvider,
        walletTag,
      }
    }
    if (customerId) {
      data.customerId = customerId
    }
    if (externalCustomerId) {
      data.externalCustomerId = externalCustomerId
    }
    if (externalSessionId) {
      data.externalSessionId = externalSessionId
    }
    if (lockFields.length > 0) {
      data.sessionData.lockFields = lockFields
    }
    history.pushState({ paymentMethodType: paymentMethod.subtype, transactionType: buySellFlow, ...data.sessionData }, 'Wizard')
    const sessionData = await postSessionData(data)
    if (redirectServiceProvider.includes(selectedProvider) && sessionData?.token) {
      if (selectedProvider === 'ROBINHOOD') {
        dispatch(setWizardSessionDirectUrl(sessionData?.widgetUrl))
      }
      dispatch(setWizardSessionToken(sessionData?.token))
      dispatch(setWizardSessionServiceProvider(getServiceProviderName(selectedProvider)))
      return dispatch(setCurrentStep('wizardRedirect'))
    } else {
      sessionStorage.clear()
      sessionStorage.setItem('wizardBackgroundColor', wizardBackgroundColor)
      sessionStorage.setItem('wizardRedirect', true)
      sessionStorage.setItem('wizardRedirectSearchParam', window?.location?.search || '')
      window.location.href = `${window.location.origin}?token=${sessionData?.token}`
    }
  }

  const getServiceProviderName = (serviceProviderName) => {
    if(serviceProviderDetails?.length > 0) {
      const { name } = serviceProviderDetails.find((name) => name.serviceProvider === serviceProviderName)
      if(!name) return serviceProviderName
      return name
    }
    return <Skeleton active paragraph={{ rows: 1 }} title={false} />
  }

  return (
    <>
      {
        imageUrl !== '' &&
        <Wrapper position={drawerOpen}>
          <Container border={theme.name === 'darkMode' ? '#8569B3' : '#65A4C1'}>
            <SourceSkeleton active loading={!sourceCurrencyCode} paragraph={{rows: 0}}>
              {
                isQuoteActive &&
                  <Quotes
                    enableSPDetails={enableSPDetails}
                    serviceProviderDetails={serviceProviderDetails}
                    isQuoteFetched={(e) => setIsButtonDisabled(e) }
                    serviceProvider={(e) => setSelectedProvider(e)}
                    onOpen={(e) => {setDrawerOpen(e)}}
                    onClose={(e) => {setDrawerOpen(e)}}
                  />
              }
              <ContinueButton
                transform={isButtonDisabled ? '1' : '1.03'}
                disabled={isButtonDisabled}
                type="primary"
                onClick={(e) => handleSubmit()}
                className={publicKey + ' ' + 'stage'}
              >{buySellFlow === 'BUY' ? 'Buy' : 'Sell'} {buySellFlow === 'BUY' ? destinationCurrencyCode : sourceCurrencyCode}</ContinueButton>
            </SourceSkeleton>
          </Container>
        </Wrapper>
      }
    </>
  )
}

export default WizardQuoteWrapper

/* eslint-disable complexity */
import React, { useEffect, useMemo, useState } from 'react'
import { useGetQuotes } from 'hooks/api'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { Skeleton } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import _ from 'lodash'
import { setDestinationCurrencyAmount, setExchangeRateAmount } from 'store/slices/wizard'
import DrawerQuotes from './drawerQuotes'
import { walletTagCurrencies } from 'utils/constants'
import mixPanel from '../services/mixpanel'

const Container = styled.div`
  border-radius: 8px;
  margin: 0px 0px 10px 0px;
`

const QuoteContainer = styled.div`
  height: calc(100% - 53px);
  overflow-y: auto;
`
const ErrorSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
const ErrorMessage = styled.div`
  margin: 10px;
  text-align: center;
  color: ${({ theme }) => theme.colors.errorText};
  width: 60%;
`

const SourceSkeleton = styled(Skeleton)`
  .ant-skeleton-content {
    display: flex;
    align-items: center;
  }
  .ant-skeleton-title {
    width: 100% !important;
  }
`

const InputWrapper = styled.div`
  margin-top: 0px;
`

const Quotes = ({ enableSPDetails, serviceProviderDetails, isQuoteFetched, serviceProvider, onOpen, onClose }) => {
  const dispatch = useDispatch()
  const refreshDuration = 6000 * 1000
  let refetchInterval = refreshDuration
  let refetchEveryMinuteUpToFiveMinutes = refreshDuration
  const { buySellFlow, countryCode, sourceAmount, sourceCurrencyCode, destinationCurrencyCode, paymentMethod, walletAddress, isSourceAmountError, isWalletAddressError, walletTag } = useSelector((state) => state.wizard)

  const [invalidWalletAddressErrorMessage, setInvalidWalletAddressErrorMessage] = useState('')
  const [ selectedQuote, setSelectedQuote ] = useState([])
  let quoteEnabled = false

  if (buySellFlow === 'BUY' && countryCode && sourceAmount > 0 && sourceCurrencyCode && destinationCurrencyCode && paymentMethod?.paymentMethod && !isSourceAmountError && !isWalletAddressError) {
    if (walletTagCurrencies?.includes(sourceCurrencyCode) || walletTagCurrencies?.includes(destinationCurrencyCode) && walletTag) {
      quoteEnabled = enableSPDetails && true
    }
    if (!(walletTagCurrencies?.includes(sourceCurrencyCode) || walletTagCurrencies?.includes(destinationCurrencyCode))) {
      quoteEnabled = enableSPDetails && true
    }
  } else if (buySellFlow === 'SELL' && countryCode && sourceAmount > 0 && sourceCurrencyCode && destinationCurrencyCode && !isSourceAmountError) {
    if (walletTagCurrencies?.includes(sourceCurrencyCode) || walletTagCurrencies?.includes(destinationCurrencyCode) && walletTag) {
      quoteEnabled = enableSPDetails && true
    }
    if (!(walletTagCurrencies?.includes(sourceCurrencyCode) || walletTagCurrencies?.includes(destinationCurrencyCode))) {
      quoteEnabled = enableSPDetails && true
    }
  } else {
    quoteEnabled = enableSPDetails && false
  }

  const { data, isFetching } = useGetQuotes({
    countryCode,
    sourceCurrencyCode,
    destinationCurrencyCode,
    sourceAmount: +sourceAmount,
    walletAddress,
    paymentMethodType: paymentMethod.paymentMethod,
  }, quoteEnabled, refetchEveryMinuteUpToFiveMinutes)

  refetchEveryMinuteUpToFiveMinutes = useMemo(() => {
    setTimeout(() => {
      refetchInterval = false
    }, 10 * refreshDuration)

    if (!refetchInterval) {
      document.addEventListener('click', () => {
        refetchInterval = refreshDuration
        history.pushState({
          countryCode,
          sourceAmount,
          sourceCurrencyCode,
          destinationCurrencyCode,
          walletAddress,
          paymentMethodType: paymentMethod.subtype
        }, 'Wizard')
        window.location.reload()
      })
    }
    return refetchInterval
  }, [refetchInterval])

  useEffect(() => {
    if (!isFetching && data?.quotes) {
      if (buySellFlow === 'BUY' && walletAddress) {
        isQuoteFetched(false)
      } else if (buySellFlow === 'SELL') {
        isQuoteFetched(false)
      }
      const displayQuote = data?.quotes?.filter((quote) => quote.destinationAmount > 0).sort(sortQuotes).slice(0, 1)
      if (displayQuote.length > 0 && displayQuote[0].serviceProvider) {
        selectedQuotation(displayQuote)
      }
    }
    if (isFetching) {
      dispatch(setDestinationCurrencyAmount(0))
      dispatch(setExchangeRateAmount(0))
    }
  }, [data, isFetching])

  const selectedQuotation = (displayQuote) => {
    serviceProvider(displayQuote[0].serviceProvider)
    setSelectedQuote(displayQuote)
    dispatch(setDestinationCurrencyAmount(displayQuote[0].destinationAmount))
    dispatch(setExchangeRateAmount(displayQuote[0].exchangeRate))
  }

  const isError = data?.response?.status >= 400 || data?.response?.data?.error || data?.response?.data?.message || _.every(data?.quotes, (quote) => quote.destinationAmount === 0)
  const mapError = () => {
    const message = `No support for this combination. Please try another option.`
    setInvalidWalletAddressErrorMessage(message)
  }

  const sortQuotes = (a,b) => {
    return b.destinationAmount - a.destinationAmount
  }

  useEffect(()=>{
    if (!isFetching && !!isError) {
      mapError()
    }
  },[isError, isFetching])

  return (
    <>
      <Container>
        <QuoteContainer>
          <SourceSkeleton active loading={isFetching} paragraph={{rows: 0}}>
            { invalidWalletAddressErrorMessage && isError &&
              <ErrorSection>
                <ErrorMessage>
                  <div><ExclamationCircleOutlined /></div>
                  {invalidWalletAddressErrorMessage}
                </ErrorMessage>
              </ErrorSection>
            }
            {
              selectedQuote?.length > 0 && !isError &&
              <InputWrapper>
                <DrawerQuotes
                  selectedQuote={selectedQuote}
                  serviceProviderDetails={serviceProviderDetails}
                  buySellFlow={buySellFlow}
                  data={data}
                  header={''}
                  onSelect={(e) => {
                    setSelectedQuote(e)
                    selectedQuotation(e)
                    mixPanel({
                      eventName: buySellFlow === 'BUY' ? 'buy_details_quote_selection' : 'sell_details_quote_selection',
                      buySellFlow: buySellFlow,
                      serviceProvider: serviceProvider,
                      payload: {
                        countryCode: countryCode,
                        amount: sourceAmount,
                        paymentMethodType: paymentMethod.subtype,
                        sourceCurrencyCode: sourceCurrencyCode,
                        destinationCurrencyCode: destinationCurrencyCode
                      }
                    })
                  }}
                  onOpen={(e) => {
                    onOpen(e)
                    mixPanel({
                      eventName: buySellFlow === 'BUY' ? 'buy_details_quote_drawer_open' : 'sell_details_quote_drawer_open',
                      buySellFlow: buySellFlow
                    })
                  }}
                  onClose={(e) => {
                    onClose(e)
                    mixPanel({
                      eventName: buySellFlow === 'BUY' ? 'buy_details_quote_drawer_back_button' : 'sell_details_quote_drawer_back_button',
                      buySellFlow: buySellFlow
                    })
                  }}
                />
              </InputWrapper>
            }
          </SourceSkeleton>
        </QuoteContainer>
      </Container>
    </>
  )
}

export default Quotes

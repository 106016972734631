/* eslint-disable complexity */
import React, { useEffect, useState } from 'react'
import { Badge, List, Skeleton } from 'antd'
import styled, { useTheme } from 'styled-components'

import Currency from 'components/ui/currency'
// eslint-disable-next-line no-unused-vars
import { CloseOutlined, MinusOutlined } from '@ant-design/icons'

import { isMobileScreen } from 'utils/constants'
import { useSelector } from 'react-redux'
import filterQuotes from '../services/filterQuotes'
import { ReactComponent as ChevronSvg } from './icons/chevron-down-icon.svg'
import Icon from '@ant-design/icons'

const QuoteItem = styled(List.Item)`
  position: relative;
  border-bottom: none;
  padding: ${({ ismobilescreen }) => ismobilescreen};
  /* margin: 8px 0px; */
  /* border: 1px solid ${({ theme }) => theme.colors.cardPaymentBorder}; */
  /* border-bottom: 1px solid ${({ theme }) => theme.colors.cardPaymentBorder} !important; */
  background: ${({ theme }) => theme.colors.currencyCardBackground};
  border-radius: 8px;
  font-size: 14px;
  h4 {
    margin: 0px;
  }
`

const QuoteItemList = styled(List.Item)`
  position: relative;
  border-bottom: none;
  padding: ${({ ismobilescreen }) => ismobilescreen};
  margin: 8px 0px;
  border: 1px solid ${({ activeborder }) => activeborder};
  border-bottom: 1px solid ${({ activeborder }) => activeborder} !important;
  background: ${({ theme }) => theme.colors.quoteBackground};
  border-radius: 8px;
  font-size: 14px;
  h4 {
    margin: 0px;
  }
  cursor: pointer;
  // &:hover {
  //   background: ${({ theme }) => theme.colors.cardBackground};
  //   border: 1px solid ${({ theme }) => theme.colors.quoteHover};
  //   border-bottom: 1px solid ${({ theme }) => theme.colors.quoteHover} !important;
  // }
`

const QuoteRow = styled.div`
  display: flex;
  flex-direction: ${({ full }) => full ? 'column' : 'row'};
  justify-content: space-between;
  font-size: ${({ titleProp }) => titleProp ? '17px' : '14px'};
  &, label {
    color: ${({ theme }) => theme.colors.foregroundPrimary} !important;
    height:100%;
    svg {
      /* fill: ${({ theme }) => theme.colors.foregroundPrimary} !important; */
    }
  };
  cursor: ${({ isClick }) => isClick ? 'pointer' : 'auto'};
  font-weight: ${({ bold }) => bold ? 'bold' : 'normal'};
`

const QuoteSelectedRow = styled.div`
  display: flex;
  flex-direction: ${({ full }) => full ? 'column' : 'row'};
  justify-content: space-between;
  font-size: ${({ titleProp }) => titleProp ? '17px' : '14px'};
  &, label {
    color: ${({ theme }) => theme.colors.quoteColor} !important;
    height:100%;
    svg {
      /* fill: ${({ theme }) => theme.colors.foregroundPrimary} !important; */
    }
  };
  cursor: ${({ isClick }) => isClick ? 'pointer' : 'auto'};
  font-weight: ${({ bold }) => bold ? 'bold' : 'normal'};
`

const QuoteLogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  // margin-top: -2px;
`

const QuoteDiv = styled.div`
  font-size: 14px;
  display: flex;
  justify-content: right;
  align-items: center;
  text-align: end;
`

const ProviderName = styled.div`
  font-size: 16px;
  margin-left: 10px;
`

const DownDownIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.downIcon};
  font-size: 14px;
  margin: 0 6px;
  font-weight: 500;
`

const Blur = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  z-index: 9;
  backdrop-filter: blur(10px);
  border-radius: 6px 6px 0 0;
  background: rgb(49 48 48 / 30%);
`

const Drawer = styled.div`
  color: #000000;
  position: absolute;
  left: 0;
  right: 0;
  height: 93%;
  border-radius: 6px;
  background: ${({ theme }) => theme.colors.currencyCardBackground};
  z-index: 11;
  padding: 15px;
  display: ${({ height }) => height};
  transform: translateY(100%);
	-webkit-transform: translateY(100%);
`

const Header = styled.div`
  color: ${({ theme }) => theme.colors.drawerHeaderColor};
  text-align: left;
  font-size: 18px;
  position: relative;
  font-weight: 500;
  /* margin-bottom: 20px; */
`
const SubHeader = styled.div`
  color: #94969C;
  text-align: left;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 15px;
  margin-top: 10px;
`

const DrawerList = styled(List)`
  height: ${({ ismobilescreen }) => ismobilescreen};
  overflow-y: auto;
`

// const Filter = styled(Badge)`
//   margin-right: 12px !important;
//   margin-bottom: 20px !important;
//   cursor: pointer !important;

//   .ant-badge-multiple-words {
//     padding: 0 10px !important;
//   }
//   .ant-badge-count {
//     color: ${({ textColor }) => textColor} !important;
//     border: 1px solid ${({ border }) => border} !important;
//     background: ${({ background }) => background} !important;
//     height: 30px !important;
//     border-radius: 8px !important;
//     line-height: 30px !important;
//     box-shadow: none !important;
//     font-size: 14px !important;
//   }
// `

const RecommendedTag = styled(Badge)`
  margin-right: 0px !important;
  margin-bottom: 3px !important;
  cursor: pointer !important;

  .ant-badge-multiple-words {
    padding: 0 10px !important;
  }
  .ant-badge-count {
    color: ${({ theme }) => theme.colors.recommendedText} !important;
    border: 1px solid ${({ theme }) => theme.colors.filled} !important;
    background: ${({ theme }) => theme.colors.quoteBackground} !important;
    height: 20px !important;
    border-radius: 4px !important;
    line-height: 18px !important;
    box-shadow: none !important;
    font-size: 12px !important;
  }
`

// const QuoteNumber = styled.span`
//   color: ${({ theme }) => theme.colors.quoteSize};
//   font-size: 10px;
//   position: absolute;
//   left: 10px;
// `

const QuoteFilter = styled.div`
  display: flex;
  justify-content: left;
`

// eslint-disable-next-line no-unused-vars
const QuoteAmountFilter = styled.div`
  display: flex;
  justify-content: center;
  font-size: 20px;
  margin-bottom: 15px;
  color: ${({ theme }) => theme.colors.drawerHeaderColor};
`
const StyledList = styled(List)`
.ant-list-items{
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 10px 0 9px 0 !important;
  // background: ${({ theme }) => theme.colors.currencyCardBackground};
}`

const ProviderSection = styled.div`
  display: flex;
  justify-content: center;
`

const DrawerQuotesNew = ({ selectedQuote, serviceProviderDetails, buySellFlow, data, header, onOpen, onClose, onSelect }) => {
  const customerScoreLimit = 30
  const showDrawer = 1
  const theme = useTheme()
  const [openDrawer, setOpenDrawer] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const { topServiceProviders } = useSelector((state) => state.serviceProvider)
  // eslint-disable-next-line no-unused-vars
  const { sourceCurrencyCode, sourceAmount } = useSelector((state) => state.wizard)
  const [selectedQuotation, setSelectedQuotation] = useState(selectedQuote)
  const [sortBy, setSortBy] = useState('amountReceived')

  const [touchStart, setTouchStart] = useState(null)
  const [touchEnd, setTouchEnd] = useState(null)
  const [ drawerPosition, setDrawerPosition ] = useState('bottom-center')
  const [ drawerClass, setDrawerClass ] = useState('slide-bottom-center')
  const minSwipeDistance = 5

  useEffect(() => {
    setSelectedQuotation(selectedQuote)
  }, [selectedQuote])

  const getServiceProviderLogo = (serviceProviderName, width) => {
    if (serviceProviderDetails?.length > 0) {
      const logos = serviceProviderDetails.find((logo) => logo.serviceProvider === serviceProviderName)?.logos || ''
      if (!logos) return serviceProviderName
      return (<img
        src={theme.name === 'darkMode' ? logos.darkShort : logos.lightShort}
        style={{ margin: '3px 5px', width: width ? width : '32px', height: width ? width : '32px' }}
        alt='' />)
    }
    return <Skeleton active paragraph={{ rows: 1 }} title={false} />
  }

  const getServiceProviderName = (serviceProviderName) => {
    if (serviceProviderDetails?.length > 0) {
      const name = serviceProviderDetails.find((name) => name.serviceProvider === serviceProviderName)?.name || ''
      if (!name) return serviceProviderName
      return name
    }
    return <Skeleton active paragraph={{ rows: 1 }} title={false} />
  }

  const sortQuotes = (a, b) => {
    if (sortBy === 'recommended') {
      return b.customerScore - a.customerScore
    }
    if (sortBy === 'amountReceived') {
      return b.destinationAmount - a.destinationAmount
    }
    return b.destinationAmount - a.destinationAmount
  }

  const reorderByTopServiceProviders = (quotes, topServiceProviders) => {
    if (!topServiceProviders || topServiceProviders.length === 0) {
      return quotes?.slice().sort(sortQuotes) // Return sorted quotes if topServiceProviders is null or empty
    }
    const topProvidersUpperCase = topServiceProviders
    const sortedQuotes = quotes?.slice().sort(sortQuotes)

    return filterQuotes(sortedQuotes, topProvidersUpperCase)
  }

  const resetDrawer = (isBackButtonClicked) => {
    setSortBy('amountReceived')
    setTimeout(() => {
      setOpenDrawer(false)
    }, 200)
    setTimeout(() => {
      setIsOpen(false)
    }, 500)
    setTimeout(() => {
      onClose(false)
    }, 200)
  }

  const onTouchStart = (e) => {
    setTouchEnd(null) // otherwise the swipe is fired even with usual touch events
    setTouchStart(e.targetTouches[0].clientY)
  }

  const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientY)

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return
    const distance = touchStart - touchEnd
    const isUpSwipe = distance > minSwipeDistance
    const isDownSwipe = distance < -minSwipeDistance
    if (isUpSwipe || isDownSwipe) {
      dragDrawer(isUpSwipe)
    }
  }

  const dragDrawer = (isUpSwipe) => {
    let closeDrawer = false
    if (isUpSwipe) {
      if (drawerPosition === 'center-top') {
        updateDrawerConfig('slide-center-top', 'top-center')
      }
      if (drawerPosition === 'center-bottom') {
        updateDrawerConfig('slide-center-top', 'top-center')
      }
    } else if (!isUpSwipe) {
      if (drawerPosition === 'top-center') {
        updateDrawerConfig('slide-top-center', 'center-bottom')
      }
    }
    if (closeDrawer) {
      resetDrawer()
    }
  }

  const changeDrawerHeight = () => {
    let closeDrawer = false
    if (drawerPosition === 'bottom-center') {
      updateDrawerConfig('slide-bottom-center', 'center-top')
    }
    if (drawerPosition === 'center-top') {
      updateDrawerConfig('slide-center-top', 'top-center')
    }
    if (drawerPosition === 'top-center') {
      updateDrawerConfig('slide-top-center', 'center-bottom')
    }
    if (drawerPosition === 'center-bottom') {
      updateDrawerConfig('slide-center-top', 'top-center')
    }
    if (closeDrawer) {
      resetDrawer()
    }
  }

  const onScroll = (e) => {
    if (drawerPosition === 'center-top') {
      updateDrawerConfig('slide-center-top', 'top-center')
    }
    if (drawerPosition === 'center-bottom') {
      updateDrawerConfig('slide-center-top', 'top-center')
    }
  }

  const closeDrawer = () => {
    if (drawerPosition === 'center-top' || drawerPosition === 'center-bottom') {
      updateDrawerConfig('slide-center-bottom', 'bottom-center')
      resetDrawer(true)
      return
    }
    updateDrawerConfig('slide-top-bottom', 'bottom-center')
    resetDrawer(true)
  }

  const updateDrawerConfig = (drawerClassName, position) => {
    setDrawerClass(drawerClassName)
    setDrawerPosition(position)
  }

  return (
    <>
      <StyledList
        itemLayout="horizontal"
        dataSource={selectedQuotation?.filter((quote) => quote.destinationAmount > 0).sort(sortQuotes).slice(0, 1)}
        // eslint-disable-next-line complexity
        renderItem={quote => (
          <QuoteItem
            ismobilescreen={isMobileScreen.toString() === 'true' ? '12px 8px 16px 8px' : '15px 16px'}
            onClick={() => {
              if (data?.quotes?.length > showDrawer) {
                setOpenDrawer(true)
                onOpen(true)
                setIsOpen(true)
                changeDrawerHeight()
              }
            }}
            style={{ cursor: data?.quotes?.length > showDrawer && 'pointer' , padding: '0px'}}
          >
            <List.Item.Meta
              style={{ marginTop: '0px', maxWidth: '97%',padding: '0px' }}
              title={
                <QuoteSelectedRow
                  titleProp
                  isClick={data?.quotes?.length > showDrawer ? true : false}
                >
                  <QuoteLogoContainer>
                    <ProviderName>
                      <ProviderSection>
                        <span style={{ color: theme.colors.labelColor, marginTop: '-1px' }}>By</span>
                        {getServiceProviderLogo(quote.serviceProvider, '18px')}
                        <span>{getServiceProviderName(quote.serviceProvider)}</span>
                      </ProviderSection>
                      {/* <div><TagLabel color="blue">Recommended</TagLabel></div> */}
                    </ProviderName>
                  </QuoteLogoContainer>
                  <span style={{ display: 'flex', flexDirection: 'row' }}>
                    {data?.quotes?.length > 1 ?
                    <QuoteDiv>
                      <div>See more quotes</div> <DownDownIcon component={ChevronSvg} />
                    </QuoteDiv>
                    : <Currency currencyCode={quote.destinationCurrencyCode} forceCrypto={buySellFlow === 'BUY'} exchangeRate={quote.exchangeRate}>{quote.destinationAmount}</Currency>
                     }
                    {/* {
                      data?.quotes?.length > showDrawer &&
                      <DownIcon opacity={theme.name === 'darkMode' ? '0.  4' : '1'}>
                        <QuoteNumber>1 of {data?.quotes?.length}</QuoteNumber>
                        <DownDownIcon />
                      </DownIcon>
                    } */}
                  </span>
                </QuoteSelectedRow>}
            />
          </QuoteItem>
        )}
      />
      {openDrawer && <Blur/>}
      <Drawer
        onTouchStart={onTouchStart} onTouchMove={onTouchMove} onTouchEnd={onTouchEnd}
        height={!isOpen && 'none'}
        className={drawerClass}
      >
        <Header>
          <MinusOutlined style={{ position: 'absolute', right: 0, left: 0, top: '-12px' }} onClick={() => changeDrawerHeight()} />
          <CloseOutlined style={{ position: 'absolute', right: 0, top: 13, zIndex: 9 }} onClick={() => closeDrawer()} />
          {header}
        </Header>
        <Header style={{ paddingTop: '7px' }}>
          {buySellFlow === 'BUY' ? 'Select a Quote to Buy Crypto' : 'Select a Quote to Sell Crypto'}
        </Header>
        <SubHeader>
          Available onramps based on location
        </SubHeader>
        <QuoteFilter>
          {/* <Filter
            background={theme.name === 'darkMode' ? (sortBy === 'recommended' ? '#f969d3' : '#161b26') : (sortBy === 'recommended' ? '#f969d3' : '#f9fafb')}
            border={theme.name === 'darkMode' ? (sortBy === 'recommended' ? '#f969d3' : '#333741' ) : (sortBy === 'recommended' ? '#f969d3' : '#eaecf0' )}
            textColor={theme.name === 'darkMode' ? (sortBy === 'recommended' ? '#ffffff' : '#94969c' ) : (sortBy === 'recommended' ? '#ffffff' : '#94969c' )}
            count={'Recommended'}
            onClick={() => setSortBy('recommended')}
          />
          <Filter
            background={theme.name === 'darkMode' ? (sortBy === 'amountReceived' ? '#f969d3' : '#161b26') : (sortBy === 'amountReceived' ? '#f969d3' : '#f9fafb')}
            border={theme.name === 'darkMode' ? (sortBy === 'amountReceived' ? '#f969d3' : '#333741' ) : (sortBy === 'amountReceived' ? '#f969d3' : '#eaecf0' )}
            textColor={theme.name === 'darkMode' ? (sortBy === 'amountReceived' ? '#ffffff' : '#94969c' ) : (sortBy === 'amountReceived' ? '#ffffff' : '#94969c' )}
            count={'Lowest Fees'}
            onClick={() => setSortBy('amountReceived')}
          /> */}
        </QuoteFilter>
        <DrawerList
         onScroll={onScroll}
          itemLayout="horizontal"
          ismobilescreen={isMobileScreen ? '80%' : '85%'}
          dataSource={reorderByTopServiceProviders(data?.quotes?.filter((quote) => quote.destinationAmount > 0), topServiceProviders)}
          renderItem={quote => (
            <QuoteItemList
              activeborder={selectedQuotation?.find((selectQuote) => selectQuote.serviceProvider === quote.serviceProvider) ? theme.colors.filled : theme.colors.recommendedBorder}
              ismobilescreen={isMobileScreen.toString() === 'true' ? '12px 8px 16px 8px' : '16px'}
              onClick={() => {
                setSelectedQuotation([quote])
                onSelect([quote])
                closeDrawer()
              }}
            >
              <List.Item.Meta
                style={{ marginTop: '0px', maxWidth: '97%',padding: '0px' }}
                title={
                  <QuoteRow
                    titleProp
                    isClick={true}
                  >
                    <QuoteLogoContainer>
                      {getServiceProviderLogo(quote.serviceProvider)}
                      <ProviderName>
                        <div>{getServiceProviderName(quote.serviceProvider)}</div>
                        {
                          quote.customerScore >= customerScoreLimit ?
                            <RecommendedTag count={'Recommended'} />
                          : quote.lowKyc === true && <RecommendedTag count={'No Document Upload'} />
                        }
                      </ProviderName>
                    </QuoteLogoContainer>
                    <span style={{ display: 'flex', flexDirection: 'column' }}>
                      <QuoteDiv>
                        <Currency currencyCode={quote.destinationCurrencyCode} forceCrypto={buySellFlow === 'BUY'} exchangeRate={quote.exchangeRate}>{quote.destinationAmount}</Currency>
                      </QuoteDiv>
                      <QuoteDiv>
                        ≈ <span style={{ margin: '0px 5px' }}><Currency currencyCode={sourceCurrencyCode}>{sourceAmount}</Currency></span> {sourceCurrencyCode}
                      </QuoteDiv>
                    </span>
                  </QuoteRow>}
              />
            </QuoteItemList>
          )}
        />
      </Drawer>
    </>
  )
}

export default DrawerQuotesNew

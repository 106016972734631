import { createSlice } from '@reduxjs/toolkit'

const DEFAULT = {
  name: 'meld',
  label: 'Meld',
  options: {
    returnLinkTitle: 'Meld\'s Crypto Wizard',
    returnUrl: 'https://meldcrypto.com/'
  },
  styling: {
    global: {
      logoAltText: 'Meld logo',
      footerPostscript: ''
    }
  }
}

const UNISWAP = {
  name: 'uniswap',
  label: 'Uniswap',
  options: {
    returnLinkTitle: 'Uniswap Crypto Wizard',
    returnUrl: 'https://uniswap.meldcrypto.com/'
  },
  styling: {
    global: {
      logoAltText: 'Uniswap logo',
      footerPostscript: ''
    }
  }
}

const ACCOUNT_MAP = {
  'WQ4Xf8uJysJQa7hW8nJoqZ': UNISWAP,
  'WQ5RyhdFzE45qjsomdzQ1u': UNISWAP,
}

const PRELOAD_MAP = {
  'localhost:3002': DEFAULT,
  'uniswap-sb.meldcrypto.com': UNISWAP,
  'uniswap.meldcrypto.com': UNISWAP,
}

export const appSlice = createSlice({
  name: 'account',
  initialState: {
    current: window?.location?.host ? (PRELOAD_MAP[window.location.host] || DEFAULT) : DEFAULT,
    accountLogo: '',
    returnUrl: '',
    serviceProviderBackButton: false,
    showAccountLogoOnTop: false,
  },
  reducers: {
    setCurrentAccount: (state, action) => {
      state.current = ACCOUNT_MAP[action.payload] || DEFAULT
    },
    setAccountLogo: (state, action) => {
      state.accountLogo = action.payload
    },
    setReturnUrl: (state, action) => {
      state.returnUrl = action.payload
    },
    setServiceProviderBackButton: (state, action) => {
      state.serviceProviderBackButton = action.payload
    },
    setShowAccountLogoOnTop: (state, action) => {
      state.showAccountLogoOnTop = action.payload
    },
  },
})

export const getCurrentAccount = (state) => state.current

export const { setCurrentAccount, setAccountLogo, setReturnUrl, setServiceProviderBackButton, setShowAccountLogoOnTop } = appSlice.actions

export default appSlice.reducer

import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  preferencesData: {
    preferences: [
      {
        status: 'ACTIVE',
        type: 'CRYPTO',
        preference: {
          backgroundColor: '#000000',
          borderColor: '#000000',
          borderTextColor: '#ffffff',
          lightModeBackground: '#E8F8FF',
          darkModeBackground: '#1f2351',
          onrampEnabled: true,
          offrampEnabled: true,
          maxQuotes: 15,
          buttonColor: '#F969D3',
          hideBackButton: false,
          showAccountLogoOnTop: true,
          darkModeButtonColor: '#F969D3'
        }
      }
    ]
  }
}

export const common = createSlice({
  name: 'preferences',
  initialState,
  reducers: {
    setPreferencesData: (state, action) => {
      state.preferencesData = action.payload
    }
  },
})

export const {
  setPreferencesData,
} = common.actions

export default common.reducer

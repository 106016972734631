import React from 'react'
import styled from 'styled-components'
import { Transition } from 'react-transition-group'
import { LoadingOutlined as LoadingOutlinedANTD } from '@ant-design/icons'

const LoadingOutlined = styled(LoadingOutlinedANTD)`
  position: absolute;
  top: 10%;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  font-size: 50px;
  color: ${({ theme }) => theme.colors.loaderSecondary} !important;
  opacity: ${({ state }) => (state === 'entered' ? '1' : '0')} !important;
  transition: 0.5s;
`

const LoadingMask = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 100;
  transition: 0.5s;
  background-color: transparent;
  display: ${({ state }) => (state === 'exited' ? 'none' : 'block')};
`

const LoadingBlockContainer = styled.div`
  padding: 220px 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    position: relative;
  }
`

export const LoadingBlock = () => {
  return (
    <LoadingBlockContainer>
      <LoadingOutlined state={'entered'} />
    </LoadingBlockContainer>
  )
}

export const LoadingOverlay = ({ visible }) => {
  return (
    <Transition in={visible} timeout={500}>
      {(state) => (
        <LoadingMask state={state}>
          <LoadingOutlined state={state} />
        </LoadingMask>
      )}
    </Transition>
  )
}

export default LoadingOverlay

import React, { useEffect } from 'react'
import styled, { useTheme } from 'styled-components'
import { Row, Col, Alert } from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons'
import Button from 'components/ui/button'

import { useDispatch, useSelector } from 'react-redux'
import { hideGlobalAlert } from 'store/slices/app'
import { formatServiceProviderName } from 'utils/formatter'

import { setAccountLogo, setReturnUrl, setServiceProviderBackButton, setShowAccountLogoOnTop } from 'store/slices/account'
import { updateCommonTheme, initBackgroundColor, initBorderColor, initBorderTextColor, initAccountLogo, initBackgroundGradient } from 'store/slices/theme'
import { useGetAccountPreferences } from 'hooks/api'
import _ from 'lodash'
import { copyrightText, fallbackReturnUrl } from 'utils/constants'

const Page = styled.div`
  display: flex;
  flex-direction: column;
  height: ${({ staticPageHeight }) => staticPageHeight ? 'auto' : '100%'};
  margin: 10px 0px;
`

const ContentWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.borderColor};
  -webkit-transition: background-color 3000ms ease;
  -moz-transition: background-color 3000ms ease;
  -o-transition: background-color 3000ms ease;
  -ms-transition: background-color 3000ms ease;
  transition: background-color 3000ms ease;
  color: ${({ theme }) => theme.colors.borderTextColor};
  height: 100%;
  padding: 0px 2px 0px 2px;
  max-height: auto;
  min-height: 520px;
  overflow: hidden;
  border-radius: 15px;
`

const ContentContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.borderColor};
  -webkit-transition: background-color 3000ms ease;
  -moz-transition: background-color 3000ms ease;
  -o-transition: background-color 3000ms ease;
  -ms-transition: background-color 3000ms ease;
  transition: background-color 3000ms ease;
  color: #000000;
  padding: 15px;
  border-radius: 15px;
  margin: 8px 6px;
  position: relative;
  height: ${({ staticContainerHeight }) => staticContainerHeight ? '630px' : '100%' };
  // max-height: 692px;
  min-height: 520px;
`

const HeadingContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
`

const PageTitle = styled.div`
  text-align: center;
  color: ${({ theme }) => theme.colors.highlightPrimary} !important;
  margin: 0;
  font-size: 1.5rem;
  font-weight: bold;
`

const SubContextContainer = styled.div`
  margin-top: -6px;
  height: 38px;
  line-height: 38px;
  font-size: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 11px;
  color: ${({ theme }) => theme.colors.borderTextColor};
  position: relative;
  z-index: 10;
`

const SubContext = styled.div`
  flex: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  &:first-of-type {
    flex: 1;
    justify-content: flex-start;
  }
  &:last-of-type {
    flex: 1;
    justify-content: flex-end;
  }
`

const ProviderContext = styled.div`
  position: absolute;
  bottom: 5px;
  text-align: center;
  width: 100%;
  color: ${({ theme }) => theme.colors.foregroundSecondary};
`
const BackButtonArrow = styled(ArrowLeftOutlined)`
  cursor: pointer;
  color: ${({theme}) => theme.colors.borderTextColor};
  margin: 10px;
  left: -6px;
  position: relative;
  top: 8px;
  font-size: 14px;
`

const LogoPageWrapper = ({ children, title, subtext, onBack, contained = true, staticContainerHeight = false, staticPageHeight = true }) => {
  const timeInSec = 1000
  const theme = useTheme()
  const dispatch = useDispatch()
  const { sessionData } = useSelector((state) => state.app)
  const account = useSelector((state) => state.account.current)
  const imageUrl = useSelector((state) => state.account.accountLogo)
  const serviceProviderBackButton = useSelector((state) => state.account.serviceProviderBackButton)
  const showAccountLogoOnTop = useSelector((state) => state.account.showAccountLogoOnTop)
  const globalAlerts = useSelector((state) => state.app.globalAlerts)

  const { publicKey } = useSelector((state) => state.wizard)
  const { data: accountPreferences, isFetched: hasFetchedAccountPreferences } = useGetAccountPreferences(publicKey, sessionData?.accountId)

  useEffect(() => {
    const wizardBackgroundColor = sessionStorage.getItem('wizardBackgroundColor')
    const preferences = _.find(accountPreferences?.preferences, (pref) => pref.type === 'CRYPTO')?.preference
    if (hasFetchedAccountPreferences && !_.isEmpty(preferences)) {
      const returnUrl = preferences.returnUrl || fallbackReturnUrl
      dispatch(setReturnUrl(returnUrl))
      preferences.backgroundColor = preferences.backgroundColor !== '' ? preferences.backgroundColor : initBackgroundColor
      preferences.borderColor = preferences.borderColor !== '' ? preferences.borderColor : initBorderColor
      preferences.borderTextColor = preferences.borderTextColor !== '' ? preferences.borderTextColor : initBorderTextColor
      preferences.accountLogoUrl = preferences.accountLogoUrl || initAccountLogo
      preferences.backgroundGradient = initBackgroundGradient

      if (!wizardBackgroundColor) {
        document.body.classList.add('transition')
        dispatch(updateCommonTheme({
          colors: {
            ..._.pick(preferences, ['backgroundColor'])
          }
        }))
      }
      let timer = setTimeout(() => {
        dispatch(setAccountLogo(preferences.accountLogoUrl || '/images/meld-logo.svg'))
        dispatch(updateCommonTheme({
          ..._.pick(preferences, ['accountLogoUrl', 'offrampEnabled', 'onrampEnabled', 'returnUrl', 'buttonColor', 'darkModeButtonColor', 'buttonTextColor']),
        }))
        dispatch(setServiceProviderBackButton(preferences.hideBackButton))
        dispatch(setShowAccountLogoOnTop(preferences.showAccountLogoOnTop))
        clearTimeout(timer)
      }, timeInSec)
    }
  }, [hasFetchedAccountPreferences])

  useEffect(() => {
    if (sessionData?.sessionData) {
      const { countryCode, sourceAmount, sourceCurrencyCode, destinationCurrencyCode, walletAddress, walletTag, paymentMethodTypes, sessionType, paymentMethodType } = sessionData.sessionData
      const data = {
        countryCode,
        sourceAmount,
        sourceCurrencyCode,
        destinationCurrencyCode,
        walletAddress,
        walletTag,
        paymentMethodType: paymentMethodType?.toString() || paymentMethodTypes?.toString(),
        transactionType: sessionType
      }
      sessionStorage.setItem('wizardSessionData', JSON.stringify(data))
    }
  }, [sessionData])

  const handleHideAlert = (globalAlert) => {
    dispatch(hideGlobalAlert(globalAlert))
  }

  const goBack = () => {
    sessionStorage.setItem('wizardBackClicked', true)
    const wizardRedirect = sessionStorage.getItem('wizardRedirect')
    const wizardRedirectSearchParam = sessionStorage.getItem('wizardRedirectSearchParam')
    if (wizardRedirect) {
      const urlToRedirect = `${window.location.origin}${wizardRedirectSearchParam ? wizardRedirectSearchParam : ''}`
      window.location.href = urlToRedirect
    } else {
      history.back()
    }
  }

  return (
    <Page staticPageHeight={staticPageHeight}>
      <ContentWrapper>
        <Row>
          <Col span={24}>
            <SubContextContainer>
              <SubContext>
                {(imageUrl !== '' && onBack && !serviceProviderBackButton) && <Button icon={<ArrowLeftOutlined />} type="link" onClick={onBack} />}
                {(imageUrl !== '' && !onBack && !serviceProviderBackButton) && <BackButtonArrow onClick={goBack} />}
              </SubContext>
              <SubContext style={{ marginTop: '14px' }}>
                {
                  showAccountLogoOnTop && imageUrl !== '' ?
                    <img src={imageUrl} alt="Meld Logo" height="24"/>
                  : <></>
                }
              </SubContext>
              <SubContext></SubContext>
            </SubContextContainer>
          </Col>
        </Row>
        <ContentContainer contained={contained} staticContainerHeight={staticContainerHeight}>
          {title && (
            <HeadingContainer>
              <PageTitle>{title}</PageTitle>
            </HeadingContainer>
          )}

          {globalAlerts.map((globalAlert, i) => (
            <div key={i}>
              <Alert
                closable={!globalAlert.persist}
                onClose={() => handleHideAlert(globalAlert)}
                key={globalAlert.id}
                style={{ margin: '10px 0', textAlign: 'center' }}
                message={(
                  <div style={{ color: theme.colors.warning, fontSize: '0.9rem' }}>
                    {globalAlert.content}
                    {globalAlert &&
                      <div style={{ textAlign: 'right', fontSize: '0.7rem', color: theme.colors.warning }}>{globalAlert.code}</div>}
                  </div>
                )}
                type={globalAlert.type}
              />
            </div>
          ))}
          {children}
          {sessionData?.sessionData?.serviceProvider === 'WYRE' && account?.styling?.global && (
            <ProviderContext>Transactions handled by {formatServiceProviderName(sessionData?.sessionData?.serviceProvider)}</ProviderContext>
          )}
        </ContentContainer>
        <SubContextContainer>
          <SubContext></SubContext>
          <SubContext>
            {
              imageUrl !== '' &&
              <span>{copyrightText}&nbsp;<b>Meld.io{account.styling.global.footerPostscript}</b></span>
            }
          </SubContext>
          <SubContext>
            {
              !showAccountLogoOnTop && imageUrl !== '' ?
                <img src={imageUrl} alt="Meld Logo" height="24"/>
              : <></>
            }
          </SubContext>
        </SubContextContainer>
      </ContentWrapper>
    </Page>
  )
}

export default LogoPageWrapper

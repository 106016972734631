import styled from 'styled-components'
import { Divider as DividerANTD } from 'antd'

export const Divider = styled(DividerANTD)`
  .ant-divider-inner-text {
    padding: 0 1em 0 0;
  }

  &.ant-divider-with-text-left {
    ::before {
      width: 0;
    }
  }
`

export default Divider

const domain = process.env.REACT_APP_POSTMESSAGE_DOMAIN || 'meld-connect'

const postMessage = (target, message, origin) => {
  if (!message || !origin) {
    throw new Error('Missing message or origin in postMessage')
  }
  target.postMessage(message, origin)
}

export const emit = (eventName, metadata = {}, origin = '*') => {
  const _log = ['Emitting']
  let paramString = ''

  _log.push(`eventName [${eventName}]`)

  // check and parse parameters
  if (metadata && metadata instanceof Object && JSON.stringify(metadata) !== '{}') {
    paramString = '?' + JSON.stringify(metadata)
    _log.push(`paramString ${paramString}`)
  } else {
    _log.push(`no metadata`)
  }

  // check and emit to top
  try {
    const payload = { domain, eventName, metadata }

    if (window?.top) {
      postMessage(window.top, payload, origin)
      _log.push('posted to top')
    }

    if (window?.opener) {
      postMessage(window.opener, payload, origin)
      _log.push('posted to opener')
    }

    if (typeof window?.Android?.postMessage === 'function') {
      window.Android.postMessage(`[${domain}]${eventName}${paramString}`)
      _log.push('posted to Android')
    }

    if (typeof window?.webkit?.messageHandlers?.meldMessageHandler?.postMessage === 'function') {
      window.webkit.messageHandlers.meldMessageHandler.postMessage(`[${domain}]${eventName}${paramString}`)
      _log.push('posted to iOS')
    }

    if (typeof window?.ReactNativeWebView?.postMessage === 'function') {
      window.ReactNativeWebView.postMessage(payload)
      _log.push('posted to React Native')
    }
  } catch (e) {
    console.error('Unable to emit', e.message, _log)
  } finally {
    console.log(_log.join(' | '))
  }
}

export default emit

export const filterQuotes = ( sortedQuotes, topServiceProviders ) => {
  if (!sortedQuotes) {
    return []
  }
  sortedQuotes.sort((a, b) => {
    const aServiceProvider = a.serviceProvider
    const bServiceProvider = b.serviceProvider

    const aIsTopProvider = topServiceProviders.includes(aServiceProvider)
    const bIsTopProvider = topServiceProviders.includes(bServiceProvider)

    if (aIsTopProvider && !bIsTopProvider) {
      return -1 // a comes before b
    } else if (!aIsTopProvider && bIsTopProvider) {
      return 1 // b comes before a
    } else {
      return 0 // maintain the order
    }
  })

  const topQuoteProviders = sortedQuotes.filter((quote) => topServiceProviders.includes(quote.serviceProvider))
  const otherProviders = sortedQuotes.filter((quote) => !topServiceProviders.includes(quote.serviceProvider))
  topQuoteProviders.sort(function(a, b){
    return topServiceProviders.indexOf(a.serviceProvider) - topServiceProviders.indexOf(b.serviceProvider)
  })
  const updatedQuotes = topQuoteProviders.concat(otherProviders)
  return updatedQuotes
}

export default filterQuotes

export const cleanCreditCardInput = (input) => input.replaceAll('-', '')

export const getURLParams = (url) => {
  const params = new URLSearchParams(url || window.location.search)
  const result = {}
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of params) {
    result[key] = value
  }
  return result
}

export const objectToParams = (params) => Object.entries(params).map(([key, val]) => `${key}=${encodeURIComponent(String(val))}`).join('&')

/* eslint-disable no-unused-vars */
/* eslint-disable complexity */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled, { useTheme } from 'styled-components'
import Button from 'components/ui/button'
import { Skeleton } from 'antd'
import { useGetWidgetToken, usePostSessionData } from 'hooks/api'
import { setToken, setWizardSessionDirectUrl, setWizardSessionServiceProvider, setWizardSessionToken } from 'store/slices/app'
import jwt from 'jwt-decode'
import moment from 'moment'
import { isMobileScreen, redirectServiceProvider } from 'utils/constants'
import { setCurrentStep } from 'store/slices/wizard'
import mixPanel from './services/mixpanel'

const Wrapper = styled.div`
  /* position: ${({ position }) => position ? '' : 'absolute'};
  margin-top: ${({ position }) => position ? '-15px' : '0px'}; */
  width: 100%;
  /* left: 0;
  bottom: 0; */
  padding: ${({ isMobileScreen }) => isMobileScreen ? '0px' : '0px 15px'};
  position: absolute;
  bottom: 2%;
  right: 0%;
`

const Container = styled.div`
  border-radius: 8px;
  /* padding: 14px; */
  /* border: 1px solid ${({ border }) => border}; */
  background-color: ${({ theme }) => theme.colors.quoteCardBackground};
`

const ContinueButton = styled(Button)`
  width: 100%;
  font-size: 18px;
  transition: transform .4s;
  &:hover {
    transform: scale(${({ transform }) => transform});
    transform-origin: 50% 50%;
  }

  span {
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &.ant-btn-primary {
    :disabled {
      color: ${({ theme }) => theme.colors.buttonDisabledColor} !important;
      background: ${({ theme }) => theme.colors.buttonDisabled} !important;
    }
    color: ${({ theme }) => theme.buttonTextColor} !important;
    background: ${({ theme }) => theme.buttonColor} !important;
  }
`

const SourceSkeleton = styled(Skeleton)`
  .ant-skeleton-content {
    display: flex;
    align-items: center;
  }
  .ant-skeleton-title {
    width: 100% !important;
  }
`

const WizardContinueWrapper = ({ serviceProviderDetails, onModalError, onModalTitle, onModalBody, isSourceAmountValid, resetWalletAddressEntered }) => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const wizardBackgroundColor = useSelector((state) => state.theme.themeBackgroundColor)
  const { token } = useSelector((state) => state.app)
  const {
    selectedDestination, selectedSource, publicKey, urlParams, buySellFlow, countryCode, sourceAmount, sourceCurrencyCode, destinationCurrencyCode,
    walletTag, paymentMethod, walletAddress, isSourceAmountError, isWalletAddressError, destinationCurrencyAmount, selectedQuotes, accountPublicPreference,
    accountOnRampServiceProviders, accountOffRampServiceProviders, accountCryptoLimit, accountCountry, accountFiatCurrency, accountCryptoCurrency, accountPaymentMethod,
    lockCountryCode
  } = useSelector((state) => state.wizard)
  const customerId = urlParams?.customerId
  const externalCustomerId = urlParams?.externalCustomerId
  const externalSessionId = urlParams?.externalSessionId
  const redirectUrl = urlParams?.redirectUrl

  let tokenExpiration = 0
  try {
    // JWT expresses expiration in Epoch seconds; moment uses Epoch milliseconds
    tokenExpiration = jwt(token)?.exp * 1000
  } catch (e) {
    // do nothing with the error; we don't care
  }
  const { mutateAsync: postSessionData } = usePostSessionData(publicKey)
  const [ isButtonDisabled, setIsButtonDisabled ] = useState(true)

  useEffect(() => {
    if (buySellFlow === 'BUY') {
      if (destinationCurrencyAmount > 0 && walletAddress && !isWalletAddressError && !isSourceAmountError && isSourceAmountValid) {
        setIsButtonDisabled(false)
      } else {
        setIsButtonDisabled(true)
      }
    }
    if (buySellFlow === 'SELL') {
      if (destinationCurrencyAmount > 0 && !isWalletAddressError && !isSourceAmountError && isSourceAmountValid) {
        setIsButtonDisabled(false)
      } else {
        setIsButtonDisabled(true)
      }
    }
  }, [destinationCurrencyAmount, walletAddress, isWalletAddressError, isSourceAmountError, isSourceAmountValid])

  const handleSubmit = async () => {
    resetWalletAddressEntered(false)
    sessionStorage.removeItem('wizardAccountSupportedPreferences')
    sessionStorage.removeItem('wizardRedirect')
    sessionStorage.removeItem('wizardAccountSupportedData')
    mixPanel({
      eventName: buySellFlow === 'BUY' ? 'buy_wizard_continue' : 'sell_wizard_continue',
      buySellFlow: buySellFlow,
      cryptoCurrencyCode: buySellFlow === 'BUY' ? destinationCurrencyCode : sourceCurrencyCode,
      payload: {
        country: countryCode,
        sourceCurrencyCode: sourceCurrencyCode,
        destinationCurrencyCode: destinationCurrencyCode,
        sourceAmount: sourceAmount,
        paymentMethodType: paymentMethod.paymentMethod,
        serviceProvider: selectedQuotes?.[0]?.serviceProvider,
      }
    })
    setIsButtonDisabled(true)
    dispatch(setWizardSessionDirectUrl(''))
    const lockFields = []
    if (urlParams?.walletAddressLocked) {
      lockFields.push('walletAddress')
    }
    if (buySellFlow === 'BUY' && destinationCurrencyCode === urlParams?.destinationCurrencyCodeLocked) {
      lockFields.push('destinationCurrencyCode')
    }
    if (buySellFlow === 'SELL' && sourceCurrencyCode === urlParams?.sourceCurrencyCodeLocked) {
      lockFields.push('sourceCurrencyCode')
    }

    const data = {
      sessionData: {
        countryCode,
        sourceCurrencyCode,
        destinationCurrencyCode,
        sourceAmount,
        walletAddress: walletAddress,
        paymentMethodType: paymentMethod.paymentMethod,
        serviceProvider: selectedQuotes?.[0]?.serviceProvider,
        walletTag,
      },
      sessionType: buySellFlow,
    }
    if (customerId) {
      data.customerId = customerId
    }
    if (externalCustomerId) {
      data.externalCustomerId = externalCustomerId
    }
    if (externalSessionId) {
      data.externalSessionId = externalSessionId
    }
    if (redirectUrl) {
      data.sessionData.redirectUrl = redirectUrl
    }
    if (lockFields.length > 0) {
      data.sessionData.lockFields = lockFields
    }
    history.pushState({ paymentMethodType: paymentMethod.subtype, transactionType: buySellFlow, ...data.sessionData }, 'Wizard')
    const sessionData = await postSessionData(data)
    if (sessionData?.code) {
      let titleMessage = `${buySellFlow === 'BUY' ? 'Onramp' : 'Offramp'} currently unavailable`
      let bodyMessage = `Please try a different combination or ${buySellFlow === 'BUY' ? 'onramp' : 'offramp'}`
      if (sessionData?.response?.data?.code === 'CRYPTO_INVALID_DESTINATION_WALLET') {
        titleMessage = 'Invalid wallet address'
        // bodyMessage = sessionData?.response?.data?.message
        bodyMessage = `Wallet address is invalid for ${selectedDestination?.name}`
      }
      onModalTitle(titleMessage)
      onModalBody(bodyMessage)
      onModalError(true)
      setIsButtonDisabled(false)
      return
    }
    if ((redirectServiceProvider.includes(selectedQuotes?.[0]?.serviceProvider) || paymentMethod.paymentMethod === 'APPLE_PAY') && sessionData?.token) {
      if (selectedQuotes?.[0]?.serviceProvider === 'ROBINHOOD') {
        dispatch(setWizardSessionDirectUrl(sessionData?.widgetUrl))
      }
      dispatch(setWizardSessionToken(sessionData?.token))
      const serviceProviderName = serviceProviderDetails?.find((serviceProvider) => serviceProvider?.serviceProvider === selectedQuotes?.[0]?.serviceProvider)?.name
      dispatch(setWizardSessionServiceProvider(serviceProviderName || selectedQuotes?.[0]?.serviceProvider))
      return dispatch(setCurrentStep('wizardRedirect'))
    } else {
      sessionStorage.clear()
      sessionStorage.setItem('wizardBackgroundColor', wizardBackgroundColor)
      sessionStorage.setItem('wizardRedirect', true)
      sessionStorage.setItem('wizardRedirectSearchParam', window?.location?.search || '')
      const accountSupportedPreference = {
        accountPublicPreference,
        accountOnRampServiceProviders,
        accountOffRampServiceProviders,
        lockCountryCode: lockCountryCode
      }
      sessionStorage.setItem('wizardAccountSupportedPreferences', JSON.stringify(accountSupportedPreference))
      const accountSupportedData = {
        accountCountry,
        accountCryptoLimit,
        accountFiatCurrency,
        accountCryptoCurrency,
        accountPaymentMethod,
      }
      sessionStorage.setItem('wizardAccountSupportedData', JSON.stringify(accountSupportedData))
      window.location.href = `${window.location.origin}?token=${sessionData?.token}`
    }
  }

  return (
    <Wrapper isMobileScreen={isMobileScreen}>
      <Container border={theme.name === 'darkMode' ? '#8569B3' : '#65A4C1'}>
        <ContinueButton
          transform={isButtonDisabled ? '1' : '1.03'}
          disabled={isButtonDisabled}
          type="primary"
          onClick={(e) => handleSubmit()}
          className={publicKey + ' ' + 'stage'}
        >
          {buySellFlow === 'BUY' ? 'Buy' : 'Sell'} {buySellFlow === 'BUY' ? selectedDestination?.name : selectedSource?.name}
        </ContinueButton>
      </Container>
    </Wrapper>
  )
}

export default WizardContinueWrapper

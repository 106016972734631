import { cleanCreditCardInput } from 'utils/data-model'

export const constructPaymentData = (quoteData, customerForm, sessionData, formValues) => {
  return {
    sourceAmount: quoteData.sourceAmount,
    sourceCurrencyCode: quoteData.sourceCurrencyCode,
    customer: {
      email: customerForm.email,
      phone: `${customerForm.phoneExt}${customerForm.phoneNumber.replace(/[^0-9]/g, '')}`,
      name: {
        firstName: customerForm.firstName,
        lastName: customerForm.lastName,
      },
      addresses: [{
        addressDetails: {
          lineOne: customerForm.lineOne,
          lineTwo: customerForm.lineTwo,
          city: customerForm.city,
          state: customerForm.state,
          zipCode: customerForm.zipCode,
          country: customerForm.countryCode,
        },
        type: 'BILLING'
      }],
    },
    serviceProviderDetails: {
      destinationCurrencyCode: quoteData.destinationCurrencyCode,
      walletAddress: sessionData.sessionData.walletAddress,
      walletTag: sessionData.sessionData.walletTag,
    },
    paymentMethod: {
      type: 'CARD',
      details: {
        number: cleanCreditCardInput(formValues.creditCardNumber),
        expiration: formValues.creditCardExpiration,
        cvc: formValues.creditCardCVC,
      },
    },
    serviceProvider: 'WYRE',
  }
}

export const constructKYCCustomerData = (formValues) => {
  const {
    countryCode, state, lineOne, lineTwo, zipCode, city, firstName, lastName, email, phoneNumber, phoneExt,
  } = formValues
  return {
    serviceProvider: 'WYRE',
    name: {
      firstName,
      lastName,
    },
    email,
    phone: `${phoneExt}${phoneNumber.replace(/[^0-9]/g, '')}`,
    addresses: [{
      addressDetails: {
        lineOne,
        lineTwo,
        city,
        state,
        zipCode,
        countryCode,
      },
      type: 'RESIDENCE'
    }],
  }
}

export const constructLimitData = (customerData, quoteData) => {
  const {
    countryCode, state, lineOne, lineTwo, zipCode, city, firstName, lastName, phoneNumber, phoneExt,
  } = customerData
  return {
    phone: `${phoneExt}${phoneNumber.replace(/[^0-9]/g, '')}`,
    firstName,
    lastName,
    billingAddress: {
      addressDetails: {
        lineOne,
        lineTwo,
        city,
        state,
        zipCode,
        countryCode,
      },
      type: 'BILLING'
    },
    sourceCurrency: quoteData.sourceCurrencyCode,
    serviceProvider: 'WYRE',
  }
}

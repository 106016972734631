import React from 'react'
import { Provider as ReduxProvider, useSelector } from 'react-redux'
import { store } from 'store/store'
import { selectTheme } from 'store/slices/theme'
import { ThemeProvider } from 'styled-components'

import SiteLayout from 'components/core/layout'
import Router from 'components/core/router'

import { QueryClientProvider, QueryClient } from 'react-query'

import 'antd/dist/antd.css'
import 'antd-country-phone-input/dist/index.css'

const ThemeWrapper = ({ children }) => {
  const theme = useSelector(selectTheme)
  return theme ? <ThemeProvider theme={theme}>{children}</ThemeProvider> : null
}

const App = () => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        refetchOnmount: false,
        refetchOnReconnect: false,
        retry: false,
        staleTime: 0,
        cacheTime: 0,
      },
    },
  })

  if (window.location?.hash === '#iframetest') {
    return <div style={{background: '#eee', display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh'}}>
      <div style={{width: 400, height: 600}}>
        <iframe title='self' style={{borderRadius: 12, border: 'none', height: 600, width: 400}} src={window.location.href.split('#')[0]} />
      </div>
    </div>
  }

  return (
    <ReduxProvider store={store}>
      <ThemeWrapper>
        <QueryClientProvider client={queryClient}>
          <SiteLayout>
            <Router />
          </SiteLayout>
        </QueryClientProvider>
      </ThemeWrapper>
    </ReduxProvider>
  )
}

export default App

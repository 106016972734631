import React from 'react'
import { useTheme } from 'styled-components'
import { LoadingOutlined } from '@ant-design/icons'

const Loader = ({ children }) => {
  const theme = useTheme()
  return (
    <div style={{margin: '20px auto', textAlign: 'center', fontSize: 100, color: theme.colors.loaderSecondary}}>
      <LoadingOutlined />
      <div>{ children }</div>
    </div>
  )
}

export default Loader

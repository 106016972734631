import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useGetQuotes } from 'hooks/api'

import { Skeleton } from 'antd'
import Button from 'components/ui/button'
import Currency from 'components/ui/currency'
import LogoPageWrapper from 'components/ui/logo-page-wrapper'
import { WalletAddressText, WalletAddressContainer, ResultItem, ButtonContainer, ResultItemTitle } from 'components/ui/styled'

import { setQuotesData } from 'store/slices/wyre'
import { setDisplayPage } from 'store/slices/app'

import { CAPTURE_PAYMENT_DETAILS, CAPTURE_BILLING_DETAILS, CRYPTO_QUOTE } from '../pages'
import { isSafari, walletTagCurrencies } from 'utils/constants'
import { GAevent } from 'utils/analytics'
import { loadScript } from 'utils/dom'

const CryptoQuoteResult = () => {
  const dispatch = useDispatch()
  const sessionData = useSelector((state) => state.app.sessionData)
  const quoteData = useSelector((state) => state.wyre.quotesData?.[0] || {})
  const { isFetching: isFetchingQuotes, data: quotes } = useGetQuotes({
    sourceAmount: sessionData.sessionData.sourceAmount,
    sourceCurrencyCode: sessionData.sessionData.sourceCurrencyCode,
    countryCode: sessionData.sessionData.countryCode,
    destinationCurrencyCode: sessionData.sessionData.destinationCurrencyCode,
    paymentMethodType: sessionData.sessionData.paymentMethodType,
    serviceProviders: ['WYRE'],
  })

  const onBack = () => {
    dispatch(setDisplayPage(CRYPTO_QUOTE))
  }

  const onPay = () => {
    if (isSafari && ['MOBILE_WALLET', 'APPLE_PAY'].includes(sessionData.sessionData.paymentMethodType)) {
      dispatch(setDisplayPage(CAPTURE_PAYMENT_DETAILS))
    } else {
      dispatch(setDisplayPage(CAPTURE_BILLING_DETAILS))
    }
  }

  useEffect(() => {
    GAevent('order_review', { payment_provider: 'WYRE' })
  }, [])

  useEffect(() => {
    if (quotes) {
      dispatch(setQuotesData(quotes?.quotes))
    }
  }, [quotes])

  useEffect(async () => {
    if(sessionData.sessionData.paymentMethodType === 'APPLE_PAY') {
      await loadScript('applepay', 'https://applepay.cdn-apple.com/jsapi/v1/apple-pay-sdk.js')
    }
  }, [sessionData.sessionData.sourceAmount])

  return (
    <LogoPageWrapper title={isFetchingQuotes ? 'Fetching quote...' : <div>Review your purchase of <br /> {quoteData.destinationAmount} {quoteData.destinationCurrencyCode}</div>}>
      <Skeleton active paragraph={{ rows: 8 }} loading={isFetchingQuotes}>
        <ResultItem>
          <ResultItemTitle>Exchange Rate</ResultItemTitle>
          <div>
            <Currency currencyCode={quoteData.sourceCurrencyCode}>{quoteData.exchangeRate}</Currency>
            &nbsp;/&nbsp;{quoteData.destinationCurrencyCode}
          </div>
        </ResultItem>
        <ResultItem>
          <ResultItemTitle>Purchase</ResultItemTitle>
          <div>
            <Currency currencyCode={quoteData.sourceCurrencyCode || 'USD'}>{quoteData.sourceAmountWithoutFees}</Currency>
          </div>
        </ResultItem>
        <ResultItem>
          <ResultItemTitle>Fees</ResultItemTitle>
          <div>
            <Currency currencyCode={quoteData.sourceCurrencyCode || 'USD'}>{quoteData.totalFee}</Currency>
          </div>
        </ResultItem>
        <ResultItem>
          <ResultItemTitle>Total Cost</ResultItemTitle>
          <div>
            <Currency currencyCode={quoteData.sourceCurrencyCode || 'USD'}>{quoteData.sourceAmount}</Currency>
          </div>
        </ResultItem>
        <WalletAddressContainer>
          <label>Wallet Address</label>
          <WalletAddressText>{sessionData.sessionData.walletAddress}</WalletAddressText>
        </WalletAddressContainer>
        {(walletTagCurrencies?.includes(sessionData.sessionData.destinationCurrencyCode) && sessionData.sessionData.walletTag) && (
          <WalletAddressContainer>
            <label>Wallet Tag</label>
            <WalletAddressText>{sessionData.sessionData.walletTag}</WalletAddressText>
          </WalletAddressContainer>
        )}
        <ButtonContainer>
          <Button onClick={onPay} size="large" type="primary" style={{ width: '100%' }} disabled={isFetchingQuotes}>
            Enter Billing Details
          </Button>
          <Button onClick={onBack} size="large" type="default" style={{ width: '100%' }} disabled={isFetchingQuotes}>
            Edit Order Details
          </Button>
        </ButtonContainer>
      </Skeleton>
    </LogoPageWrapper>
  )
}

export default CryptoQuoteResult

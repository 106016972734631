import React from 'react'
import styled from 'styled-components'

import { useSelector, useDispatch } from 'react-redux'
import { setBuySellFlow, setSourceCurrencyCode, setDestinationCurrencyCode, setCountryCode, setSourceAmount, setPaymentMethod } from 'store/slices/wizard'
import mixPanel from '../services/mixpanel'
// import { isMobileScreen } from 'utils/constants'

// const Container = styled.div`
//   display: flex;
//   flex-direction: row;
//   justify-content: center;
//   position: relative;
//   width: 100px;
//   min-width: 100px;
//   max-width: 100px;
//   align-items: center;
// `

// const HiglightIndicator = styled.div`
//   position: absolute;
//   top: 0px;
//   left: ${({ left }) => left};
//   transition: left 0.4s cubic-bezier(.5, 0, .5, 1), width 0.4s cubic-bezier(.3, ${({ left }) => left ? -3 : 3}, .5, 1);
//   color: ${({ theme, active }) => theme.colors.tabPrimary};
//   border-bottom: 2px solid ${({ theme, active }) => theme.colors.tabPrimary};
//   height: 2rem;
//   width: ${({ left }) => left ? '25px' : '26px'};
//   z-index: 0;
// `

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
  border: 1px solid ${({ theme }) => theme.colors.toggleBorder};
  border-radius: 8px;
  padding: 2px;
  /* width: 200px; */
`

const Button = styled.button`
  width: 100%;
  color: ${({ theme, active }) => active ? theme.colors.tabforeGroundPrimary : theme.colors.foregroundQuaternary};
  background-color: ${({ theme, active }) => active ? theme.colors.tabPrimary : 'transparent'};
  border: 1px solid ${({ theme, active }) => active ? theme.colors.tabPrimary : 'transparent'};
  height: 2rem;
  transition: all 0.4s ease;
  cursor: pointer;
  font-size: 14px;
  padding: 0 14px;
  border-radius: 6px;
`

export const BuySellToggle = ({ onFlowChange }) => {
  const buySellFlow = useSelector((state) => state.wizard.buySellFlow)
  const { publicKey } = useSelector((state) => state.wizard)
  const dispatch = useDispatch()

  const updateBuySellFlow = (flow) => {
    if (buySellFlow === flow) {
      // Don't blow everything up if we're not changing
      return
    }
    onFlowChange(flow)
    dispatch(setSourceAmount(0))
    dispatch(setSourceCurrencyCode(''))
    dispatch(setDestinationCurrencyCode(''))
    dispatch(setBuySellFlow(flow))
    dispatch(setCountryCode(''))
    dispatch(setPaymentMethod({}))
  }

  return (
    <Container>
      {/* <HiglightIndicator left={buySellFlow === 'BUY' ? (isMobileScreen ? '2px' : '12px') : (isMobileScreen ? '73px' : '63px') } /> */}
      <Button active={buySellFlow === 'BUY'} onClick={() => {
        updateBuySellFlow('BUY')
        mixPanel({
          eventName: 'buy_button_click',
          buySellFlow: 'BUY'
        })
      }} className={publicKey + ' BUY'}>Buy</Button>
      <Button active={buySellFlow === 'SELL'} onClick={() => {
        updateBuySellFlow('SELL')
        mixPanel({
          eventName: 'sell_button_click',
          buySellFlow: 'SELL'
        })
      }} className={publicKey + ' SELL'}>Sell</Button>
    </Container>
  )
}

export default BuySellToggle

import React, { useEffect } from 'react'
import LogoPageWrapper from 'components/ui/logo-page-wrapper'
import { useSelector } from 'react-redux'
import { loadScript } from 'utils/dom'

const XanpoolProvider = () => {
  const sessionData = useSelector((state) => state.app.sessionData)
  useEffect(() => {

    let init = async () => {
      await loadScript('xanpool', `https://${sessionData.sessionData.serviceProviderEnvironment === 'SANDBOX' ? 'cdn.widget.sandbox.xanpool.com' : 'cdn.widget.xanpool.com'}/xanpool-sdk-v1.js`)
      const widget = new window.XanPoolWidget(
        {
          apiKey: sessionData.sessionData.serviceProviderPublicApiKey,
          wallet: sessionData.sessionData.walletAddress,
          currency: sessionData.sessionData.sourceCurrencyCode,
          cryptoCurrency: sessionData.sessionData.serviceProviderDestinationCurrencyCode,
          chain: sessionData.sessionData.cryptoChain,
          fiat: sessionData.sessionData.sourceAmount,
          partnerData: sessionData.id,
          transactionType: 'buy',
          method: sessionData.sessionData.paymentMethodTypes?.length === 1 ? sessionData.sessionData.paymentMethodTypes?.[0] : null,
          destinationTag: sessionData.sessionData.walletTag
        },
        document.getElementById('xanpoolContainer'),
        (message) => {
          console.log('Received message', message.type)
          console.log('Message payload', message.payload)
        }
      )
      widget.init()

    }
    init()
  }, [])

  return (
    <LogoPageWrapper subtext="">
      <div id="xanpoolContainer" />
    </LogoPageWrapper>
  )
}

export default XanpoolProvider

import { configureStore } from '@reduxjs/toolkit'
import appReducer from './slices/app'
import commonReducer from './slices/common'
import paymentProcessReducer from './slices/payment-process-slice'

import accountReducer from './slices/account'
import serviceProviderReducer from './slices/serviceProvider'
import wyreReducer from './slices/wyre'
import simplexReducer from './slices/simplex'
import btcdirectReducer from './slices/btcdirect'
import wizardReducer from './slices/wizard'
import themeReducer from './slices/theme'
import preferencesReducer from './slices/preferences'

export const store = configureStore({
  reducer: {
    app: appReducer,
    common: commonReducer,
    paymentProcess: paymentProcessReducer,
    account: accountReducer,
    serviceProvider: serviceProviderReducer,
    wyre: wyreReducer,
    simplex: simplexReducer,
    btcdirect: btcdirectReducer,
    wizard: wizardReducer,
    theme: themeReducer,
    preferences: preferencesReducer
  },
})

import React from 'react'
import { CreditCardOutlined, BankFilled, AppleFilled, GoogleOutlined, WalletOutlined, EuroCircleOutlined } from '@ant-design/icons'
import { turkeyCurrencySymbol } from 'utils/constants'

export const getPaymentMethodIcon = (paymentMethod) => {
  if (paymentMethod.paymentType === 'CARD') {
    return <CreditCardOutlined />
  } else if (paymentMethod.paymentType === 'BANK_TRANSFER' || paymentMethod.paymentType === 'ACH' || paymentMethod.paymentType === 'LOCAL') {
    return <BankFilled />
  } else if (paymentMethod.paymentMethod === 'APPLE_PAY') {
    return <AppleFilled />
  } else if (paymentMethod.paymentMethod === 'GOOGLE_PAY') {
    return <GoogleOutlined />
  } else if (paymentMethod.paymentType === 'SEPA') {
    return <EuroCircleOutlined />
  } else {
    return <WalletOutlined />
  }
}

const getSymbol = (currency, locale) => {
  let symbol = (0).toLocaleString(locale, { style: 'currency', currency, minimumFractionDigits: 0, maximumFractionDigits: 0 }).replace(/\d/g, '').trim()
  if (currency === 'TRY') {
    symbol = symbol.replace(currency, turkeyCurrencySymbol)
  }
  return symbol
}

export const getCurrencySymbol = (currency, locale = 'en-US') => {
  try {
    const symbol = getSymbol(currency, locale)
    if (symbol.length > 1) {
      return ''
    }
    return symbol
  } catch(err) {
    return currency
  }
}

export const setGoogleAnalyticsState = (url, title, buySell, publicKey) => {
  const storeUrl = window.location.href + publicKey + '/' + buySell + '/' + url
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    'event': 'virtualPageview',
    'pageUrl': storeUrl,
    'pageTitle': title
  })
}

export const objToString = (object) => {
  let string = ''
  if (object == null) {
    return string
  }
  for (const key in object) {
    if (string !== '') {
      string += ', '
    }
    string += key + '=' + object[key]
  }
  return string
}

import styled from 'styled-components'
import { Button as ButtonANTD } from 'antd'

export const Button = styled(ButtonANTD)`
  height: 56px;
  padding: 10px 40px;
  border-radius: 6px;

  &.ant-btn-primary {
    // background: ${({ theme }) => theme.name === 'darkMode' ? theme.darkModeButtonColor : theme.buttonColor } ${({ theme }) => (theme.darkModeButtonColor || theme.buttonColor) && `!important`};
    // background: linear-gradient(112.11deg, ${({ theme }) => theme.colors.filled} -18.69%, ${({ theme }) => theme.colors.highlightPrimary} 102.07%) ${({ theme }) => (!theme.darkModeButtonColor || !theme.buttonColor) && `!important`};
    background: ${({ theme }) => theme.name === 'darkMode' ? ((theme.darkModeButtonColor === '#zzzzzz' || !theme.darkModeButtonColor) ? theme.colors.filled : theme.darkModeButtonColor) : ((theme.buttonColor === '#zzzzzz' ||  !theme.buttonColor) ? theme.colors.filled : theme.buttonColor) } !important;
    border-width: 0px;
    min-width: 120px;

    :disabled {
      background-color: ${({ theme }) => theme.colors.disabled} !important;
      border-color: ${({ theme}) => theme.colors.disabled} !important;
      color: ${({ theme}) => theme.colors.foregroundPrimary} !important;
    }
  }

  &.ant-btn-ghost, &.ant-btn-ghostActive {
    height: 22px;
    line-height: 22px;
    padding: 0px 10px;

    &.ant-btn-ghost {
      color: ${({ theme }) => theme.colors.foregroundPrimary};
      border-color: ${({ theme }) => theme.colors.foregroundPrimary};
      background: transparent;

      :disabled {
        color: ${({ theme }) => theme.colors.disabledLight} !important;
        background-color: ${({ theme }) => theme.colors.disabled} !important;
        border-color: ${({ theme }) => theme.colors.disabled} !important;
      }
    }

    &.ant-btn-ghostActive {
      color: ${({ theme }) => theme.colors.backgroundPrimary};
      border-color: transparent;
      background-color: ${({ theme }) => theme.colors.foregroundPrimary};
      cursor: default;

      :hover {
        background-color: ${({ theme }) => theme.colors.foregroundPrimary} !important;
        border-color: transparent !important;
        color: ${({ theme }) => theme.colors.backgroundPrimary} !important;
      }
    }
  }

  :hover {
    border-color: ${({ theme }) => theme.name === 'darkMode' ? theme.darkModeButtonColor : theme.buttonColor || theme.colors.highlightPrimary} !important;
  }

  &.ant-btn-lg {
    border-radius: 5px !important;
  }
`

export default Button

import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import styled, { useTheme } from 'styled-components'
import { Skeleton } from 'antd'
import jwt from 'jwt-decode'
import moment from 'moment'
import QuotesNew from './components/quotesNew'

const Wrapper = styled.div`
  /* position: ${({ position }) => position ? '' : 'absolute'};
  margin-top: ${({ position }) => position ? '-15px' : '0px'}; */
  width: 100%;
  /* left: 0;
  bottom: 0; */
`

const Container = styled.div`
  // border-radius: 8px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  /* padding: 14px; */
  /* border: 1px solid ${({ border }) => border}; */
  // background-color: ${({ theme }) => theme.colors.quoteCardBackground};
`

const SourceSkeleton = styled(Skeleton)`
  .ant-skeleton-content {
    display: flex;
    align-items: center;
  }
  .ant-skeleton-title {
    width: 100% !important;
  }
`

const WizardQuoteWrapperNew = ({serviceProviderDetails, isInitialLoad, sourceErrorMsg, topServiceProviderList, onModalError, isWalletAddressEntered, resetWalletAddressEntered, isCountryChange, walletAddress}) => {
  const theme = useTheme()
  const { token } = useSelector((state) => state.app)
  const { sourceAmount, isSourceAmountError } = useSelector((state) => state.wizard)

  let tokenExpiration = 0
  try {
    // JWT expresses expiration in Epoch seconds; moment uses Epoch milliseconds
    tokenExpiration = jwt(token)?.exp * 1000
  } catch (e) {
    // do nothing with the error; we don't care
  }
  const enableSPDetails = moment().isBefore(tokenExpiration) && !isSourceAmountError && sourceAmount > 0
  const [ drawerOpen, setDrawerOpen ] = useState(false)

  return (
    <>
      {
        <Wrapper position={drawerOpen}>
          <Container border={theme.name === 'darkMode' ? '#8569B3' : '#65A4C1'}>
            <SourceSkeleton active loading={false} paragraph={{rows: 0}}>
              <QuotesNew
                enableSPDetails={enableSPDetails}
                isInitialLoad={isInitialLoad}
                serviceProviderDetails={serviceProviderDetails}
                onOpen={(e) => {setDrawerOpen(e)}}
                onClose={(e) => {setDrawerOpen(e)}}
                sourceErrorMsg={sourceErrorMsg}
                topServiceProviderList={topServiceProviderList}
                onModalError={onModalError}
                isWalletAddressEntered={isWalletAddressEntered}
                resetWalletAddressEntered={resetWalletAddressEntered}
                isCountryChange={isCountryChange}
                walletAddress={walletAddress}
              />
            </SourceSkeleton>
          </Container>
        </Wrapper>
      }
    </>
  )
}

export default WizardQuoteWrapperNew

export const filterSourceCurrency = (content, regex, keyword, emptyMsg) => {
  let filteredCurrencies = content?.filter((fiatCurrency) => (
    fiatCurrency?.name?.normalize('NFD')?.replace(regex, '')?.toLowerCase()?.includes(keyword.toLowerCase()) ||
    fiatCurrency?.currencyCode?.toLowerCase().includes(keyword.toLowerCase()) ||
    fiatCurrency?.currencyCode?.toLowerCase() === keyword?.toLowerCase() ||
    fiatCurrency?.name?.toLowerCase().includes(keyword.toLowerCase()) ||
    fiatCurrency?.name?.toLowerCase() === keyword?.toLowerCase()
  ))

  if (!filteredCurrencies.length) {
    filteredCurrencies = filteredCurrencies.concat({ currencyCode: emptyMsg })
  }
  return filteredCurrencies
}

export default filterSourceCurrency

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useGetCryptoLimits, useUpdateCustomer } from 'hooks/api'
import { setCustomerData, setCustomerForm, setLimitData } from 'store/slices/wyre'
import { hideGlobalAlerts, setDisplayPage, showGlobalAlert, updateSessionData } from 'store/slices/app'

import { Col, Form, Input, Select } from 'antd'
import MaskedInput from 'antd-mask-input'
import Button from 'components/ui/button'
import Divider from 'components/ui/divider'
import NumericInput from 'components/ui/numeric-input'
import LogoPageWrapper from 'components/ui/logo-page-wrapper'
import { CAPTURE_PAYMENT_DETAILS, LIMITS_DETAILS, CRYPTO_QUOTE_RESULT } from '../pages'
import { constructKYCCustomerData, constructLimitData } from 'components/providers/wyre/data-helpers'

import { GAevent } from 'utils/analytics'
import { WidgetHeader, WidgetHeaderTitle, BackButton } from 'components/ui/styled'

const CaptureBillingDetails = () => {
  const [form] = Form.useForm()
  const dispatch = useDispatch()

  const [maskedPhone, setMaskedPhone] = useState(true)
  const [isBusyProcessing, setIsBusyProcessing] = useState(false)

  const quotesData = useSelector((state) => state.wyre.quotesData)
  const sessionData = useSelector((state) => state.app.sessionData)
  const { countries, states } = useSelector((state) => state.common)
  const customerForm = useSelector((state) => state.wyre.customerForm)
  const serviceProviderProperties = useSelector((state) => state.serviceProvider.serviceProviderProperties)

  const { mutateAsync: getCryptoLimits } = useGetCryptoLimits()
  const { mutateAsync: updateCustomer } = useUpdateCustomer()

  const handleSubmit = async (_formValues) => {
    let formValues = {
      ..._formValues,
      phoneExt: '+' + countries.find(c => c.iso2 === _formValues.phoneCountryCode).country_code
    }
    try {
      setIsBusyProcessing(true)
      dispatch(hideGlobalAlerts())
      dispatch(setCustomerForm(formValues))

      const limitData = await getCryptoLimits(constructLimitData(formValues, quotesData[0]))
      dispatch(setLimitData(limitData))

      const customerResult = await updateCustomer(constructKYCCustomerData(formValues))
      dispatch(setCustomerData(customerResult))

      if (quotesData[0].sourceAmountWithoutFees > limitData.dailyLimitRemaining) {
        dispatch(setDisplayPage(LIMITS_DETAILS))
      } else {
        dispatch(setDisplayPage(CAPTURE_PAYMENT_DETAILS))
      }
    } catch (error) {
      let errorMessage = 'Please check your State, Zip Code, or Phone Number.'
      if (error.response?.data?.status === 'BAD_REQUEST' && error.response.data.errors?.length) {
        errorMessage = error.response.data.errors.join()
      }
      dispatch(showGlobalAlert({
        type: 'error',
        content: errorMessage,
      }))
      console.error(error.response)
      throw error
    } finally {
      setIsBusyProcessing(false)
    }
  }

  useEffect(() => {
    GAevent('billing_contact_details', { payment_provider: 'WYRE' })
  }, [])

  const rules = {
    STATE: [
      {
        required: sessionData.sessionData.countryCode === 'US',
        message: 'State / Province is required',
      },
    ],
    LINE_ONE: [
      {
        required: true,
        message: 'Address Line 1 is required',
      },
    ],
    ZIP_CODE: [
      {
        required: sessionData.sessionData.countryCode === 'US',
        message: 'Postal / ZIP code is required',
      },
    ],
    CITY: [
      {
        required: true,
        message: 'City is required',
      },
    ],
    FIRST_NAME: [
      {
        required: true,
        message: 'First Name is required',
      },
    ],
    LAST_NAME: [
      {
        required: true,
        message: 'Last Name is required',
      },
    ],
    EMAIL: [
      {
        required: true,
        message: 'Email is required',
      },
      {
        type: 'email',
        message: 'Invalid Email',
      },
    ],
    PHONE: [
      {
        required: true,
        message: 'Mobile Number is required',
      },
    ],
  }

  let initialValues = {
    countryCode: sessionData.sessionData.countryCode,
    phoneCountryCode: sessionData.sessionData.countryCode || customerForm.phoneCountryCode,
    lineOne: customerForm.lineOne,
    lineTwo: customerForm.lineTwo,
    zipCode: customerForm.zipCode,
    city: customerForm.city,
    state: customerForm.state,
    firstName: customerForm.firstName,
    lastName: customerForm.lastName,
    email: customerForm.email,
    phoneNumber: customerForm.phoneNumber,
  }
  const sortedAndFilteredCountries = [...serviceProviderProperties.countries]?.sort((a, b) => a.displayName.localeCompare(b.displayName))

  return (
    <LogoPageWrapper>
      <Form form={form} layout="vertical" initialValues={initialValues} onFinish={handleSubmit}>
        <WidgetHeader>
          <BackButton order={0} onClick={() => dispatch(setDisplayPage(CRYPTO_QUOTE_RESULT))} />
          <Col sm={16}>
            <WidgetHeaderTitle>Billing Address</WidgetHeaderTitle>
          </Col>
          <Col span={3}/>
        </WidgetHeader>
        <Divider style={{ display: 'flex' }} orientation="left">
        </Divider>
        <Input.Group compact>
          <Form.Item name="countryCode" style={{ width: '50%', paddingRight: '10px' }}>
            <Select size="large" showSearch
              filterOption={(input, option) => option?.children?.toLowerCase()?.includes(input?.toLowerCase())}
              onChange={(value) => {
                form.resetFields(['state', 'city', 'zipCode', 'lineOne', 'lineTwo'])
                form.setFieldsValue({phoneCountryCode: value, countryCode: value})
                dispatch(updateSessionData({ ...sessionData, sessionData: { ...sessionData.sessionData, countryCode: value } }))
              }}
            >
              {sortedAndFilteredCountries.map((country) => (
                <Select.Option value={country?.countryCode} key={country?.countryCode}>
                  {country?.displayName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item rules={rules.STATE} name="state" style={{ width: '50%' }}>
            {sessionData.sessionData.countryCode === 'US' ? (
              <Select
                placeholder="State / Province"
                size="large"
                disabled={isBusyProcessing || states.length < 2}
                showSearch
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                {states.map((state) => (
                  <Select.Option key={state.id} value={state.abbreviation}>
                    {state.state}
                  </Select.Option>
                ))}
              </Select>
            ) : (
              <Input placeholder="State / Province" disabled={isBusyProcessing} maxLength={50} size="large" />
            )}
          </Form.Item>
        </Input.Group>
        <Form.Item rules={rules.LINE_ONE} name="lineOne">
          <Input placeholder="Address Line 1" disabled={isBusyProcessing} maxLength={50} size="large" />
        </Form.Item>
        <Form.Item name="lineTwo">
          <Input placeholder="Address Line 2" disabled={isBusyProcessing} maxLength={50} size="large" />
        </Form.Item>
        <Input.Group compact>
          <Form.Item rules={rules.ZIP_CODE} name="zipCode" style={{ width: '50%', paddingRight: '10px' }}>
            {sessionData.sessionData.countryCode === 'US' ? (
              <NumericInput placeholder="Postal / ZIP code" disabled={isBusyProcessing} maxLength={5} pattern="[0-9]*" size="large" />
            ) : (
              <Input placeholder="Postal / ZIP code" disabled={isBusyProcessing} maxLength={10} size="large" />
            )}
          </Form.Item>
          <Form.Item rules={rules.CITY} name="city" style={{ width: '50%' }}>
            <Input placeholder="City" disabled={isBusyProcessing} maxLength={50} size="large" />
          </Form.Item>
        </Input.Group>
        <Divider orientation="left">Contact</Divider>
        <Input.Group compact>
          <Form.Item rules={rules.FIRST_NAME} name="firstName" style={{ width: '50%', paddingRight: '10px' }}>
            <Input placeholder="First Name" disabled={isBusyProcessing} maxLength={50} size="large" />
          </Form.Item>
          <Form.Item rules={rules.LAST_NAME} name="lastName" style={{ width: '50%' }}>
            <Input placeholder="Last Name" disabled={isBusyProcessing} maxLength={50} size="large" />
          </Form.Item>
        </Input.Group>
        <Form.Item rules={rules.EMAIL} name="email" validateTrigger='onBlur'>
          <Input placeholder="Email" disabled={isBusyProcessing} maxLength={50} size="large" />
        </Form.Item>
        <Form.Item>
          <Input.Group compact>
            <Form.Item name="phoneCountryCode" noStyle>
              <Select
                showSearch
                optionFilterProp="children"
                onChange={(v) => { setMaskedPhone(v === 'US'); form.setFieldsValue({ phoneNumber: '' }) }}
                style={{ width: '30%' }}
                disabled={isBusyProcessing || countries.length < 2}
                size="large"
              >
                {countries.map((country) => (
                  <Select.Option value={country.iso2} key={country.iso2}>
                    {`${country.iso3} ${country.dial_code}`}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item rules={rules.PHONE} name="phoneNumber" noStyle>
              {maskedPhone ?
                <MaskedInput style={{ width: '70%' }} type="tel" size="large" placeholder="Mobile Number" mask={'(000) 000-0000'} disabled={isBusyProcessing} />
                :
                <Input style={{ width: '70%' }} type="tel" size="large" placeholder="Mobile Number" disabled={isBusyProcessing} />
              }
            </Form.Item>
          </Input.Group>
        </Form.Item>
        <Form.Item>
          <Button size="large" type="primary" style={{ width: '100%' }} htmlType="submit" disabled={isBusyProcessing} loading={isBusyProcessing}>
            {isBusyProcessing ? 'Processing' : 'Process'}
          </Button>
        </Form.Item>
      </Form>
    </LogoPageWrapper>
  )
}

export default CaptureBillingDetails

import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  quotesData: [],
}

export const common = createSlice({
  name: 'btcdirect',
  initialState,
  reducers: {
    setQuotesData: (state, action) => {
      state.quotesData = action.payload
    }
  },
})

export const {
  setQuotesData,
} = common.actions

export default common.reducer

import React from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import { normalize } from 'styled-normalize'
import { useSelector } from 'react-redux'

export const GlobalStyle = createGlobalStyle`
  ${normalize}
  body {
    // background: ${({ theme }) => theme.colors.backgroundColor} ${props => props.global.backgroundImage && `fixed url(${props.global.backgroundImage}) repeat-y right top`};
    background: #0c112a;
    h1, h2, h3, h4, h5, h6, .ant-form, .ant-form-item-label > label, .ant-list-item, .ant-radio-wrapper, .ant-divider, .ant-divider-inner-text {
      color: ${({ theme }) => theme.colors.foregroundPrimary};
    }
    .ant-form-item-explain-error {
      color: ${({ theme }) => theme.colors.dangerous};
    }
    .ant-alert-error {
      background-color: unset;
      border: unset;
    }
    background-size: cover;
    height: 100vh;
    width: auto;
  }
  #root {
    height: 100%;
    overflow-y: scroll;

    // Firefox
    scrollbar-width: none;

    // Internet Explorer 10+
    -ms-overflow-style: none;
  }
  #root::-webkit-scrollbar {
    //Chromium & Safari?
    width: 0;
}
`

const SiteWrapper = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
`

const InnerSiteWrapper = styled.div`
  width: 100%;
  max-width: 450px;
`

const SiteLayout = ({ children }) => {
  const account = useSelector((state) => state.account.current)
  return (
    <>
      <GlobalStyle {...account.styling} />
      <SiteWrapper>
        <InnerSiteWrapper>{children}</InnerSiteWrapper>
      </SiteWrapper>
    </>
  )
}

export default SiteLayout

import styled from 'styled-components'

export const CardInput = styled.div`
  position: relative;
  margin: 0;

  input {
    background: none;
    color: ${({ theme }) => theme.colors.walletText};
    font-size: 14px;
    width: 100%;
    padding: 10px 10px 10px 2px;
    display: block;
    border: none;
    border-radius: 0;

    &:focus {
      outline: none;
    }
    &:focus ~ span, &:valid ~ span {
      // top: -8px;
      transform: ${({ walletAddress }) => !walletAddress && 'translateY(-8px)'};
      font-size: 10px;
      color: #acacac;
    }
  }
  span {
    color: #acacac;
    font-size: 14px;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 0px;
    top: ${({ walletAddress }) => walletAddress ? '-8px' : '0px'};
    // transition: all 0.3s ease-in;
    transform: ${({ walletAddress }) => !walletAddress && `translateY(0px)`};
  }
`

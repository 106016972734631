import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { loadScript } from 'utils/dom'
import Loader from 'components/ui/loader'

const SimplexIframe = () => {
  const sessionData = useSelector((state) => state.app.sessionData)
  const account = useSelector((state) => state.account.current)
  const [loader, setLoader] = useState(false)
  let timer

  const simplexSDKs = {
    // Simplex SDK to load in Sandbox
    SANDBOX: ['https://cdn.test-simplexcc.com/sdk/v1/js/sdk.js'],
    // Simplex SDK to load in Production
    PRODUCTION: ['https://cdn.simplex.com/sdk/v1/js/sdk.js', 'https://checkout.simplexcc.com/splx.js']
  }

  const init = async () => {
    if (window.simplex) {
      return
    }
    window.simplexAsyncFunction = function () {
      window.Simplex.init({public_key: sessionData.sessionData.serviceProviderPublicApiKey})
    }
    for (let file of simplexSDKs[sessionData.sessionData.serviceProviderEnvironment]) {
      await loadScript(`simplex-${file}`, file)
      setLoader(true)
    }
  }

  useEffect(() => {
    init()
  }, [])

  useEffect(() => {
    if (loader && sessionData?.sessionData?.spOrderId) {
      timer = setTimeout(() => {
        setLoader(false)
        window.Simplex.load({
          payment_id: sessionData?.sessionData?.spOrderId
        })
        window.Simplex.subscribe('onlineFlowFinished', function (e) {
          window.top.location.href = account.options.returnUrl
        })
        clearTimeout(timer)
      }, 2000)
    }
  }, [loader, sessionData])

  return (
    <div>
      { loader && <Loader /> }
      <form id="simplex-form">
        <div id="checkout-element" />
      </form>
    </div>
  )
}

export default SimplexIframe

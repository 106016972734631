import React from 'react'
import { useSelector } from 'react-redux'
import { Row, Col } from 'antd'
import styled from 'styled-components'

const Container = styled.div`
  height: 100%;
  width: 100%;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Page = styled.div`
  display: flex;
  flex-direction: column;
  height: ${({ staticPageHeight }) => staticPageHeight ? 'auto' : '100%'};
`

const ContentWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.borderColor};
  color: ${({ theme }) => theme.colors.borderTextColor};
  height: 100%;
  padding: 0px 2px 0px 2px;
  max-height: auto;
  min-height: 520px;
  overflow: hidden;
  border-radius: 15px;
`

const ContentContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.backgroundTertiary};
  color: #000000;
  padding: 15px;
  border-radius: 15px;
  margin: 8px 6px;
  position: relative;
  height: ${({ staticContainerHeight }) => staticContainerHeight ? '630px' : '100%' };
  max-height: 692px;
  min-height: 520px;
`

const BinanceProvider = () => {
  const sessionData = useSelector((state) => state.app.sessionData)

  if (sessionData?.sessionData?.serviceProviderUrl !== '') {
    window.location.href = sessionData?.sessionData?.serviceProviderUrl
  }

  return (
    <Page>
      <ContentWrapper>
        <Row>
          <Col span={8}></Col>
          <Col span={8}>
          </Col>
          <Col span={8} />
        </Row>
        <ContentContainer contained={true} staticContainerHeight={true}>
          <Container>
            Redirecting to Binance...
          </Container>
        </ContentContainer>
      </ContentWrapper>
    </Page>
  )
}

export default BinanceProvider

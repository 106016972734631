import React, { useState } from 'react'
import { Badge, List, Skeleton } from 'antd'
import styled, { useTheme } from 'styled-components'

import Currency from 'components/ui/currency'
import { CloseOutlined, DoubleRightOutlined } from '@ant-design/icons'

import { isMobileScreen } from 'utils/constants'
import { useSelector } from 'react-redux'

const QuoteItem = styled(List.Item)`
  position: relative;
  border-bottom: none;
  padding: ${({ismobilescreen}) => ismobilescreen};
  margin: 8px 0px;
  border: 1px solid ${({ theme }) => theme.colors.cardPaymentBorder};
  border-bottom: 1px solid ${({ theme }) => theme.colors.cardPaymentBorder} !important;
  background: ${({ theme }) => theme.colors.quoteCard};
  border-radius: 8px;
  font-size: 14px;
  h4 {
    margin: 0px;
  }
`

const QuoteItemList = styled(List.Item)`
  position: relative;
  border-bottom: none;
  padding: ${({ismobilescreen}) => ismobilescreen};
  margin: 8px 0px;
  border: 1px solid ${({ theme }) => theme.colors.cardPaymentBorder};
  border-bottom: 1px solid ${({ theme }) => theme.colors.cardPaymentBorder} !important;
  background: ${({ theme }) => theme.colors.quoteCard};
  border-radius: 8px;
  font-size: 14px;
  h4 {
    margin: 0px;
  }
  cursor: pointer;
  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.quoteHover};
    border-bottom: 1px solid ${({ theme }) => theme.colors.quoteHover} !important;
  }
`

const QuoteRow = styled.div`
  display: flex;
  flex-direction: ${({ full }) => full ? 'column' : 'row'};
  justify-content: space-between;
  font-size: ${({ titleProp }) => titleProp ? '17px' : '14px'};
  &, label {
    color: ${({ theme }) => theme.colors.foregroundPrimary} !important;
    height:100%;
    svg {
      fill: ${({ theme }) => theme.colors.foregroundPrimary} !important;
    }
  };
  cursor: ${({ isClick }) => isClick ? 'pointer' : 'auto'};
  font-weight: ${({ bold }) => bold ? 'bold' : 'normal'};
`

const QuoteLogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const QuoteDiv = styled.div`
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: end;
`

const ProviderName = styled.div`
  font-size: 14px;
  margin-left: 10px;
`

// const TagLabel = styled(Tag)`
//   font-size: 10px;
//   border-radius: 2.91px;
//   padding: 1px 3px !important;
//   line-height: 15px !important;
// `

const DownIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0px 0px 8px 8px;
  // border: 1px solid #8569B3;
  // border-left: none;
  opacity: ${({ opacity }) => opacity};
  background: ${({ theme }) => theme.colors.quoteDropSection};
  position: absolute;
  bottom: 0px;
  right: 0;
  height: calc(100% - 75%);
  width: 100%;
  cursor: pointer;
`

const DownDownIcon = styled(DoubleRightOutlined)`
  color: ${({ theme }) => theme.colors.downIcon};
  font-size: 12px;
  transform: rotate(90deg);
`

const Drawer = styled.div`
  color: #000000;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  border-radius: 6px;
  background: ${({ theme }) => theme.colors.drawerBackgoundPrimary};
  z-index: 11;
  padding: 15px;
  display: ${({height}) => height};
  transform: translateY(100%);
	-webkit-transform: translateY(100%);
`

const Header = styled.div`
  color: ${({ theme }) => theme.colors.drawerHeaderColor};
  text-align: left;
  font-size: 18px;
  position: relative;
  font-weight: 500;
  /* margin-bottom: 20px; */
`
const SubHeader = styled.div`
  color: #94969C;
  text-align: left;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 10px; // Add any other styles as needed
`

const DrawerList = styled(List)`
  height: ${({ismobilescreen}) => ismobilescreen};
  overflow-y: auto;
`

const Filter = styled(Badge)`
  margin-right: 12px !important;
  margin-bottom: 20px !important;
  cursor: pointer !important;

  .ant-badge-multiple-words {
    padding: 0 22px !important;
  }
  .ant-badge-count {
    color: ${({ textColor }) => textColor} !important;
    border: 1px solid ${({ border }) => border} !important;
    background: ${({ background }) => background} !important;
    height: 40px !important;
    border-radius: 20px !important;
    line-height: 38px !important;
    box-shadow: none !important;
    font-size: 14px !important;
  }
`

const QuoteNumber = styled.span`
  color: ${({ theme }) => theme.colors.quoteSize};
  font-size: 10px;
  position: absolute;
  left: 10px;
`

const QuoteFilter = styled.div`
  display: flex;
  justify-content: center;
`

// eslint-disable-next-line no-unused-vars
const QuoteAmountFilter = styled.div`
  display: flex;
  justify-content: center;
  font-size: 20px;
  margin-bottom: 15px;
  color: ${({ theme }) => theme.colors.drawerHeaderColor};
`

const DrawerQuotes = ({ selectedQuote, serviceProviderDetails, buySellFlow, data, header, onOpen, onClose, onSelect }) => {
  const customerScoreLimit = 35
  const showDrawer = 1
  const theme = useTheme()
  const [ openDrawer, setOpenDrawer ] = useState(false)
  const [ isOpen, setIsOpen ] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const { sourceCurrencyCode, sourceAmount } = useSelector((state) => state.wizard)
  const [ selectedQuotation, setSelectedQuotation ] = useState(selectedQuote)
  const [ sortBy, setSortBy ] = useState('amountReceived')

  const getServiceProviderLogo = (serviceProviderName) => {
    if(serviceProviderDetails?.length > 0) {
      const { logos } = serviceProviderDetails.find((logo) => logo.serviceProvider === serviceProviderName)
      if(!logos) return serviceProviderName
      return (<img
        src={theme.name === 'darkMode' ? logos.darkShort : logos.lightShort}
        width={'30px'}
        alt='' />)
    }
    return <Skeleton active paragraph={{ rows: 1 }} title={false} />
  }

  const getServiceProviderName = (serviceProviderName) => {
    if(serviceProviderDetails?.length > 0) {
      const { name } = serviceProviderDetails.find((name) => name.serviceProvider === serviceProviderName)
      if(!name) return serviceProviderName
      return name
    }
    return <Skeleton active paragraph={{ rows: 1 }} title={false} />
  }

  const sortQuotes = (a,b) => {
    if (sortBy === 'recommended') {
      return b.customerScore - a.customerScore
    }
    if (sortBy === 'amountReceived') {
      return b.destinationAmount - a.destinationAmount
    }
    return b.destinationAmount - a.destinationAmount
  }

  const resetDrawer = (isBackButtonClicked) => {
    setOpenDrawer(false)
    setTimeout(() => {
      onClose(false)
    }, 200)
  }

  return (
    <>
      <List
        itemLayout="horizontal"
        dataSource={selectedQuotation?.filter((quote) => quote.destinationAmount > 0).sort(sortQuotes).slice(0, 1)}
        // eslint-disable-next-line complexity
        renderItem={quote => (
          <QuoteItem
            borderColor={quote.customerScore > customerScoreLimit ? theme.colors.quoteEmphasize : theme.colors.foregroundPrimary}
            ismobilescreen={isMobileScreen.toString() === 'true' ? '12px 8px 16px 8px' : '10px 16px 10px 16px'}
            onClick={() => {
              if (data?.quotes?.length > showDrawer) {
                setOpenDrawer(true)
                onOpen(true)
                setIsOpen(true)
              }
            }}
            style={{ cursor: data?.quotes?.length > showDrawer && 'pointer' }}
          >
            <List.Item.Meta
              style={{marginTop : quote.customerScore > customerScoreLimit ? '15px': '0px', maxWidth: '97%', marginBottom: data?.quotes?.length > showDrawer ? '20px' : '5px' }}
              title={
                <QuoteRow
                  titleProp
                  isClick={data?.quotes?.length > showDrawer ? true : false}
                >
                  <QuoteLogoContainer>
                    {getServiceProviderLogo(quote.serviceProvider)}
                    <ProviderName>
                      <div>By {getServiceProviderName(quote.serviceProvider)}</div>
                      {/* <div><TagLabel color="blue">Recommended</TagLabel></div> */}
                    </ProviderName>
                  </QuoteLogoContainer>
                  <span style={{ display: 'flex', flexDirection: 'row' }}>
                    <QuoteDiv>
                      <Currency currencyCode={quote.destinationCurrencyCode} forceCrypto={buySellFlow === 'BUY'} exchangeRate={quote.exchangeRate}>{quote.destinationAmount}</Currency>
                    </QuoteDiv>
                    {
                      data?.quotes?.length > showDrawer &&
                      <DownIcon opacity={theme.name === 'darkMode' ? '0.4' : '1'}>
                        <QuoteNumber>1 of {data?.quotes?.length}</QuoteNumber>
                        <DownDownIcon />
                      </DownIcon>
                    }
                  </span>
                </QuoteRow>}
            />
          </QuoteItem>
        )}
      />
      <Drawer height={!isOpen && 'none'} className={openDrawer ? 'selected' : 'dismiss'}>
        <Header>
          <CloseOutlined style={{ position: 'absolute', right: 0, top: 5 }} onClick={() => resetDrawer(true)}/>
          {header}
        </Header>
        <Header>
          <CloseOutlined style={{ position: 'absolute', right: 0, top: 5 }} onClick={() => resetDrawer(true)}/>
          Choose Onramp
        </Header>
        <SubHeader>
        Available onramps based on location
        </SubHeader>
        {/* {
          buySellFlow === 'BUY' && (
            <QuoteAmountFilter>
              {sourceCurrencyCode} Amount: {sourceAmount}
            </QuoteAmountFilter>
          )
        } */}
        <QuoteFilter>
          <Filter
            background={sortBy === 'recommended' ? theme.colors.quoteFilterSelected : theme.colors.quoteCardBackground}
            border={theme.name === 'darkMode' ? '#8569B3' : '#EBD7FA'}
            textColor={sortBy === 'recommended' ? theme.colors.quoterFilterText : theme.colors.foregroundPrimary}
            count={'Recommended'}
            onClick={() => setSortBy('recommended')}
          />
          <Filter
            background={sortBy === 'amountReceived' ? theme.colors.quoteFilterSelected : theme.colors.quoteCardBackground}
            border={theme.name === 'darkMode' ? '#8569B3' : '#EBD7FA'}
            textColor={sortBy === 'amountReceived' ? theme.colors.quoterFilterText : theme.colors.foregroundPrimary}
            count={'Amount Received'}
            onClick={() => setSortBy('amountReceived')}
          />
        </QuoteFilter>
        <DrawerList
          itemLayout="horizontal"
          ismobilescreen={isMobileScreen ? '80%' : '93%' }
          dataSource={data?.quotes?.filter((quote) => quote.destinationAmount > 0).sort(sortQuotes)}
          // eslint-disable-next-line complexity
          renderItem={quote => (
            <QuoteItemList
              borderColor={quote.customerScore > customerScoreLimit ? theme.colors.quoteEmphasize : theme.colors.foregroundPrimary}
              ismobilescreen={isMobileScreen.toString() === 'true' ? '12px 8px 16px 8px' : '10px 16px 10px 16px'}
              onClick={() => {
                setSelectedQuotation([quote])
                onSelect([quote])
                resetDrawer()
              }}
            >
              <List.Item.Meta
                style={{marginTop : quote.customerScore > customerScoreLimit ? '15px': '0px', maxWidth: '97%' }}
                title={
                  <QuoteRow
                    titleProp
                    isClick={true}
                  >
                    <QuoteLogoContainer>
                      {getServiceProviderLogo(quote.serviceProvider)}
                      <ProviderName>
                        <div>{getServiceProviderName(quote.serviceProvider)}</div>
                        {/* <div><TagLabel color="blue">Recommended</TagLabel></div> */}
                      </ProviderName>
                    </QuoteLogoContainer>
                    <span style={{ display: 'flex', flexDirection: 'row' }}>
                      <QuoteDiv>
                        <Currency currencyCode={quote.destinationCurrencyCode} forceCrypto={buySellFlow === 'BUY'} exchangeRate={quote.exchangeRate}>{quote.destinationAmount}</Currency>
                      </QuoteDiv>
                    </span>
                  </QuoteRow>}
              />
            </QuoteItemList>
          )}
        />
      </Drawer>
    </>
  )
}

export default DrawerQuotes

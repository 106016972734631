export const filterCryptoCurrency = ( buySellFlow, initialSourceCurrencyCode, destinationCurrencyCode, cryptoCurrencies, cryptoLocked ) => {
  const initital = buySellFlow === 'BUY' ? destinationCurrencyCode : initialSourceCurrencyCode
  const result = initital ? cryptoCurrencies.find((crypto) => crypto.currencyCode === initital) : cryptoCurrencies?.[0]
  if (!result && !cryptoLocked) {
    return cryptoCurrencies.find((crypto) => crypto.currencyCode === 'BTC' || crypto.currencyCode === 'ETH') || cryptoCurrencies?.[0]
  }
  return result
}

export default filterCryptoCurrency

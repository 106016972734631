import filterTokenCrypto from './filterTokenCrypto'
import filterNonTokenCrypto from './filterNonTokenCrypto'

export const sortCryptoCurrencies = (cryptoCurrencies, popularTokens, cryptoSearch) => {
  let filterToken = filterTokenCrypto({
    cryptoCurrencies: cryptoCurrencies,
    popularTokens: popularTokens,
    cryptoSearch: cryptoSearch
  })
  let filterNonToken = filterNonTokenCrypto({
    cryptoCurrencies: cryptoCurrencies,
    popularTokens: popularTokens,
    cryptoSearch: cryptoSearch
  }).sort((a,b) => a.name?.localeCompare(b.name))

  let filteredCurrencies = [].concat(filterToken).concat(filterNonToken)
  return filteredCurrencies
}

export default sortCryptoCurrencies

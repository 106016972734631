import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { setDisplayPage, updateSessionData } from 'store/slices/app'

import { Form, Input, Select } from 'antd'
import Button from 'components/ui/button'
import NumericInput from 'components/ui/numeric-input'
import LogoPageWrapper from 'components/ui/logo-page-wrapper'

import { CRYPTO_QUOTE_RESULT } from '../pages'
import countryCodes from 'assets/currency-by-country.json'
import { isSafari, walletTagCurrencies } from 'utils/constants'
import { getCountryKey } from 'utils/getCountyKeyByCurrency'
import { sortCurrencies } from 'utils/formatter'
import { GAevent } from 'utils/analytics'
import styled from 'styled-components'

const FormLabel = styled.div`
  color: #070e26 !important;
`

const WyreQuoteForm = () => {
  const dispatch = useDispatch()
  const sessionData = useSelector((state) => state.app.sessionData)
  const serviceProviderProperties = useSelector((state) => state.serviceProvider.serviceProviderProperties)
  const [formDestinationCurrencyCode, setFormDestinationCurrencyCode] = useState(sessionData.sessionData.destinationCurrencyCode)
  const [form] = Form.useForm()

  useEffect(() => {
    GAevent('edit_order', { payment_provider: 'WYRE' })
  }, [])

  let initialValues = {
    sourceAmount: sessionData.sessionData.sourceAmount,
    sourceCurrencyCode: sessionData.sessionData.sourceCurrencyCode,
    destinationCurrencyCode: sessionData.sessionData.destinationCurrencyCode,
    paymentMethodType: sessionData.sessionData.paymentMethodSubtype || (sessionData.sessionData.paymentMethodType === 'CARD' ? 'MASTERCARD' : sessionData.sessionData.paymentMethodType),
    walletAddress: sessionData.sessionData.walletAddress || '',
    walletTag: sessionData.sessionData.walletTag || '',
  }

  let handleSubmit = (formValues) => {
    dispatch(updateSessionData({ sessionData: formValues }))
    dispatch(setDisplayPage(CRYPTO_QUOTE_RESULT))
  }

  const fiatCurrencies = sortCurrencies('fiat', serviceProviderProperties.fiatCurrencies)
  const cryptoCurrencies = sortCurrencies('crypto', serviceProviderProperties.cryptoCurrencies)

  const rules = {
    amount: [
      {
        required: true,
        message: 'Amount required'
      },
      ({getFieldValue}) => ({
        validator: (_, value) => {
          if (value) {
            const amount = parseFloat(value)
            // consider refactoring with https://github.com/ant-design/ant-design/blob/master/components/form/demo/useWatch.md
            if (['USD', 'NZD', 'AUD', 'SGD', 'CAD', 'CHF', 'EUR', 'GBP'].includes(getFieldValue('sourceCurrencyCode')) && amount > 1000) {
              return Promise.reject(`Wyre only supports transactions up to 1000 ${getFieldValue('sourceCurrencyCode')}.`)
            }
            return amount > 1 ? Promise.resolve() : Promise.reject('Must be greater than 1.00')
          }
          return Promise.reject()
        },
      }),
    ],
    walletTag: [
      {
        required: walletTagCurrencies?.includes(form.getFieldValue('destinationCurrencyCode') || initialValues.destinationCurrencyCode),
        message: 'Wallet Tag is required for this currency'
      }
    ]
  }

  const validateDisable = () => {
    if (sessionData?.sessionData?.sessionType === 'BUY') {
      return sessionData?.sessionData?.lockFields?.includes('destinationCurrencyCode')
    }
    if (sessionData?.sessionData?.sessionType === 'SELL') {
      return sessionData?.sessionData?.lockFields?.includes('sourceCurrencyCode')
    }
  }

  return (
    <LogoPageWrapper title="Buy crypto">
      <Form form={form} name="cryptoPurchaseForm" validateMessages={{ required: 'Field required' }} initialValues={initialValues} onFinish={handleSubmit} layout="vertical">
        <div className="ant-form-item-label"><FormLabel>How much do you want to invest?</FormLabel></div>
        <Input.Group size="large" compact style={{ width: '100%' }}>
          <Form.Item rules={rules.amount} style={{ width: '50%', paddingRight: '10px' }} name="sourceAmount">
            <NumericInput currency="true" placeholder="$0.00" min={1.0} style={{ width: '100%' }} size="large" />
          </Form.Item>
          <Form.Item style={{ width: '50%' }} name="sourceCurrencyCode">
            <Select
              style={{ width: '100%' }}
              showSearch
              filterOption={(input, option) => (option.title).toLowerCase().indexOf(input.toLowerCase()) >= 0 || (option.value).toLowerCase().indexOf(input.toLowerCase()) >= 0}
              size="large"
              disabled={fiatCurrencies.length < 2}
              onChange={(currency) => dispatch(updateSessionData({ ...sessionData, sessionData: { ...sessionData.sessionData, countryCode: getCountryKey(countryCodes, currency) || 'US' } }))}>
              {fiatCurrencies.map((currency) => (
                <Select.Option title={currency.name} key={currency.currencyCode} value={currency.currencyCode}>{currency.currencyCode} - {currency.name}</Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Input.Group>
        <Form.Item name="destinationCurrencyCode" label={<FormLabel>What type of coins are you buying?</FormLabel>} rules={[{ required: true, message: 'Please select your crypto' }]}>
          <Select
            size="large"
            showSearch
            filterOption={(input, option) => option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            onChange={(newValue) => setFormDestinationCurrencyCode(newValue)}
            disabled={validateDisable()}
          >
            {cryptoCurrencies?.filter((currency) => sessionData?.sessionData?.accountCryptoCurrencies?.includes(currency.currencyCode)).map((currency) => (
              <Select.Option key={currency.currencyCode} value={currency.currencyCode}>{currency.name} ({currency.currencyCode})</Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="paymentMethodType" label={<FormLabel>What is your preferred payment method?</FormLabel>} rules={[{ required: true, message: 'Please select your payment method' }]}>
          <Select size="large" disabled={serviceProviderProperties.paymentMethods.length < 2}>
            {serviceProviderProperties?.paymentMethods?.map((method) => (
              <Select.Option
                key={method.name}
                value={method.paymentMethod}
                disabled={!isSafari && method.paymentMethod === 'APPLE_PAY'}
              >
                {`${method.name}${!isSafari && method.paymentMethod === 'APPLE_PAY' ? ' - Safari Only' : ''}`}
              </Select.Option>))}
          </Select>
        </Form.Item>
        <Form.Item name="walletAddress" label={<FormLabel>Wallet address</FormLabel>} rules={[{ required: true, message: 'Please enter your wallet address' }]} required tooltip='Ensure your wallet address is compatible to receive your selected tokens.' style={{ width: '100%' }}>
          <Input
            size="large"
            disabled={sessionData?.sessionData?.lockFields?.includes('walletAddress')}
            onChange={e => dispatch(updateSessionData({ ...sessionData, sessionData: { ...sessionData.sessionData, walletAddress: e.target.value } }))}
          />
        </Form.Item>
        {walletTagCurrencies?.includes(formDestinationCurrencyCode) && (
          <Form.Item
            name='walletTag'
            dependencies={['destinationCurrencyCode']}
            rules={rules.walletTag}
            label="Wallet tag"
          >
            <Input size='large' style={{ borderRadius: '2px' }} placeholder="Wallet tag" />
          </Form.Item>
        )}
        <Form.Item>
          <Button size="large" type="primary" style={{ width: '100%' }} htmlType="submit">
            Proceed
          </Button>
        </Form.Item>
      </Form>
    </LogoPageWrapper>
  )
}

export default WyreQuoteForm

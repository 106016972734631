import { pickDefaultSourceAmount } from 'utils/constants'

export const updateSourceAmount = ( defaultLimit, urlParams, lockSourceAmount, buySellFlow ) => {
  let result = {
    sourceAmount: 0,
    sourceAmountLocked: lockSourceAmount
  }
  const defaultSourceAmount = defaultLimit > 0 ? defaultLimit : pickDefaultSourceAmount((urlParams?.transactionType?.toUpperCase() === 'SELL' ? urlParams.sourceCurrencyCodeLock ?? urlParams.sourceCurrencyCode ?? 'BTC' : buySellFlow === 'SELL' ? 'BTC' : 'USD'))
  const sourceAmount = urlParams?.sourceAmountLocked || urlParams?.sourceAmount || defaultSourceAmount
  if (sourceAmount >= 0) {
    result.sourceAmount = sourceAmount
    result.sourceAmountLocked = urlParams?.sourceAmountLocked ? true : false
  }
  if (sourceAmount < 0) {
    const updatedSourceAmount = urlParams?.sourceAmount >= 0 ? urlParams?.sourceAmount : defaultSourceAmount
    result.sourceAmount = updatedSourceAmount
    result.sourceAmountLocked = false
  }
  if (!Number(sourceAmount)) {
    result.sourceAmount = defaultSourceAmount
    result.sourceAmountLocked = false
  }
  return result
}

export default updateSourceAmount

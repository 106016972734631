import React, { useEffect } from 'react'
import styled from 'styled-components'
import Button from 'components/ui/button'
import LogoPageWrapper from 'components/ui/logo-page-wrapper'
import { GAevent } from 'utils/analytics'
import { useSelector } from 'react-redux'

const Summary = styled.div`
  margin: 20px 0 10px;
  text-align: center;
`

const getBlockChainExplorerLink = (coin, walletAddress) => {
  let link = 'https://www.blockchain.com/explorer'
  let linkMap = {
    btc: `https://blockstream.info/address/${walletAddress}`,
    susdc: `https://stellarchain.io/address/${walletAddress}`,
    avax: `https://explorer.palm.io/address/${walletAddress}`,
    fusd: `https://flowscan.org/account/${walletAddress}`,
    eth: `https://etherscan.io/address/${walletAddress}`,
    dai: `https://etherscan.io/address/${walletAddress}`,
    usdc: `https://etherscan.io/address/${walletAddress}`,
    musdc: `https://etherscan.io/address/${walletAddress}`,
    usdt: `https://etherscan.io/address/${walletAddress}`,
    busd: `https://etherscan.io/address/${walletAddress}`,
    gusd: `https://etherscan.io/address/${walletAddress}`,
    pax: `https://etherscan.io/address/${walletAddress}`,
    usds: `https://etherscan.io/address/${walletAddress}`,
    aave: `https://etherscan.io/address/${walletAddress}`,
    comp: `https://etherscan.io/address/${walletAddress}`,
    link: `https://etherscan.io/address/${walletAddress}`,
    bat: `https://etherscan.io/address/${walletAddress}`,
    crv: `https://etherscan.io/address/${walletAddress}`,
    mkr: `https://etherscan.io/address/${walletAddress}`,
    snx: `https://etherscan.io/address/${walletAddress}`,
    uma: `https://etherscan.io/address/${walletAddress}`,
    uni: `https://etherscan.io/address/${walletAddress}`,
    wbtc: `https://etherscan.io/address/${walletAddress}`,
    yfi: `https://etherscan.io/address/${walletAddress}`,
    gyen: `https://etherscan.io/address/${walletAddress}`,
    zusd: `https://etherscan.io/address/${walletAddress}`,
  }

  return linkMap[coin?.toLowerCase()] || link
}

const PaymentComplete = () => {
  const quotesData = useSelector((state) => state.wyre.quotesData)
  const transactionData = useSelector((state) => state.wyre.transactionData)
  const sessionData = useSelector((state) => state.app.sessionData)
  const account = useSelector((state) => state.account.current)

  const onComplete = () => {
    window.open(account.options.returnUrl, '_self')
  }

  useEffect(() => {
    GAevent('order_successful', { payment_provider: 'WYRE' }) // Todo: remove hardcoded provider name
  }, [])

  let quoteData = quotesData?.[0] || {}
  return (
    <LogoPageWrapper title="Payment Complete">
      <Summary>
        Thank You! Your Wyre Order Number is <strong>{transactionData?.transaction?.serviceTransactionId}</strong>.
      </Summary>
      <Summary>
        The transfer of {quoteData.destAmount} {quoteData.destCurrency} to {sessionData.sessionData.walletAddress} has been initiated.
        <br />
        <br />
        It may take up to 30 minutes for the transaction to complete. You can track the status of your transaction&nbsp;
        <a href={getBlockChainExplorerLink(quoteData.destCurrency, sessionData.sessionData.walletAddress)} target="_blank" rel="noreferrer">here</a>.
      </Summary>
      <Button size="large" type="primary" style={{ width: '100%' }} onClick={onComplete}>
        Back to {account.label}
      </Button>
    </LogoPageWrapper>
  )
}

export default PaymentComplete

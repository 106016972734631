/* eslint-disable complexity */
import countryCodeByTimezone from 'utils/countryCodeByTimezone'
import currencyByCountry from 'assets/currency-by-country.json'

function filterCurrency(sourceCurrencyData, param) {
  return sourceCurrencyData?.find(currency => currency.currencyCode === param)?.currencyCode
}

export const providerFiatCurrency = (urlParams, sourceCurrencyData, countryCode, buySellFlow) => {
  const result = {}
  const currencyCodeLocked = buySellFlow === 'SELL' ? urlParams?.destinationCurrencyCodeLocked : urlParams?.sourceCurrencyCodeLocked
  const currencyCode = buySellFlow === 'SELL' ? urlParams?.destinationCurrencyCode : urlParams?.sourceCurrencyCode

  if (countryCode || countryCodeByTimezone || currencyCodeLocked || currencyCode) {
    let SPPSupportedFiatCurrencyCode

    if (currencyCodeLocked) {
      SPPSupportedFiatCurrencyCode = filterCurrency(sourceCurrencyData, currencyCodeLocked)
      result.initial = SPPSupportedFiatCurrencyCode

      if (!SPPSupportedFiatCurrencyCode) {
        SPPSupportedFiatCurrencyCode = filterCurrency(sourceCurrencyData, currencyCode)
      }

      if (!SPPSupportedFiatCurrencyCode) {
        SPPSupportedFiatCurrencyCode = filterCurrency(sourceCurrencyData, currencyByCountry[countryCode || countryCodeByTimezone])
      }

      if (!SPPSupportedFiatCurrencyCode) {
        SPPSupportedFiatCurrencyCode = filterCurrency(sourceCurrencyData, ('USD' || 'GBP'))
      }

      if (!SPPSupportedFiatCurrencyCode) {
        SPPSupportedFiatCurrencyCode = sourceCurrencyData?.length ? sourceCurrencyData?.[0]?.currencyCode : ''
      }
      result.final = SPPSupportedFiatCurrencyCode ?? 'USD'

      return result
    }

    if (currencyCode) {
      SPPSupportedFiatCurrencyCode = filterCurrency(sourceCurrencyData, currencyCode)

      if (!SPPSupportedFiatCurrencyCode) {
        SPPSupportedFiatCurrencyCode = filterCurrency(sourceCurrencyData, currencyByCountry[countryCode || countryCodeByTimezone])
      }

      if (!SPPSupportedFiatCurrencyCode) {
        SPPSupportedFiatCurrencyCode = filterCurrency(sourceCurrencyData, ('USD' || 'GBP'))
      }

      if (!SPPSupportedFiatCurrencyCode) {
        SPPSupportedFiatCurrencyCode = sourceCurrencyData?.length ? sourceCurrencyData?.[0]?.currencyCode : ''
      }
      result.final = SPPSupportedFiatCurrencyCode ?? 'USD'

      return result
    }

    SPPSupportedFiatCurrencyCode = filterCurrency(sourceCurrencyData, currencyByCountry[countryCode || countryCodeByTimezone])
    if (!SPPSupportedFiatCurrencyCode) {
      SPPSupportedFiatCurrencyCode = filterCurrency(sourceCurrencyData, ('USD' || 'GBP'))
    }

    if (!SPPSupportedFiatCurrencyCode) {
      SPPSupportedFiatCurrencyCode = sourceCurrencyData?.length ? sourceCurrencyData?.[0]?.currencyCode : ''
    }
    result.final = SPPSupportedFiatCurrencyCode ?? 'USD'

    return result
  }

  return result
}

export default providerFiatCurrency

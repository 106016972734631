import React from 'react'
import LogoPageWrapper from 'components/ui/logo-page-wrapper'
import SimplexIframe from './views/iframe'

const SimplexProvider = () => {
  return (
    <LogoPageWrapper staticContainerHeight={false}>
      <SimplexIframe />
    </LogoPageWrapper>
  )
}

export default SimplexProvider

import React from 'react'
import LogoPageWrapper from '../ui/logo-page-wrapper'
import { useSelector } from 'react-redux'

const Error = () => {
  const account = useSelector((state) => state.account.current)

  return (
    <LogoPageWrapper title="An error occurred" subtext="">
      <div style={{margin: '20px 0 20px 0', textAlign: 'center'}}>Your request could not be processed at this time.</div>
      <div style={{margin: '20px 0 20px 0', textAlign: 'center'}}>Please try again at <a href={account.options.returnUrl}>{account.options.returnLinkTitle}</a></div>
    </LogoPageWrapper>
  )
}

export default Error

import axios from 'axios'
import { store } from 'store/store'

import { defaultPublicKey } from 'utils/constants'

const axiosBase = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL_PATH,
  timeout: 60000,
  withCredentials: true,
})

axiosBase.interceptors.request.use((config) => {
  const state = store.getState()
  const wizardSessionToken = config?.sessionToken
  const axiosConfig = {
    ...config,
    headers: {
      ...config.headers,
      'X-Crypto-Session-Token': wizardSessionToken || state?.app?.token,
    },
  }
  if (config.omitToken) {
    delete axiosConfig.headers['X-Crypto-Session-Token']
  }
  if (config.useDefaultPublicKey) {
    axiosConfig.headers.authorization = `BASIC ${state?.wizard?.publicKey ?? defaultPublicKey.publicKey}`
  }
  return axiosConfig
})

async function doRequest ({ method = 'get', url = '', payload = {}, config, completeResponse }) {
  try {
    const result = method === 'get' ?
      await axiosBase[method](url, config) :
      await axiosBase[method](url, payload, config)
    return completeResponse ? result : result.data
  } catch (error) {
    return error
  }
}

export default doRequest

import React from 'react'
import { turkeyCurrencySymbol } from 'utils/constants'

var Currency = ({ children, currencyCode = 'USD', forceCrypto = false, exchangeRate = 0 }) => {
  // This is messy and I kinda hate it, but we use it in a LOT of places so it's a pain to update backwards-compatibly
  if (forceCrypto && exchangeRate) {
    const precision = Math.min(7, Math.max(2, Math.ceil(Math.log10(exchangeRate))))
    return `${Number(children).toFixed(precision)} ${currencyCode}`
  }

  let numberFormat
  try {
    numberFormat = new Intl.NumberFormat('en-US', { style: 'currency', currency: currencyCode }).format(children)
    if (currencyCode === 'TRY') {
      numberFormat = numberFormat.replace(currencyCode, turkeyCurrencySymbol)
    }
  } catch (e) {
    numberFormat = `${Number(children)?.toFixed(5)} ${currencyCode}`
  }
  return <span>{numberFormat}</span>
}

export default Currency

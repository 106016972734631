import React from 'react'
import styled from 'styled-components'
import { Col } from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons'

export const WalletAddressContainer = styled.div`
  width: 100%;
  margin-bottom: 10px;
  &:nth-last-child(2) {
    margin-bottom: 30px;
  }
  label {
    font-weight: bold;
  }
`

export const WalletAddressText = styled.div`
  text-align: left;
  font-size: ${({ fontSize }) => fontSize};
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-word;
`

export const ButtonContainer = styled.div`
  margin: 20px 0 10px;
  > button {
    margin-bottom: 10px;
  }
`

export const ResultItemTitle = styled.div`
font-weight: bold;
`

export const ResultItem = styled.div`
display: flex;
justify-content: space-between;
margin-bottom: 10px;
`

export const WidgetHeader = styled.div`
display: flex;
text-align: center;
align-items: center;
justify-content: space-between
`

export const WidgetHeaderTitle = styled.div`
font-size: ${({ theme }) => theme.fontSizes.title};
`

export const BackButton = ({ onClick, hidden, order = 0 }) => (
  <Col xs={3} sm={hidden ? 0 : 3} order={order} style={{ opacity: hidden ? 0 : 1 }}>
    <BackButtonArrow onClick={onClick} cursor={hidden ? 'auto' : 'pointer'} />
  </Col>
)

const BackButtonArrow = styled(ArrowLeftOutlined)`
  cursor: ${({ cursor  }) => cursor};
  padding: 10px;
  left: 10px;
`

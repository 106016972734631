import _ from 'lodash'

export const filterNonTokenCrypto = ({ cryptoCurrencies, popularTokens, cryptoSearch }) => {
  let nonPopularCurrencies = cryptoCurrencies?.filter((token) => !popularTokens?.includes(token.currencyCode))
  let nonPopularExactCurrencies = nonPopularCurrencies.filter((crypto) => {
    const displayName = crypto?.name?.toLowerCase()
    const currencyCode = crypto?.currencyCode?.toLowerCase()
    if (displayName === cryptoSearch?.toLowerCase() || currencyCode === cryptoSearch?.toLowerCase()) {
      return crypto
    }
  })
  let nonPopularNonExactCurrencies = nonPopularCurrencies.filter((crypto) => {
    const excludeCrypto = nonPopularExactCurrencies.find((token) => token.currencyCode === crypto?.currencyCode)
    if (!excludeCrypto) {
      const displayName = _.startsWith(crypto?.name?.toLowerCase(), cryptoSearch?.toLowerCase())
      const currencyCode = _.startsWith(crypto?.currencyCode?.toLowerCase(), cryptoSearch?.toLowerCase())
      if (displayName || currencyCode) {
        return crypto
      }
    }
  })

  return nonPopularExactCurrencies.concat(nonPopularNonExactCurrencies)
}

export default filterNonTokenCrypto

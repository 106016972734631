export const loadScript = async (label = 'script', src = '') => {
  return new Promise((resolve, reject) => {
    let script = document.createElement('script')
    script.type = 'text/javascript'
    script.id = label
    script.src = src
    script.async = true
    script.onload = () => {
      resolve()
    }
    script.onerror = (e) => {
      console.error(e)
      reject(e.message)
    }

    let tag = document.getElementsByTagName('script')[0]
    tag.parentNode.insertBefore(script, tag)
  })
}

const enums = {
  BUY: {
    buy_country_page_view: {
      name: '1 Buy Country Page View',
      description: 'A user lands on the country selection screen'
    },
    buy_country_drawer_open: {
      name: 'Buy Country Drawer Open',
      description: 'A user opens the country selection drawer'
    },
    buy_country_drawer_back_button: {
      name: 'Buy Country Drawer Back',
      description: 'A user clicks the back button from the country selection drawer'
    },
    buy_country_selection: {
      name: 'Buy Country Drawer Select',
      description: 'A user select a country'
    },
    buy_country_continue: {
      name: '1 Buy Country Continue',
      description: 'A user clicks the continue button on the country selection screen'
    },
    buy_payment_method_page_view: {
      name: '2 Buy Payment Method Page View',
      description: 'A user lands on the payment method selection screen'
    },
    buy_payment_method_expand: {
      name: '2 Buy Payment Method Expand',
      description: 'A user clicks on one of the top level payment method types to expand it'
    },
    buy_payment_method_minimize: {
      name: '2 Buy Payment Method Minimize',
      description: 'A user clicks on an expanded payment method type to minimize the box'
    },
    buy_payment_method_selection: {
      name: '2 Buy Payment Method Selection',
      description: 'A user clicks on the Select button next to a payment method subtype'
    },
    buy_payment_method_back_button: {
      name: '2 Buy Payment Method Back Button',
      description: 'A user clicks the back button to go back to the country selection screen'
    },
    buy_details_page_view: {
      name: '3 Buy Details Page View',
      description: 'A user lands on the details aka amount to buy screen'
    },
    buy_details_amount_selection: {
      name: '3 Buy Details Amount Selection',
      description: 'A user edits the purchase amount'
    },
    buy_details_fiat_currency_drawer_open: {
      name: 'Buy Fiat Drawer Open',
      description: 'A user opens the fiat currency drawer'
    },
    buy_details_fiat_currency_drawer_back_button: {
      name: 'Buy Fiat Drawer Back',
      description: 'A user clicks the back button from the fiat currency selection drawer'
    },
    buy_details_fiat_currency_selection: {
      name: 'Buy Fiat Drawer Select',
      description: 'A user select a fiat currency'
    },
    buy_details_token_drawer_open: {
      name: 'Buy Token Drawer Open',
      description: 'A user opens the token selection drawer'
    },
    buy_details_token_drawer_back_button: {
      name: 'Buy Token Drawer Back',
      description: 'A user clicks the back button from the token selection drawer'
    },
    buy_details_token_network_open: {
      name: '3.5 Buy Details Token Network Open',
      description: 'In the cryptocurrency drawer, a user clicks the network selection dropdown'
    },
    buy_details_token_network_close: {
      name: '3.5 Buy Details Token Network Close',
      description: 'In the cryptocurrency drawer, a user close the network selection dropdown'
    },
    buy_details_token_network_selection: {
      name: '3.5 Buy Details Token Network Selection',
      description: 'In the cryptocurrency drawer, in the network selection dropdown a user selects a network'
    },
    buy_details_token_selection: {
      name: 'Buy Token Drawer Select',
      description: 'A user select a token'
    },
    buy_details_wallet_address_selection: {
      name: '3 Buy Details Wallet Address Selection',
      description: 'A user enters a wallet address into the box or edits the existing one'
    },
    buy_details_update_payment_method: {
      name: '3 Buy Details Update Payment Method',
      description: 'A user clicks the button to go back to the payment method selection screen'
    },
    buy_details_back_button: {
      name: '3 Buy Details Back Button',
      description: 'A user clicks the back button to go back to the payment method screen'
    },
    buy_details_continue: {
      name: '3 Buy Details Continue',
      description: 'A user clicks the Continue button on the details screen'
    },
    buy_quotes_page_view: {
      name: '4 Buy Quotes Page View',
      description: 'A user lands on the quote screen'
    },
    buy_quotes_show_more_button: {
      name: '4 Buy Quotes Show More Button',
      description: 'A user clicks the Show More button to see more quotes'
    },
    buy_quotes_expand_service_provider: {
      name: '4 Buy Quotes Expand ${serviceProvider}',
      description: 'A user clicks on a ${serviceProvider} quote to expand it'
    },
    buy_quotes_minimize_service_provider: {
      name: '4 Buy Quotes Minimize ${serviceProvider}',
      description: 'A user clicks on an expanded ${serviceProvider} quote to minimize it'
    },
    buy_quotes_wallet_address_change: {
      name: '4 Buy Quotes Wallet Address Change',
      description: 'A user edits the wallet address on the quote screen. This action does not have to be provider specific'
    },
    buy_quotes_continue_service_provider: {
      name: '4 Buy Quotes Continue ${serviceProvider}',
      description: 'A user clicks Continue on the ${serviceProvider} quote to launch the ${serviceProvider} widget'
    },
    buy_quotes_back_button: {
      name: '4 Buy Quotes Back Button',
      description: 'A user clicks the back button to go back to the details screen'
    },
    buy_waiting_return: {
      name: '5 Buy Waiting Return',
      description: 'A user clicks the “back to quotes” button on the bottom of the screen shown while Banxa or Moonpay is open in a separate tab'
    },
    buy_button_click: {
      name: 'Buy Button Click',
      description: 'A user clicks on the Buy button at the top of the wizard'
    },
    buy_wizard_page_view: {
      name: 'Buy Wizard Page View',
      description: 'A user lands on the wizard main screen in the buy flow'
    },
    buy_wizard_continue: {
      name: 'Buy Wizard Continue',
      description: 'User clicks on the “Buy ${cryptoCurrencyCode}” button on the bottom'
    },
    buy_details_payment_drawer_open: {
      name: 'Buy Payment Method Drawer Open',
      description: 'A user opens the payment method selection drawer'
    },
    buy_details_payment_drawer_back_button: {
      name: 'Buy Payment Method Drawer Back',
      description: 'A user clicks the back button from the payment method selection drawer'
    },
    buy_details_payment_selection: {
      name: 'Buy Payment Method Drawer Select',
      description: 'A user select a payment method'
    },
    buy_details_quote_drawer_open: {
      name: 'Buy Quote Drawer Open',
      description: 'A user opens the quote selection drawer'
    },
    buy_details_quote_drawer_back_button: {
      name: 'Buy Quote Drawer Back',
      description: 'A user clicks the back button from the quote selection drawer'
    },
    buy_details_quote_selection: {
      name: 'Buy Quote Drawer Select',
      description: 'A user select a quote'
    },
  },
  SELL: {
    sell_country_page_view: {
      name: '1 Sell Country Page View',
      description: 'A user lands on the country selection screen'
    },
    sell_country_drawer_open: {
      name: 'Sell Country Drawer Open',
      description: 'A user opens the country selection drawer'
    },
    sell_country_drawer_back_button: {
      name: 'Sell Country Drawer Back',
      description: 'A user clicks the back button from the country selection drawer'
    },
    sell_country_selection: {
      name: 'Sell Country Drawer Select',
      description: 'A user select a country'
    },
    sell_country_continue: {
      name: '1 Sell Country Continue',
      description: 'A user clicks the continue button on the country selection screen'
    },
    sell_details_page_view: {
      name: '2 Sell Details Page View',
      description: 'A user lands on the details aka amount to buy screen'
    },
    sell_details_amount_selection: {
      name: '2 Sell Details Amount Selection',
      description: 'A user edits the purchase amount'
    },
    sell_details_fiat_currency_drawer_open: {
      name: 'Sell Fiat Drawer Open',
      description: 'A user opens the fiat currency selection drawer'
    },
    sell_details_fiat_currency_drawer_back_button: {
      name: 'Sell Fiat Drawer Back',
      description: 'A user clicks the back button from the fiat currency selection drawer'
    },
    sell_details_fiat_currency_selection: {
      name: 'Sell Fiat Drawer Select',
      description: 'A user select a fiat currency'
    },
    sell_details_token_drawer_open: {
      name: 'Sell Token Drawer Open',
      description: 'A user opens the token selection drawer'
    },
    sell_details_token_drawer_back_button: {
      name: 'Sell Token Drawer Back',
      description: 'A user clicks the back button from the token selection drawer'
    },
    sell_details_token_network_open: {
      name: 'Sell Token Drawer Select',
      description: 'A user select a token'
    },
    sell_details_token_network_close: {
      name: '2.5 Sell Details Token Network Close',
      description: 'In the cryptocurrency drawer, a user close the network selection dropdown'
    },
    sell_details_token_network_selection: {
      name: '2.5 Sell Details Token Network Selection',
      description: 'In the cryptocurrency drawer, in the network selection dropdown a user selects a network'
    },
    sell_details_token_selection: {
      name: 'Sell Token Drawer Select',
      description: 'A user select a token'
    },
    sell_details_back_button: {
      name: '2 Sell Details Back Button',
      description: 'A user clicks the back button to go back to the payment method screen'
    },
    sell_details_continue: {
      name: '2 Sell Details Continue',
      description: 'A user clicks the continue button on the details screen'
    },
    sell_quotes_page_view: {
      name: '3 Sell Quotes Page View',
      description: 'A user lands on the quote screen'
    },
    sell_quotes_show_more_button: {
      name: '3 Sell Quotes Show More Button',
      description: 'A user clicks the Show More button to see more quotes'
    },
    sell_quotes_expand_service_provider: {
      name: '3 Sell Quotes Expand ${serviceProvider}',
      description: 'A user clicks on a ${serviceProvider} quote to expand it'
    },
    sell_quotes_minimize_service_provider: {
      name: '3 Sell Quotes Minimize ${serviceProvider}',
      description: 'A user clicks on an expanded ${serviceProvider} quote to minimize it'
    },
    sell_quotes_continue_service_provider: {
      name: '3 Sell Quotes Continue ${serviceProvider}',
      description: 'A user clicks Continue on the ${serviceProvider} quote to launch the ${serviceProvider} widget'
    },
    sell_quotes_back_button: {
      name: '3 Sell Quotes Back Button',
      description: 'A user clicks the back button to go back to the details screen'
    },
    sell_waiting_return: {
      name: '4 Sell Waiting Return',
      description: 'A user clicks the “back to quotes” button on the bottom of the screen shown while Banxa or Moonpay is open in a separate tab'
    },
    sell_button_click: {
      name: 'Sell Button Click',
      description: 'A user clicks on the Sell button at the top of the wizard'
    },
    sell_wizard_page_view: {
      name: 'Sell Wizard Page View',
      description: 'A user lands on the wizard main screen in the sell flow'
    },
    sell_wizard_continue: {
      name: 'Sell Wizard Continue',
      description: 'User clicks on the “Sell ${cryptoCurrencyCode}” button on the bottom'
    },
    sell_details_payment_drawer_open: {
      name: 'Sell Payment Method Drawer Open',
      description: 'A user opens the payment method selection drawer'
    },
    sell_details_payment_drawer_back_button: {
      name: 'Sell Payment Method Drawer Back',
      description: 'A user clicks the back button from the payment method selection drawer'
    },
    sell_details_payment_selection: {
      name: 'Sell Payment Method Drawer Select',
      description: 'A user select a payment method'
    },
    sell_details_quote_drawer_open: {
      name: 'Sell Quote Drawer Open',
      description: 'A user opens the quote selection drawer'
    },
    sell_details_quote_drawer_back_button: {
      name: 'Sell Quote Drawer Back',
      description: 'A user clicks the back button from the quote selection drawer'
    },
    sell_details_quote_selection: {
      name: 'Sell Quote Drawer Select',
      description: 'A user select a quote'
    },
  },
  TOGGLE: {
    mode_toggle: {
      name: 'Mode Toggle',
      description: 'A user clicks the light mode / dark mode toggle button in the bottom left'
    },
  }
}
export const mixpanelConstant = (eventName, buySellFlow) => {
  return enums[buySellFlow]?.[eventName] || ''
}

import React from 'react'
import styled from 'styled-components'
import MaskedInput from 'antd-mask-input'
import visa from 'assets/payment-icons/visa.svg'
import mastercard from 'assets/payment-icons/mastercard.svg'

const CardImg = styled.img`
  margin-left: 2px;
`

const CardLogos = () => {
  return (
    <>
      <CardImg src={visa} alt="visa" height="20px" />
      <CardImg src={mastercard} alt="mastercard" height="20px" />
    </>
  )
}

const CreditCardNumberInput = (props) => {
  return <MaskedInput size="large" mask="0000-0000-0000-0000" suffix={<CardLogos />} {...props} />
}

export default CreditCardNumberInput

import { Col, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { copyrightText } from 'utils/constants'

const Page = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  margin: 10px 0px;
`

const ContentWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.defaultBackgroundColor};
  -webkit-transition: background-color 3000ms ease;
  -moz-transition: background-color 3000ms ease;
  -o-transition: background-color 3000ms ease;
  -ms-transition: background-color 3000ms ease;
  transition: background-color 3000ms ease;
  color: ${({ theme }) => theme.colors.borderTextColor};
  height: 100%;
  padding: 0px 2px 0px 2px;
  max-height: auto;
  min-height: 520px;
  overflow: hidden;
  border-radius: 15px;
`

const SubContextContainer = styled.div`
  margin-top: -6px;
  height: 38px;
  line-height: 38px;
  font-size: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 11px;
  color: ${({ theme }) => theme.colors.borderTextColor};
  position: relative;
  z-index: 10;
`

const SubContext = styled.div`
  flex: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  &:first-of-type {
    flex: 1;
    justify-content: flex-start;
  }
  &:last-of-type {
    flex: 1;
    justify-content: flex-end;
  }
`

const ContentContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.backgroundPrimary};
  -webkit-transition: background-color 3000ms ease;
  -moz-transition: background-color 3000ms ease;
  -o-transition: background-color 3000ms ease;
  -ms-transition: background-color 3000ms ease;
  transition: background-color 3000ms ease;
  color: #000000;
  padding: 15px;
  border-radius: 15px;
  margin: 8px 6px;
  position: relative;
  height: 615px;
  min-height: 520px;
`

const ServiceProviderSection = styled.div`
  width: 100%;
  max-width: 430px;
  height: 100%;
  min-height: 630px;
  background-color: ${({ theme }) => theme.colors.backgroundPrimary};
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  text-align: center;
  position: relative;
`

const BackButton = styled.div`
  // background: ${({ theme }) => theme.buttonColor && theme.buttonColor } ${({ theme }) => theme.buttonColor && `!important`};
  // background: linear-gradient(112.11deg, ${({ theme }) => theme.colors.filled} -18.69%, ${({ theme }) => theme.colors.highlightPrimary} 102.07%) ${({ theme }) => !theme.buttonColor && `!important`};
  background: ${({ theme }) => theme.name === 'darkMode' ? ((theme.darkModeButtonColor === '#zzzzzz' || !theme.darkModeButtonColor) ? theme.colors.filled : theme.darkModeButtonColor) : ((theme.buttonColor === '#zzzzzz' ||  !theme.buttonColor) ? theme.colors.filled : theme.buttonColor) } !important;
  color: ${({ theme }) => theme.colors.foregroundPrimary};
  text-align: center;
  padding: 15px 40px;
  min-height: 56px;
  width: 100%;
  position: absolute;
  bottom: 3.8em;
  border-radius: 5px;
  cursor: pointer;

  @media only screen and (max-width: 335px) {
    height: 60px;
  }

  width: 100%;
  font-size: 14px;
  transition: transform .4s;
  &:hover {
    transform: scale(${({ transform }) => transform});
    transform-origin: 50% 50%;
  }
`

const WizardError = () => {
  const redirectTime = 5
  const [ remainSec, setRemainSec ] = useState(redirectTime)
  let timer

  useEffect(() => {
    if (remainSec === redirectTime) {
      let remainTime = (remainSec - 1)
      timer = setInterval(() => {
        setRemainSec(remainTime--)
        if (remainTime < 0) {
          const url = new URL(window.location.href)
          const params = new URLSearchParams(url.search)
          params.delete('publicKey')
          window.location.href = `${window.location.origin}?${params}`
          clearInterval(timer)
        }
      }, 1000)
    }
  }, [remainSec])

  const goBack = async (isButtonClicked) => {
    const url = new URL(window.location.href)
    const params = new URLSearchParams(url.search)
    params.delete('publicKey')
    window.location.href = `${window.location.origin}?${params}`
    clearInterval(timer)
  }


  return (
    <>
      <Page>
        <ContentWrapper>
          <Row>
            <Col span={24}>
              <SubContextContainer>
                <SubContext>
                </SubContext>
                <SubContext style={{ marginTop: '14px' }}>

                </SubContext>
                <SubContext></SubContext>
              </SubContextContainer>
            </Col>
          </Row>
          <ContentContainer>
            <ServiceProviderSection>
              <div style={{ marginTop: '-6em' }}>Invalid url, you will be redirected to the Meld wizard</div>
              <BackButton onClick={(e) => goBack(true)}>
                You will be redirected to the Meld wizard in {remainSec}
              </BackButton>
            </ServiceProviderSection>
          </ContentContainer>
          <SubContextContainer>
          <SubContext>
          </SubContext>
          <SubContext>
            <span>{copyrightText}&nbsp;<b>Meld.io</b></span>
          </SubContext>
          <SubContext>

          </SubContext>
        </SubContextContainer>
        </ContentWrapper>
      </Page>
    </>
  )
}

export default WizardError

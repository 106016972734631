import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import Button from 'components/ui/button'
import Currency from 'components/ui/currency'
import LogoPageWrapper from 'components/ui/logo-page-wrapper'

import { GAevent } from 'utils/analytics'

const Summary = styled.div`
  margin: 20px 0 10px;
  text-align: center;
`

const PaymentFailure = () => {
  const transactionData = useSelector((state) => state.wyre.transactionData)
  const quotesData = useSelector((state) => state.wyre.quotesData)
  const account = useSelector((state) => state.account.current)

  const onComplete = () => {
    window.open(account.options.returnUrl, '_self')
  }

  useEffect(() => {
    GAevent('order_fail', { payment_provider: 'WYRE' }) // Todo: remove hardcoded provider name
  }, [])

  let quoteData = quotesData?.[0] || {}
  return (
    <LogoPageWrapper title="Order Unsuccessful">
      <Summary>Your payment could not be processed at this time.</Summary>
      <Summary>
        Your card was not charged for <Currency currencyCode={quoteData.sourceCurrencyCode}>{quoteData.sourceAmount}</Currency>.
        <br />
        If you need assistance, please submit a request to Wyre&nbsp;
        <a href="https://support.sendwyre.com/hc/en-us/requests/new?ticket_form_id=360004784573" target="_blank" rel="noreferrer">here</a>.
        <br />
        Your Wyre Order Number is <strong>{transactionData?.transaction?.serviceTransactionId}</strong>.
        <br />
      </Summary>
      <Button size="large" type="primary" style={{ width: '100%' }} onClick={onComplete}>
        Back to {account.label}
      </Button>
    </LogoPageWrapper>
  )
}

export default PaymentFailure

import React, { useEffect } from 'react'
import LogoPageWrapper from 'components/ui/logo-page-wrapper'
import { useSelector } from 'react-redux'
import { getURLParams } from 'utils/data-model'

const PaybisProvider = () => {
  const sessionData = useSelector((state) => state.app.sessionData)

  if (sessionData?.sessionData?.paymentMethodType === 'APPLE_PAY') {
    window.location.href = sessionData?.sessionData?.serviceProviderUrl
  }

  useEffect(() => {
    const urlObj = new URL(sessionData.sessionData.serviceProviderUrl)
    const qparams = getURLParams(urlObj.search)

    window.PartnerExchangeWidget.openInEmbed(
      { requestId: qparams.requestId },
      document.getElementById('paybis-widget-container')
    )
    const container = document.getElementById('paybis-widget-container')
    if (container) {
      const iFrame = container.querySelector('iframe')
      if (iFrame) {
        iFrame.style.height = '580px'
      }
    }
  }, [sessionData])

  return (
    <LogoPageWrapper>
      <div id="paybis-widget-container"></div>
    </LogoPageWrapper>
  )
}

export default PaybisProvider

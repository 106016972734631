import axios from 'axios'
import countries from 'assets/countries.json'
import states from 'assets/us-states.json'
import { sortBy } from 'lodash'

export const allCountries = countries.map((country) => {
  return {
    ...country,
    iso2: country.iso2.trim(),
    dial_code: `+${country.country_code}`
  }
}).sort((a, b) => a.iso3.localeCompare(b.iso3))

export const getStates = async (countryCode) => {
  const country = countries.find((country) => country.iso2.trim() === countryCode)

  if (countryCode === 'US') {
    const filteredStates = states.filter((state) => state.wyre_supported === 'Yes')
    return sortBy(filteredStates, 'state')
  }

  const { data } = await axios.get(`https://api.countrystatecity.in/v1/countries/${country.iso2}/states`, {
    headers: {
      'X-CSCAPI-KEY': 'd05zTkRlVzF6eXdZZTVRS3RIYTU1Qk14YkdHbTlSdzJEYUVZT1RGeA==',
    },
  })

  return sortBy(data, 'name')
}

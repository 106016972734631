import React from 'react'
import styled from 'styled-components'
import { Divider, Form, List as ListANTD, Select as SelectANTD } from 'antd'
import Button from 'components/ui/button'
import { RightOutlined } from '@ant-design/icons'
import { getPaymentMethodIcon } from './utils'

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const FullHeightForm = styled(Form)`
  height: calc(100% - 14px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: ${({ nopadding, mobilepadding }) => nopadding === 'true' ? '0px 0px 0px 0px' : '15px 30px 10px'};
  margin: -15px;
`

export const HeaderTitle = styled.div`
  width: 100%;
  text-align: center;
  font-size: 1.5em;
  line-height: 34px;
  margin-bottom: 10px;
`

const UpdatePaymentMethodContainer = styled.div`
  color: ${({ theme }) => theme.colors.foregroundPrimary};
  width: 100%;
  margin-bottom: 20px;
  font-weight: bolder;
`

const UpdatePaymentMethodButton = styled(Button)`
  margin-top: 5px;
  border-radius: 5px;
  padding: 20px 15px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  border-width: 1px;
  :hover {
    color: ${({ theme }) => theme.colors.disabled} !important;
    border-color: ${({ theme }) => theme.colors.antdInputBorder} !important;
  }
`
export const UpdatePaymentMethod = ({ paymentMethod, onClick }) => (
  <UpdatePaymentMethodContainer>
    <div>Update Payment Method</div>
    <UpdatePaymentMethodButton onClick={onClick}>
      <div>
        {getPaymentMethodIcon(paymentMethod)}
        <span style={{ marginLeft: 14 }}>{paymentMethod.name}</span>
      </div>
      <RightOutlined style={{ fontSize: 12 }}/>
    </UpdatePaymentMethodButton>
  </UpdatePaymentMethodContainer>
)

export const ErrorContainer = styled.div`
  height: calc(100% - 53px);
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  text-align: center;
  font-size: ${({ theme }) => theme.fontSizes.large};
  padding: 20px;
  color: ${({ theme, error }) => error ? theme.colors.warning : theme.colors.foregroundPrimary};
`

export const Select = styled(SelectANTD).attrs(({ notFoundContent }) => ({ notFoundContent: notFoundContent || 'Not found' }))`
  .ant-select-selector {
    height: ${({ height }) => height || 51}px !important;
    line-height: ${({ height }) => height || 51}px !important;
    padding: ${({ height }) => height ? Math.max((height - 31) / 2, 0) : 10}px 11px !important;
  }
`

export const List = styled(ListANTD)`
  border-width: 0px;
`

export const ListItem = styled.li`
  width: 100%;
  border-radius: 10px;
  border: 2px solid ${({ theme }) => theme.colors.empty};
  color: ${({ theme }) => theme.colors.foregroundPrimary};
  margin-bottom: 10px;
  &:hover {
    border-color: ${({ theme }) => theme.buttonColor ? theme.buttonColor : theme.colors.highlightPrimary};
  }
`

export const ListItemHeader = styled.div`
  padding: 15px 20px;
  cursor: pointer;
`

export const ListItemDivider = styled(Divider)`
  margin: 0px 0px 15px 0px;
  border-top-color: ${({ theme }) => theme.colors.empty};
  && {
    padding: 0px;
  }
`

export const ListItemBody = styled.div`
  padding: 0px 20px 15px 20px;
  & > div {
    padding: 5px 0px;
  }
`

export const PaymentMethodLogo = styled.img`
  height: 20px;
  width: 20px;
  margin-right: 15px;
`

export const Subtext = styled.div`
  font-size: 15px;
  height: 15px;
  margin-top: 15px;
  line-height: 15px;
  display: flex;
  justify-content: center;
  text-align: center;
  color: ${({ theme }) => theme.colors.foregroundSecondary};
`
